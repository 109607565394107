/* Large display */
@media screen and (min-width: 1200px){

	.xl-fontsize-24{
		font-size: 24px;
	}
	.sf-menu > li{
		float: left;

		> a {
			margin-left: 13px;
			margin-right: 13px;
		}
	}
}

/* Default */
@media screen and (min-width: 980px) and (max-width: 1199px){

	.nav-tabs > li > a {
	    padding: 12px 14px;
	    font-size: 11px;
	}

	.sf-menu > li > a{
		padding-top: 10px;
		padding-bottom: 10px;
	    margin-left: 13px;
	    margin-right: 13px;
	    font-size: 11px;
	}

	#home{
		section.intro_section{
			a[href="#about"]{
				display: none;
			}

			.slide_description{
				font-size: 19px;
			}

			h2{
				font-size: 50px;
			}
		}

		#about{
			background-image: none;
		}

		.intro_section + .intro_overlap_section .container{
			top: -830px;
		}
	}

	#materials tr td:first-child{
		width: 170px;
	}

	#matieres{
		h1{
			font-size: 43px;
		}
	}
	
	#voitures{
		.teaser{

			h3[class*="count"]{
				font-size: 38px;
			}

			img{
				max-width: 123px;
			}
		}

		.intro_overlap_section{
			background-size: 40%;
		}

		.bareclist li:before {
		    font-family: FontAwesome;
		    content: "\f19d";
		    display: inline-block;
		    margin-left: -1.3em;
		    width: 1.3em;
		    position: relative;
		    color: #a2c862;
		    left: -14px;
		    font-size: 20px;
		}
	}
}

/* Portrait tablets */
@media screen and (min-width: 768px) and (max-width: 978px){
	.toggler_left .toggle_menu{
		left: auto;
		right: 0;
		top: -23px;
	}
	.page_toplogo{
		padding-bottom: 33px;
	}
	.toggle_menu:before, .toggle_menu span, .toggle_menu span:before, .toggle_menu span:after{
		color: #000;
	}
	.page_mainslider .slide_description{
		margin-top: 160px;
	}
	.search_modal_button.header-button{
		display: none;
	}
	.toggle_menu:before, .toggle_menu span, .toggle_menu span:before, .toggle_menu span:after{
		background-color: #000;
	}
	.container-fluid.nav-container{
		display: none;
	}
	#home #about{
		background-image: none;
	}

	#voitures{
		section#location{
			.location-main{
				.grey-slant{
					position: initial;
				    padding: 0;
				    margin: 0;
				    transform: none;
				    height: auto;
				    margin-left: auto;
				    margin-right: auto;
				    padding-bottom: 20px;

				    > div{
						transform: none;
				    }
			    }
			}
		}
	}
	#auto_text h2{
		font-size: 30px;
		height: 77px;
	}
	#auto_text h3{
		font-size: 25px;
	}
}

/* Phones to tablets */
@media screen and (max-width: 767px){
	.logo img{
		max-width: 115px;
	}

	.inline-list.small-padding > li{
		padding: 8px 2px;
	}

	.page_header [class*="display_table_cell"]{
		text-align: left;
	}

	.header_right_buttons {
		right: 60px;
	}

	.search_modal_button{
		display: none;
	}

	.header_darkgrey{
		background: #fff;
		border-top: none;
	}

	.page_toplogo{
		padding-bottom: 0;
	}

	#home{

		.page_toplogo{
				
			background: #fff!important;

			.display_table{
				display: block;
		    	height: auto;
		    }

		    &.table_section .row{
		    	height: auto;
		    }

		    .media.inline-block{
		    	margin: 0;
		    	padding: 0;
		    }
		    .small-media .media-left{
		    	padding-right: 3px;
		    }

		    section#about{
		    	background-image: none;
		    }
		}

		#gallery .theme_button {
		    font-size: 12px;
		    margin: 0;
		    padding: 8px 12px;
		    vertical-align: top;
		}
		#about{
			display: none;
		}
	}
	.page_mainslider .slide_description{
		margin-top: initial;
	}
	.flexslider .slides > li{
		padding-bottom: 222px;
		height: initial;
	}
	.slide_description_wrapper{
		height: auto;
	}
	.intro_section .slide_description_wrapper{
		height: auto;
	}
	.container-fluid.nav-container{
		display: none;
	}
	.toggler_left .toggle_menu{
		left: auto;
		right: 0;
		top: -47px;
	}
	.toggle_menu:before, .toggle_menu span, .toggle_menu span:before, .toggle_menu span:after{
		background-color: #000;
	}
	.toggler_left .toggle_menu.mobile-active{
		top: 75px;
	}
	.mainmenu .sub-icon{
		display: none;
	}
	.page_footer > div > div > div{
		text-align: left;
	}
	.page_breadcrumbs h2 + .breadcrumb{
		font-size: 12px;
	}

	#auto_text h2{
		font-size: 30px;
		height: 111px;
	}
	#auto_text h3{
		font-size: 25px;
	}
	#calculator h3{
		font-size: 28px;
	}
	#voitures{
		section#location{
			.location-main{
				.grey-slant{
					position: initial;
				    padding: 0;
				    margin: 0;
				    transform: none;
				    height: auto;
				    margin-left: auto;
				    margin-right: auto;
				    padding-bottom: 20px;

				    > div{
						transform: none;
				    }
			    }
			}
		}
	}
	#eco-car{
		display: none;
	}
}

/* Phones */
@media screen and (max-width: 480px){
	#calculator{
		li a{
			font-size: 11px;
		}
	}
	table#materials > tbody > tr > td:first-child{
		width: 150px;
	}
	#home #gallery .theme_button {
	    font-size: 9px;
	    padding: 6px 9px;
	    vertical-align: top;
	}
	.teaser h3[class*="count"]{
		font-size: 35px;
	}
	.teaser h3[class*="count"] + p{
		font-size: 16px;
	}
	#partners > .container{
		padding-top: 30px;
		padding-bottom: 30px;
	}
	#counters > .container{
		padding-top: 30px;
		padding-bottom: 30px;
	}
	#home .intro_overlap_section > .container{
		padding-top: 30px;
		padding-bottom: 30px;
	}
	#gallery .container{
		padding-top: 20px;
	}
	.static-calculator-open{
		padding: 5px 28px;
	}
	.static-calculator{
	    max-height: 88%;
	    top: 0;
	    margin-bottom: 97px;
	    z-index: 9999;
	    overflow: scroll;
	}
	#eco-car{
		display: none;
	}
}
