#overlay-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; 
    padding: 32px 12px 0px 12px;
    background: rgba(0,0,0,.25098039215686274);
    z-index: 50;

    & #ct-popup {
        background: #FFF;
        width: 30%;
        padding: 20px 30px 20px 30px;
        margin: 0 auto;
        border: none;
        border-radius: 7px;
        overflow: hidden;
        z-index: 50;
        position: relative;
        top: 30%;
        transform: translateY(-50%);

        @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: 65%;
        }

        @media screen and (max-width:481px) {
            width: 90%;

        }

        & #popup-esc {
            margin-left: -40px;
            font-size: 12px;
            background: #ddd;
            padding: 5px;
            border-radius: 7px;
        }
        
        & #popup-close {
            margin-top: 15px;
        }

        & input {
            width: 100%;
            border: none;
            border-radius: 0px;
            border-bottom: 1px solid black;
        }
        
        & #ct-search-results {
            list-style: none;
            padding: 0px;
            min-height: 165px;
            max-height: 350px;
            overflow-y: auto;

            & .search-result {
                width: 100%;
                margin: 20px 0px;

                h4 {
                    font-size: 20px;
                    margin-bottom: 0px;

                    @media screen and (max-width:481px) {
                        font-size: 14px;
                    }
                }

                p {
                    @media screen and (max-width:481px) {
                        font-size: 12px;
                    }
                }
    
                a {
                    display: flex;

                    > img {
                        border-radius: 10px;
                        width: 100px;

                        @media screen and (max-width:481px) {
                            width: 50px;
                        }
                    }

                    > div {
                        width: 100%;
                        padding-left: 20px;
                        padding-right: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }

            & #search-no-result {
                text-align: center;
                margin-top: 25px;
            }
        }

        & button {
            font-size: 10px;
        }
    }
}
