#overlay-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 32px 12px 0px 12px;
  background: rgba(0, 0, 0, 0.25098);
  z-index: 50;
}

#overlay-popup #ct-popup {
  background: #FFF;
  width: 30%;
  padding: 20px 30px 20px 30px;
  margin: 0 auto;
  border: none;
  border-radius: 7px;
  overflow: hidden;
  z-index: 50;
  position: relative;
  top: 30%;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #overlay-popup #ct-popup {
    width: 65%;
  }
}

@media screen and (max-width: 481px) {
  #overlay-popup #ct-popup {
    width: 90%;
  }
}

#overlay-popup #ct-popup #popup-esc {
  margin-left: -40px;
  font-size: 12px;
  background: #ddd;
  padding: 5px;
  border-radius: 7px;
}

#overlay-popup #ct-popup #popup-close {
  margin-top: 15px;
}

#overlay-popup #ct-popup input {
  width: 100%;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
}

#overlay-popup #ct-popup #ct-search-results {
  list-style: none;
  padding: 0px;
  min-height: 165px;
  max-height: 350px;
  overflow-y: auto;
}

#overlay-popup #ct-popup #ct-search-results .search-result {
  width: 100%;
  margin: 20px 0px;
}

#overlay-popup #ct-popup #ct-search-results .search-result h4 {
  font-size: 20px;
  margin-bottom: 0px;
}

@media screen and (max-width: 481px) {
  #overlay-popup #ct-popup #ct-search-results .search-result h4 {
    font-size: 14px;
  }
}

@media screen and (max-width: 481px) {
  #overlay-popup #ct-popup #ct-search-results .search-result p {
    font-size: 12px;
  }
}

#overlay-popup #ct-popup #ct-search-results .search-result a {
  display: flex;
}

#overlay-popup #ct-popup #ct-search-results .search-result a > img {
  border-radius: 10px;
  width: 100px;
}

@media screen and (max-width: 481px) {
  #overlay-popup #ct-popup #ct-search-results .search-result a > img {
    width: 50px;
  }
}

#overlay-popup #ct-popup #ct-search-results .search-result a > div {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#overlay-popup #ct-popup #ct-search-results #search-no-result {
  text-align: center;
  margin-top: 25px;
}

#overlay-popup #ct-popup button {
  font-size: 10px;
}
