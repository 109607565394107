/**
*** Table Of Contents
*
* Bootstrap Overrides
  - columns
  - container
  - breadcrumbs
  - pagination
  - forms
  - carousel
  - accordion
  - progressbar
  - navs and tabs
  - tables
  - etc.
* General Styles for HTML tags
* Styled Lists
* Buttons
* JQuery Plugins
  - Owl Carousel
  - FlexSlider
  - prettyPhoto
  - Easy pieChart
  - Time Table
  - toTop
  - Countdown
* Vertical Margins and Paddings
* Common Sections Styles
  - parallax
  - page preloader
  - animated elements
  - boxed layout
* Helper Utilites
  - layout
  - borders, colors and backgrouns
  - dividers, margins and paddings
  - alignment
  - media items
* Header
* Side Header
  - side header layout
  - side menu styles
  - sidebar scroll plugin
* Light Section - .ls
* Grey Section - .ls.ms
* Dark Sections - .ds
* Color Section - .cs
* Template Specific Styles
  - topline styles
  - toplogo styles
  - title section (breadcrumbs) styles
  - template parallax sections
  - footer styles
  - copyrights styles
  - google map
  - intro slider inside content
* Intro Section Styles
* Main Menu
  - Desktop menu
  - Mobile menu
  - Mobile menu toggler
  - Mega Menu
* Subpages
  - 404
  - about
  - blog
  - single post
  - gallery
  - post formats
* Widgets
  - common widgets styles
  - search_mailchimp
  - nav_recent_categories
  - tags
  - popular
  - recent_posts
  - calendar
  - banner
  - slider
  - flickr
  - instagram
  - twitter
  - post_tabs
  - rss
  - access_press
* Shortcodes
  - teasers
  - testimonials
  - thumbnail
  - price tables
  - contact forms
  - partners_carousel
* Social Icons
  - layout
  - corporate colors
*/
/*
** Template colors
*

Accent Colors:
1: #0074ba
2: #bee27b

Light section (.ls) colors:
Text color in light section: #656565
Background color for light section and font color in dark section: #ffffff
Color for headings in light section and for background in dark section (.ds): #323232
Background color for light muted section (grey - .ls.ms): #fafafa
Background color for dark muted section (.ds.ms): #272727
Border colors for elements in light section (and .with_border elements): #f2f2f2, rgba(0, 0, 0, 0.05)
Background color for elements in light section (.with_background): #fafafa

Dark section (.ds) colors:
Text color in dark section: #a2a2a2
Border colors for elements in dark section: #3b3b3b
Background color for elements in dark section (.with_background): rgba(255, 255, 255, 0.05)

Color section (.cs) colors:
Border colors for elements in color section: rgba(255, 255, 255, 0.23)

*/
/*
** Bootstrap Overrides
*/
/* columns with 20 and 40 percents width */
.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-20 {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-20 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-20 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-20 {
		width: 20%;
		float: left;
	}
}

.col-xs-40,
.col-sm-40,
.col-md-40,
.col-lg-40 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-40 {
	width: 40%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-40 {
		width: 40%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-40 {
		width: 40%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-40 {
		width: 40%;
		float: left;
	}
}

/* half on .container in .container-fluid */
.container-left-half,
.container-right-half {
	padding-right: 15px;
	padding-left: 15px;
}

.container-left-half {
	margin-right: 0;
	margin-left: auto;
}

.container-right-half {
	margin-right: auto;
	margin-left: 0;
}

@media (min-width: 768px) {
	.container-left-half,
  .container-right-half {
		width: 375px;
	}
}

@media (min-width: 992px) {
	.container-left-half,
  .container-right-half {
		width: 485px;
	}
}

@media (min-width: 1200px) {
	.container-left-half,
  .container-right-half {
		width: 585px;
	}
}

/* bootstrap breadcrumbs*/
.breadcrumb {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border-radius: 0;
	color: inherit;
	font-size: 18px;
	text-transform: uppercase;
}

.breadcrumb > li {
	padding: 0 10px;
}

.breadcrumb > li:last-child {
	padding-right: 0;
}

.breadcrumb > .active {
	color: #bee27b;
}

.breadcrumb > li + li:before {
	display: none;
}

/* pagination */
.pagination {
	display: inline-block;
	margin: 0;
	line-height: 0;
	padding: 3px 15px;
	border-radius: 0;
}

.pagination > li {
	display: inline-block;
}

.pagination > li:last-child {
	margin-right: 0;
}

.pagination > li > a,
.pagination > li > span {
	text-align: center;
	padding: 0;
	border: none;
	color: #323232;
	background-color: transparent;
	margin-left: 0;
	min-width: 24px;
	font-size: 16px;
	line-height: 30px;
	font-weight: 700;
	text-transform: uppercase;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	font-size: 18px;
}

@media (min-width: 992px) {
	.pagination > li:first-child > a,
  .pagination > li:first-child > span {
		margin-right: 15px;
	}

	.pagination > li:last-child > a,
  .pagination > li:last-child > span {
		margin-left: 15px;
	}
}

@media (min-width: 1200px) {
	.pagination > li:first-child > a,
  .pagination > li:first-child > span {
		margin-right: 25px;
	}

	.pagination > li:last-child > a,
  .pagination > li:last-child > span {
		margin-left: 25px;
	}

	.pagination > li {
		margin: 3px;
	}
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background: transparent;
	color: #009d83;
}

.pagination > .disabled > a,
.pagination > .disabled > span,
.pagination > .disabled > a:hover,
.pagination > .disabled > span:hover,
.pagination > .disabled > a:focus,
.pagination > .disabled > span:focus {
	background-color: #fafafa;
	color: #656565;
	opacity: 0.5;
}

/* forms */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea,
select,
.form-control {
	height: 46px;
	background-color: transparent;
	border-radius: 5px;
	box-shadow: none;
	color: #323232;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 7px;
	padding-bottom: 7px;
	font-size: 16px;
	line-height: 30px;
	font-weight: 300;
	border: 2px solid rgba(0, 0, 0, 0.05);
}

input[type="text"] + .theme_button,
input[type="email"] + .theme_button,
input[type="url"] + .theme_button,
input[type="password"] + .theme_button,
input[type="search"] + .theme_button,
input[type="tel"] + .theme_button,
input[type="number"] + .theme_button,
input[type="date"] + .theme_button,
textarea + .theme_button,
select + .theme_button,
.form-control + .theme_button {
	position: absolute;
	padding: 0;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	color: #ffffff;
	box-shadow: none;
	border-radius: 0 20px 20px 0;
	cursor: pointer;
}

.input-text-center input[type="text"], .input-text-center
input[type="email"], .input-text-center
input[type="url"], .input-text-center
input[type="password"], .input-text-center
input[type="search"], .input-text-center
input[type="tel"], .input-text-center
input[type="number"], .input-text-center
input[type="date"], .input-text-center
textarea, .input-text-center
select, .input-text-center
.form-control {
	text-align: center;
}

.with_background input[type="text"], .with_background
input[type="email"], .with_background
input[type="url"], .with_background
input[type="password"], .with_background
input[type="search"], .with_background
input[type="tel"], .with_background
input[type="number"], .with_background
input[type="date"], .with_background
textarea, .with_background
select, .with_background
.form-control {
	background-color: #ffffff;
}

.form-group {
	position: relative;
	margin-bottom: 20px;
}

.form-group-wrap {
	position: relative;
}

.inline-form .form-group-wrap {
	display: inline-block;
}

/* select styling */
.form-control option {
	color: #323232;
	background-color: #ffffff;
}

.form-control-unstyled {
	border: none;
	background-color: transparent;
}

/* select styling */
.select-group {
	position: relative;
	border-radius: 0;
	padding: 0;
	z-index: 1;
}

.select-group select {
	position: relative;
	cursor: pointer;
	z-index: 1;
	padding-right: 50px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent !important;
	box-shadow: none !important;
}

.select-group select::-ms-expand {
	display: none;
}

.select-group .empty {
	color: #656565;
}

.select-group option {
	font-style: normal;
	color: #323232;
	font-weight: 300;
}

.select-group option[data-default] {
	color: #656565;
}

.select-group:hover select + .theme_button,
.select-group:hover select + i {
	color: #323232;
}

.select-group:hover .theme_button {
	transition: all 0.05s linear 0s;
}

.select-group select + i,
.select-group .theme_button {
	font-size: 14px;
	z-index: -1;
}

.form-inline .select-group {
	display: inline-block;
	max-width: 100%;
}

.form-inline .select-group select {
	width: 100%;
}

.form-control.invalid {
	box-shadow: 0 0 0 2px #0074ba;
}

.form-control:focus {
	border-color: rgba(101, 101, 101, 0.3);
	box-shadow: none;
}

.form-control:-moz-placeholder {
	color: #939393;
	font-style: italic;
}

.form-control::-moz-placeholder {
	color: #939393;
}

.form-control:-ms-input-placeholder {
	color: #939393;
}

.form-control::-webkit-input-placeholder {
	color: #939393;
}

.form-control:focus:-moz-placeholder {
	color: transparent;
}

.form-control:focus::-moz-placeholder {
	color: transparent;
}

.form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

/*icons for forms */
form label + i,
form label + i {
	position: absolute;
	font-size: 14px !important;
	left: 20px;
	top: 18px;
	width: 1em;
	line-height: 1;
	text-align: center;
}

form label + i + .form-control,
form label + i + .form-control {
	padding-left: 45px;
}

form .with_icon {
	padding-left: 25px;
}

form .with_icon + [class*="fa-"],
form .with_icon + [class*="icon2-"] {
	position: absolute;
	top: 17px;
	left: 2px;
	font-size: 12px;
}

form .with_icon:focus + [class*="fa-"],
form .with_icon:focus + [class*="icon2-"] {
	color: #bee27b;
}

label {
	font-weight: inherit;
}

.checkbox label,
.radio label {
	font-weight: inherit;
}

.checkbox label[for],
.radio label[for] {
	font-weight: inherit;
}

.form-control + [class*="icon2-"] {
	position: absolute;
	font-size: 24px;
	left: 20px;
	top: 50%;
	color: #0074ba;
}

.checkbox label[for] + input[type=checkbox],
.checkbox label[for] + input[type=radio],
.radio label[for] + input[type=checkbox],
.radio label[for] + input[type=radio] {
	left: 20px;
}

input[type=checkbox],
input[type=radio] {
	margin-top: 7px;
}

.form-wrapper.with_background, .form-wrapper.muted_background, .form-wrapper.cs, .form-wrapper.ds, .form-wrapper.ls {
	padding: 35px;
}

.form-inline-button .form-inline {
	position: relative;
}

.form-inline-button .form-inline .form-control {
	width: 100%;
	margin: 0;
}

.form-inline-button .form-inline label {
	display: block;
}

.form-inline-button .form-inline label[for] {
	display: none;
}

.form-inline-button .form-inline .form-group {
	display: block;
	vertical-align: top;
	position: relative;
}

@media (min-width: 768px) {
	.form-inline-button .form-inline .form-group.inline-block {
		min-width: 370px;
		margin-right: 5px;
	}
}

.form-inline-button .form-inline [type="text"],
.form-inline-button .form-inline [type="password"],
.form-inline-button .form-inline [type="search"],
.form-inline-button .form-inline [type="email"] {
	padding-right: 50px;
	width: 100%;
}

.form-inline-button .form-inline [type="submit"],
.form-inline-button .form-inline .theme_button {
	font-size: 0;
	padding-left: 22px;
	padding-right: 22px;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	border: none;
	background-color: #0074ba;
	color: #ffffff;
}

.form-inline-button .form-inline [type="submit"] i,
.form-inline-button .form-inline .theme_button i {
	font-size: 16px;
	content: "\e656";
	font-family: 'rt-icons-2';
	line-height: 1;
	top: 0;
}

.form-inline-button .form-inline [type="submit"]:hover,
.form-inline-button .form-inline .theme_button:hover {
	opacity: 0.5;
}

.has-error .form-control {
	border-color: #0074ba;
}

.has-error label,
.has-error .help-block {
	color: #0074ba;
}

/* inline input with button */
.button-on-input {
	position: relative;
}

.button-on-input input[type*="text"],
.button-on-input input[type*="email"]
input[type*="password"],
.button-on-input .form-group {
	width: 100%;
}

.button-on-input button,
.button-on-input input[type*="submit"] {
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
}

/* carousel */
.carousel {
	overflow: hidden;
}

.carousel-indicators li {
	width: 10px;
	height: 10px;
	border-width: 0;
	background-color: #323232;
	margin: 0 3px;
	transition: all 0.2s ease-in-out 0s;
}

.carousel-indicators li:hover {
	opacity: 0.8;
}

.carousel-indicators .active {
	width: 10px;
	height: 10px;
	margin: 0 3px;
	background-color: #0074ba;
}

.carousel-control.left,
.carousel-control.right {
	border: 1px solid #ffffff;
	color: #ffffff;
	border-radius: 0px;
	width: 50px;
	height: 50px;
	left: -50px;
	top: 50%;
	margin-top: -25px;
	text-shadow: none;
	opacity: 1;
	background-image: none;
	filter: alpha(opacity=100);
	text-align: center;
}

.carousel-control.right {
	right: -50px;
}

.carousel:hover .carousel-control {
	left: 50px;
}

.carousel:hover .carousel-control.right {
	left: auto;
	right: 50px;
}

.carousel-control.left span,
.carousel-control.right span {
	font-family: 'rt-icons-2';
	width: 50px;
	height: 50px;
	margin: 0;
	font-size: 16px;
	left: 0;
	top: 0;
	line-height: 50px;
}

.carousel-control .icon-prev:before {
	content: "\e7c4";
}

.carousel-control .icon-next:before {
	content: "\e7c5";
}

/* collapse accordion */
.panel-heading .panel-title {
	font-size: inherit;
	letter-spacing: 0;
	padding: 0;
	position: relative;
	font-family: "HelveticaW01-RoundedBdC 709938", Helvetica, Arial, sans-serif;
}

.panel-heading .panel-title > a {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.3;
	display: block;
	padding: 13px 50px 12px 20px;
	background: #36afb7;
	border: 2px solid #36afb7;
	color: #ffffff;
	border-radius: 3px;
	word-wrap: break-word;
}

.panel-heading .panel-title > a.collapsed:hover,
.panel-heading .panel-title > a:hover {
	color: #ffffff;
	background: #36afb7;
	border-color: #36afb7;
}

.panel-heading .panel-title > a.collapsed:hover i, .panel-heading .panel-title > a.collapsed:hover:after,
.panel-heading .panel-title > a:hover i,
.panel-heading .panel-title > a:hover:after {
	color: #ffffff;
}

.panel-heading .panel-title > a.collapsed {
	color: #656565;
	background: #fafafa;
	border-color: rgba(101, 101, 101, 0.03);
}

.panel-heading .panel-title > a.collapsed i {
	color: #0074ba;
}

.panel-heading .panel-title > a:after {
	font-family: FontAwesome;
	content: "\f105";
	position: absolute;
	text-align: center;
	right: 10px;
	top: 0;
	width: 30px;
	height: 52px;
	line-height: 52px;
	font-weight: 100;
	transition: all 0.2s ease-in-out 0s;
}

.panel-heading .panel-title > a.collapsed:after {
	content: "\f107";
}

.panel-heading .panel-title > a > i {
	position: relative;
	width: 2em;
	padding-right: 30px;
	text-align: center;
	display: inline-block;
	line-height: 0;
}

.panel {
	box-shadow: none;
}

.panel-group .panel {
	border-radius: 5px;
	background-color: transparent;
}

.panel-default > .panel-heading {
	background-color: transparent;
	color: inherit;
	position: relative;
	border: none;
	border-radius: 0;
	padding: 0;
}

.panel-default {
	border: none;
}

.panel-group .panel + .panel {
	margin-top: 20px;
}

.panel-body {
	border-top: none !important;
	background-color: transparent;
}

.panel-body .media-left {
	padding-right: 15px;
}

.panel-body .media-left .img-circle {
	max-width: 50px;
}

.panel-body .media-left img {
	max-width: 80px;
}

@media (min-width: 1200px) {
	.panel-body {
		font-size: 18px;
	}
}

.panel-group .panel-heading + .panel-collapse .panel-body {
	padding: 20px;
}

.collapse-unstyled .panel h4 {
	margin: 0 0 0.5em;
}

.collapse-unstyled .panel h4 a:after {
	color: #0074ba;
	padding: 0 5px;
	display: inline-block;
	font-family: 'FontAwesome';
	content: "\f107";
}

.collapse-unstyled .panel h4 a.collapsed:after {
	content: "\f105";
}

.collapse-unstyled .panel-content {
	padding: 10px 20px;
}

/* progress bars */
.progress-bar-title {
	font-weight: 700;
	margin-bottom: 5px;
	font-size: 12px;
	text-transform: uppercase;
	margin-right: 50px;
}

.progress {
	overflow: visible;
	height: 5px;
	margin-bottom: 25px;
	position: relative;
	border-radius: 0;
	box-shadow: none;
	background-color: #f2f2f2;
}

.ls .with_background .progress,
.ls .tab-content .progress {
	background-color: #ffffff;
}

.ls.ms .with_background .progress,
.ls.ms .tab-content .progress {
	background-color: #f2f2f2;
}

.progress-bar {
	background-color: #0074ba;
	color: inherit;
	box-shadow: none;
	height: 5px;
	border-radius: 0;
}

.progress-bar span {
	position: absolute;
	right: 0;
	top: -27px;
	font-size: 12px;
	font-weight: 600;
}

.progress-bar-success {
	background-color: #9fc201;
}

.progress-bar-info {
	background-color: #19bcdb;
}

.progress-bar-warning {
	background-color: #f4b803;
}

.progress-bar-danger {
	background-color: #ec5c42;
}

.progress-bar-color2 {
	background-color: #bee27b;
}

/* nav and tabs */
.nav > li > a:hover,
.nav > li > a:focus {
	background-color: transparent;
}

.nav-unstyled {
	list-style: none;
	padding: 0;
	margin: 0 -12px;
}

.nav-unstyled li {
	display: inline-block;
	margin-bottom: -1px;
}

.nav-unstyled li a {
	display: inline-block;
	padding: 10px 12px;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 400;
}

.nav-unstyled .active a {
	color: #323232;
	border-color: #0074ba;
}

/*tabs*/
.tab-content {
	padding: 30px 20px;
	border: none;
	background-color: #fafafa;
}

.tab-content.no-border {
	padding: 30px 0 0;
	border: none;
	box-shadow: none;
	background-color: transparent;
}

.tab-content.top-color-border {
	position: relative;
}

.tab-content.top-color-border:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: 7px;
	background-color: #009d83;
}

.tab-content.tab-unstyled {
	padding: 10px 0 0;
	border: none;
	background-color: transparent;
	box-shadow: none;
}

.ls .tab-content.tab-unstyled,
.ds .tab-content.tab-unstyled,
.cs .tab-content.tab-unstyled {
	background-color: transparent;
}

.tab-content .featured-tab-image {
	margin: -30px -20px 30px;
}

@media (min-width: 400px) {
	.tab-content .featured-tab-image {
		margin: -40px -30px 30px;
	}
}

@media (min-width: 400px) {
	.tab-content {
		padding: 15px 30px;
	}
}

@media (min-width: 400px) {
	aside .tab-content {
		padding: 30px 20px;
	}
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border-bottom: none;
}

.nav-tabs {
	position: relative;
	border-bottom: none;
	margin-top: 0px;
	z-index: 1;
}

.nav-tabs.half-with-tabs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.nav-tabs.half-with-tabs > li {
	width: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.nav-tabs.half-with-tabs > li a {
	width: 100%;
}

.nav-tabs > li {
	margin-bottom: 0;
}

.nav-tabs > li > a {
	position: relative;
	z-index: 1;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 400;
	padding: 10px;
	margin-right: 0;
	margin-top: 0px;
	background-color: #009d83;
	border: none;
	color: #ffffff;
	border-radius: 0;
}

.small-tabs .nav-tabs > li > a {
	padding: 10px;
}

.nav-tabs > li > a i {
	position: relative;
	top: 2px;
	padding-right: 4px;
	font-size: 1.6em;
	display: inline-block;
	line-height: 0;
}

@media (min-width: 992px) {
	.nav-tabs > li > a {
		padding: 12px 28px;
		font-size: 12px;
	}
}

@media (min-width: 1200px) {
	.nav-tabs > li > a {
		padding: 15px 21px;
	}
}

@media (max-width: 400px) {
	.nav-tabs > li > a {
		font-size: 12px;
		padding: 14px 8px 12px;
	}

	.small-tabs .nav-tabs > li > a {
		font-size: 10px;
		padding: 8px;
	}
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border: none;
	background-color: #fafafa;
	color: #323232;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > .active > a {
	background-color: #009d83;
	color: #323232;
}

/*vertical tabs*/
.vertical-tabs .tab-content.no-border {
	padding-top: 0;
}

.vertical-tabs .nav > li > a {
	font-size: 18px;
	font-weight: 400;
	background-color: #fafafa;
	border: 2px solid rgba(101, 101, 101, 0.03);
	padding: 8px 40px 8px 20px;
	margin-bottom: 10px;
	position: relative;
	border-radius: 3px;
	color: #656565;
}

.vertical-tabs .nav > li > a i {
	position: relative;
	top: -2px;
	padding-right: 8px;
	width: 2em;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	line-height: 0;
}

.vertical-tabs .nav > li > a:after {
	font-family: 'rt-icons-2';
	content: "\e7c5";
	position: absolute;
	font-size: 12px;
	line-height: 50px;
	text-align: center;
	right: 0;
	top: 0;
	bottom: 0;
	width: 46px;
	letter-spacing: 0;
}

.vertical-tabs.no-arrows .nav > li > a:after {
	display: none;
}

.vertical-tabs.no-arrows .nav > li > a {
	padding: 24px 0;
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover {
	background: #36afb7;
	box-shadow: none;
	color: #ffffff;
}

@media (min-width: 768px) {
	.vertical-tabs .nav > li.active {
		position: relative;
		z-index: 2;
	}
}

/* modal */
.modal-backdrop.in {
	filter: alpha(opacity=80);
	opacity: .8;
}

.modal-content {
	border-radius: 1px;
}

.modal-content [class*="col-"]:last-child {
	margin-bottom: 0;
}

/* bootstrap tables */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	padding: 15px 15px;
	border-color: #f2f2f2;
}

.table {
	border-top: 1px solid #f2f2f2;
	border-bottom: 1px solid #f2f2f2;
	box-shadow: 0px 0px 57px 0 rgba(0,0,0,0.11);
	border-radius: 12px;
   	overflow: hidden;

   	&.first-row-heading {

   		tr:first-child{
	   		background: #727272;
	   		color: #fff;
	   	}

   		&.table-light tr:first-child{
	   		background: #cecece;
	   		color: #323232;
	   		font-weight: bold;
   		}
   	}
}
.no-radius{
	border-radius: 0!important;
}

.table td + th,
.table th + td,
.table th + th,
.table td + td {
	border-left: 1px solid #f2f2f2;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
	border-bottom-width: 1px;
}

.table > tbody > tr > td.media-middle {
	vertical-align: middle;
}

.table-responsive {
	border: none;
}

/* bootstrap buttons */
.btn {
	padding: 22px 30px 22px;
	margin: 0 0 4px 0;
	font-weight: 700;
	border: none;
	border-radius: 0;
	outline: none;
	transition: all 0.3s ease-in-out 0s;
}

.btn:hover, .btn:focus {
	outline: none;
}

/* media elements */
.media {
	margin-top: 17px;
}

.media .media-object {
	max-width: 100px;
}

li.media:before,
li.media:after {
	display: block;
	content: '';
	clear: both;
}

.media-right,
.media > .pull-right {
	padding-left: 20px;
}

.media-left,
.media > .pull-left {
	padding-right: 20px;
}

.media-left img,
.media-right img {
	max-width: 85px;
}

@media (min-width: 768px) {
	.big-left-media .media-left img, .big-left-media
  .media-right img {
		max-width: 100px;
	}
}

.small-media {
	margin-top: 15px;
}

.small-media .media-left {
	padding-right: 15px;
}

.small-media .media-right {
	padding-left: 15px;
}

.small-media .media-left img,
.small-media .media-right img {
	max-width: 40px;
}

td .media-body {
	width: auto;
}

/* bootstrap dropdown menu */
.dropdown-menu {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 0;
	border: none;
	z-index: 1001;
	font-size: 14px;
	margin-top: 0;
	padding-left: 0;
	padding-right: 0;
}

[class*="right"] .dropdown-menu {
	right: 0;
	left: auto;
}

.dropdown-menu > li > a {
	font-weight: 700;
	padding: 7px 20px;
}

.nav .open > .header-button,
.nav .open > .header-button:hover,
.nav .open > .header-button:focus {
	background-color: transparent;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
	color: #323232;
	background-color: transparent;
}

.dropdwon-menu-title {
	padding: 10px 30px;
}

.well {
	box-shadow: none;
	border: none;
	border-radius: 0;
}

.embed-responsive-3by2 {
	padding-bottom: 66.666%;
}

.embed-placeholder:after {
	content: "\f04b";
	font-family: 'FontAwesome';
	position: absolute;
	color: #0074ba;
	font-size: 14px;
	line-height: 60px;
	width: 60px;
	height: 60px;
	text-align: center;
	background-color: #ffffff;
	border-radius: 50%;
	left: 50%;
	top: 50%;
	margin-left: -30px;
	margin-top: -30px;
	transition: all 0.2s ease 0s;
}

.embed-placeholder:hover:after {
	color: #bee27b;
}

.alert {
	border-width: 0;
	padding: 15px 30px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 300;
	border-radius: 0;
}

.alert i {
	position: relative;
	padding-right: 10px;
}

.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
	color: #ffffff;
}

.alert-success {
	background-color: #9fc201;
}

.alert-info {
	background-color: #19bcdb;
}

.alert-warning {
	background-color: #f4b803;
}

.alert-danger {
	background-color: #ec5c42;
}

button.close {
	margin-top: 4px;
}

/* small in headings */
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
	color: inherit;
	font-weight: 300;
}

/* labels */
.label-success {
	color: #ffffff;
	background-color: #9fc201;
}

.label-info {
	color: #ffffff;
	background-color: #19bcdb;
}

.label-warning {
	color: #ffffff;
	background-color: #f4b803;
}

.label-danger {
	color: #ffffff;
	background-color: #ec5c42;
}

/*caret*/
.caret {
	border: none;
	line-height: 20px;
	width: 20px;
	height: 20px;
	border: 1px solid #f2f2f2;
	text-align: center;
	margin-left: 5px;
	vertical-align: baseline;
}

.caret:before {
	content: "\f107";
	font-family: FontAwesome;
}

/* helpers */
@media (max-width: 499px) {
	.hidden-xxs {
		display: none !important;
	}
}

/*
** General Styles for HTML tags
*/
html {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: visible;
	font-size: 16px;
}

::-moz-selection {
	color: #ffffff;
	background-color: #0074ba;
}

::selection {
	color: #ffffff;
	background-color: #0074ba;
}

::-moz-selection {
	color: #ffffff;
	background-color: #0074ba;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 300;
	line-height: 1.875rem;
	font-size: 16px;
	background-color: #fafafa;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 300;
	line-height: 1.34;
	word-break: break-word;
	margin-top: 1.1em;
	margin-bottom: 0.8em;
	font-family: 'HelveticaW01-RoundedBdC 709938', Helvetica, Arial, sans-serif;
}

h1 {
	font-size: 52px;
}

h2 {
	font-size: 40px;
}

h3 {
	font-size: 32px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 16px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

p {
	margin-bottom: 15px;
}

p:last-child {
	margin-bottom: 0;
}

blockquote {
	position: relative;
	margin: 40px 0;
	padding: 0 0 0 50px;
	border: none;
	font-size: 20px;
	font-weight: 300;
	line-height: 1.5em;
	color: #656565;
}

blockquote .item-meta {
	margin-top: 25px;
}

blockquote h4, blockquote h5 {
	font-size: 30px;
	margin-bottom: 0;
}

blockquote cite {
	display: block;
	font-style: normal;
	margin-top: 40px;
	font-size: 18px;
}

blockquote:before {
	content: "\"";
	font-size: 80px;
	position: absolute;
	font-style: italic;
	font-weight: 700;
	top: 25px;
	left: -10px;
	color: #323232;
}

@media (min-width: 992px) {
	blockquote.lg-big {
		font-size: 24px;
		padding-left: 70px;
	}

	blockquote.lg-big:before {
		font-size: 130px;
		left: -20px;
		top: 45px;
	}
}

.flexslider blockquote,
.owl-carousel blockquote {
	margin: 0;
}

img, figure {
	max-width: 100%;
	height: auto;
}

figcaption, .entry-caption {
	padding: 2px 0 12px;
}

figcaption p, .entry-caption p {
	margin-bottom: 0;
}

a {
	text-decoration: none;
	transition: all 0.2s ease-in-out 0s;
}

a:hover {
	color: #bee27b;
	text-decoration: none;
	transition: all 0.2s linear 0s;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
	outline: medium none;
	text-decoration: none;
}

a > img {
	transition: all 0.2s ease-in-out 0s;
}

a:hover > img {
	opacity: 0.8;
}

hr {
	border: none;
	height: 1px;
	margin-left: 0;
	margin-right: 0;
	background-color: #f2f2f2;
}

iframe {
	border: none;
	max-width: 100%;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

table {
	width: 100%;
	max-width: 100%;
	margin: 10px 0;
}

table td, table th {
	padding: 8px 10px;
	line-height: 1.42857143;
	vertical-align: top;
	border: 1px solid #f2f2f2;
}

table th {
	color: #323232;
	font-weight: normal;
	vertical-align: middle;
	line-height: 1;
}

.table_template thead {
	background-color: #0074ba;
	color: #323232;
}

.table_template thead.light {
	background-color: #ffffff;
}

.table_template th {
	font-weight: 700;
}

.table_template td, .table_template th {
	padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd) {
	background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even) {
	background-color: rgba(17, 17, 17, 0.05);
}

canvas {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

/*
** Styled Lists
*/
ul, ol {
	padding-left: 30px;
	margin-bottom: 20px;
}

.list1 ul,
ul.list1 {
	list-style: none;
	padding: 0;
}

.padding_30 .list1 ul, .padding_30
ul.list1 {
	margin: 0;
}

.list1 li {
	position: relative;
	padding: 9px 0 10px 30px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.muted_background .list1 li {
	border-color: rgba(255, 255, 255, 0.4);
}

.list1 li > .media {
	margin: 3px 0;
}

@media (min-width: 992px) {
	.list1 li {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.list1.big-padding li {
	padding-top: 22px;
	padding-bottom: 21px;
}

.list1.huge-padding li {
	padding-top: 37px;
	padding-bottom: 36px;
}

.list1.no-bullets li {
	padding-left: 0;
}

.list1.no-bullets li:before {
	display: none;
}

.list1 li:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.list1.no-top-border li:first-child {
	border-top: none;
	margin-top: 0;
	padding-top: 0;
}

.list1.no-bottom-border li:last-child {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.list1 li:before {
	content: '';
	display: block;
	position: absolute;
	border: 1px solid #0074ba;
	left: 10px;
	top: 21px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
}

.list2 ul,
ul.list2 {
	list-style: none;
	padding-left: 20px;
}

.list2 li {
	position: relative;
	padding: 5px 0 5px 0;
}

.list2 li:first-child {
	padding-top: 0;
}

.list2 li:first-child:before {
	top: calc(50% - 5px);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.list2 li:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	background-color: #0074ba;
	border-radius: 50%;
	left: -20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #0074ba;
}

.list2.no-bullets {
	padding-left: 0 !important;
}

.list2.no-bullets li:before {
	display: none;
}

.list2.checklist {
	padding-left: 30px;
}

.list2.checklist li:before {
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 14px;
	left: -30px;
	color: #0074ba;
	width: auto;
	height: auto;
	background-color: transparent;
}

.list2.checklist li:first-child:before {
	margin-top: -11px;
}

.list2.checklist.color2 li:before {
	color: #bee27b;
}

.list2.checklist.color3 li:before {
	color: #67dfc1;
}

.list3 ol,
ol.list3 {
	counter-reset: li;
	list-style: none outside none;
	padding: 0;
}

.list3 li {
	position: relative;
	padding: 6px 0 6px 25px;
}

.list3 li.active:after {
	color: #bee27b;
}

.list3 li:after {
	content: counter(li) ".";
	counter-increment: li;
	position: absolute;
	left: 2px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-weight: 600;
	color: #0074ba;
}

.list3.color li:after {
	color: #0074ba;
}

.list3.color2 li:after {
	color: #bee27b;
}

.list-unstyled .media {
	margin-bottom: 10px;
}

.list-unstyled li:last-child > .media {
	margin-bottom: 0;
}

.list-unstyled .media-left {
	padding-right: 10px;
}

.list-unstyled .media-left img {
	border-radius: 50%;
}

.list-unstyled .media-right {
	padding-left: 10px;
}

.list-unstyled .media-right img {
	border-radius: 50%;
}

.list-unstyled .media-body .pull-right {
	margin: 0 20px;
}

.inline-list {
	list-style: none;
	margin: 0 -8px;
	padding: 0;
}

.inline-list > li {
	display: inline-block;
	padding: 0 8px;
}

@media (min-width: 1200px) {
	.inline-list {
		margin: 0 -12px;
	}

	.inline-list > li {
		padding: 0 12px;
	}

	.inline-list.big-padding > li {
		padding: 0 25px;
	}
}

.inline-list.small-padding {
	margin: 0 -8px;
}

.inline-list.small-padding > li {
	padding: 8px;
}

/*
** Buttons
*/
.theme_buttons a,
.theme_button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
button,
input[type="submit"] {
	position: relative;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 400;
	padding: 14px 25px;
	line-height: 1;
	display: inline-block;
	text-align: center;
	color: #009d83;
	background-color: #323232;
	border-radius: 20px;
	border: none;
	position: relative;
	transition: all 0.4s linear 0s;
	z-index: 1;
}

.theme_button,
.theme_buttons a {
	margin-bottom: 20px;
	margin-right: 16px;
}

.theme_button:last-child,
.theme_buttons a:last-child {
	margin-bottom: 0;
	margin-right: 0;
}

.theme_buttons a:active,
.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_button:active,
.theme_button:hover,
.theme_button:focus,
.wc-proceed-to-checkout .checkout-button:hover {
	color: #ffffff;
	opacity: 1;
	text-decoration: none;
	outline: none;
	transition: all 0.05s linear 0s;
}

.theme_button[type="reset"] {
	display: none;
}

.theme_buttons.inverse a,
.theme_button.inverse {
	padding: 13px 24px;
	color: #323232;
	background-color: transparent;
	border: 1px solid #323232;
}

.theme_buttons.inverse a:hover, .theme_buttons.inverse a:focus, .theme_buttons.inverse a:active,
.theme_button.inverse:hover,
.theme_button.inverse:focus,
.theme_button.inverse:active {
	color: #0074ba;
}

.theme_buttons.color1 a,
.theme_button.color1,
.button.alt,
.btn-accent,
.wc-proceed-to-checkout .checkout-button,
.form-submit [type="submit"] {
	color: #ffffff;
	background-color: #0074ba;
}

.theme_buttons.color1 a:hover, .theme_buttons.color1 a:focus, .theme_buttons.color1 a:active,
.theme_button.color1:hover,
.theme_button.color1:focus,
.theme_button.color1:active,
.button.alt:hover,
.button.alt:focus,
.button.alt:active,
.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active,
.wc-proceed-to-checkout .checkout-button:hover,
.wc-proceed-to-checkout .checkout-button:focus,
.wc-proceed-to-checkout .checkout-button:active,
.form-submit [type="submit"]:hover,
.form-submit [type="submit"]:focus,
.form-submit [type="submit"]:active {
	color: #323232;
}

.theme_buttons[class*="color"],
.theme_button[class*="color"] {
	color: #ffffff;
}

.theme_buttons[class*="color"]:hover, .theme_buttons[class*="color"]:focus, .theme_buttons[class*="color"]:active,
.theme_button[class*="color"]:hover,
.theme_button[class*="color"]:focus,
.theme_button[class*="color"]:active {
	color: #323232;
}

.theme_buttons.color1.dark a,
.theme_button.color1.dark {
	background-color: #0c9ece;
}

.theme_buttons.color2 a,
.theme_button.color2 {
	background-color: #bee27b;
}

.theme_buttons.color2.dark a,
.theme_button.color2.dark {
	background-color: #009d83;
}

.theme_buttons.color3 a,
.theme_button.color3 {
	background-color: #67dfc1;
}

.theme_buttons.color3.dark a,
.theme_button.color3.dark {
	background-color: #6fc3b4;
}

.theme_buttons.color4 a,
.theme_button.color4 {
	background-color: #009d83;
}

.theme_buttons.color4.dark a,
.theme_button.color4.dark {
	background-color: #009d83;
}

.theme_buttons.color_gradient a,
.theme_button.color_gradient {
	color: #ffffff;
	background: linear-gradient(-191deg, #bee27b, #0074ba);
	box-shadow: 0 5px 15px rgba(38, 41, 49, 0.2);
}

.theme_buttons a:active,
.theme_button:active {
	top: 1px;
}

.muted_buttons a,
.muted_button {
	opacity: 0.2;
}

.small_buttons a,
.small_button {
	padding: 7px 10px;
	text-transform: uppercase;
	letter-spacing: 0;
}

.square_buttons a,
.square_button {
	padding: 0;
	width: 50px;
	line-height: 50px;
	margin: 0;
}

.round_buttons a,
.round_button {
	padding: 0;
	width: 60px;
	line-height: 60px;
	border-radius: 30px;
}

.wide_buttons a,
.wide_button {
	padding: 24px 50px;
	border-radius: 30px;
}

.wide_buttons.inverse a,
.wide_button.inverse {
	padding: 23px 49px;
}

a.inactive {
	pointer-events: none;
}

.block_buttons a,
.block_button {
	display: block;
	width: 100%;
	text-align: center;
}

.min_width_button {
	min-width: 160px;
}

@media (min-width: 1200px) {
	.min_width_button {
		min-width: 170px;
	}

	.min_width_button.wide_button {
		min-width: 230px;
	}
}

.no_bg_button {
	background-color: transparent !important;
	border-color: transparent;
}

.no_bg_button.color1 {
	color: #0074ba;
}

.no_bg_button.color2 {
	color: #bee27b;
}

.no_bg_button.color3 {
	color: #67dfc1;
}

.no_bg_button.color4 {
	color: #009d83;
}

.no_bg_button[class*="color"]:hover, .no_bg_button[class*="color"]:focus, .no_bg_button[class*="color"]:active {
	color: #323232;
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*="rt-icon"],
h2 > [class*="rt-icon"],
h3 > [class*="rt-icon"],
h4 > [class*="rt-icon"],
h5 > [class*="rt-icon"],
h6 > [class*="rt-icon"] {
	position: relative;
	top: .08em;
	padding-right: 0.1em;
}

p > i {
	font-size: 1.1em;
	line-height: 0;
}

p > i + [class*="rt-icon"] {
	margin-left: -0.75em;
}

.theme_buttons a > [class*="rt-icon"],
.theme_buttons a > [class*="fa-"],
.theme_button > [class*="rt-icon"],
.theme_button > [class*="fa-"] {
	line-height: 0;
	font-size: 1.5em;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: -0.1em;
}

.theme_buttons.big_icon a > [class*="rt-icon"],
.theme_buttons.big_icon a > [class*="fa-"],
.theme_button.big_icon > [class*="rt-icon"],
.theme_button.big_icon > [class*="fa-"] {
	font-size: 2.5em;
}

.form-inline [type="submit"],
.form-inline .btn,
.form-inline .theme_button {
	margin-bottom: 0;
}

.btn-group-lg > .btn, .btn-lg {
	padding: 16px 16px;
}

.btn-group-sm > .btn, .btn-sm {
	padding: 5px 10px;
}

.btn-group-xs > .btn, .btn-xs {
	padding: 1px 5px;
}

/*
**plugins
*/
/* Owl carousel v 2.1.6 styles */
/* Owl Carousel - Core */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	padding: 1px 0;
	width: 100.1%;
  /* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
	-webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
	display: none;
}

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

.owl-carousel.dots-center .owl-dots {
	text-align: center;
}

.owl-carousel .owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	counter-reset: owl-dots;
}

.owl-carousel .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	*display: inline;
	padding: 0 10px;
}

.owl-carousel .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
	color: transparent;
	border: 2px solid #939393;
	cursor: pointer;
	text-indent: -9999px;
	transition: all 0.2s ease-in-out 0s;
}

.ds .owl-carousel .owl-dots .owl-dot span,
.cs .owl-carousel .owl-dots .owl-dot span {
	border-color: #ffffff;
}

.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
	background-color: #939393;
}

.background_cover .owl-carousel .owl-dots .owl-dot span {
	border-color: #ffffff;
}

.background_cover .owl-carousel .owl-dots .owl-dot.active span, .background_cover .owl-carousel .owl-dots .owl-dot:hover span {
	border-color: #ffffff;
	background-color: #ffffff;
}

.owl-carousel .owl-nav {
	transition: all 0.6s ease 0s;
}

.owl-carousel .owl-nav > div {
	color: transparent;
	font-size: 0;
	display: inline-block;
	right: 0;
	text-align: center;
}

.owl-carousel .owl-nav > div.disabled {
	opacity: 0.5;
	cursor: default;
}

.owl-carousel .owl-nav > div:first-child {
	left: 0;
	right: auto;
}

.owl-carousel .owl-nav > div:after {
	transition: all 0.3s ease 0s;
	font-family: FontAwesome;
	font-size: 40px;
	line-height: 60px;
	content: "\f105";
	color: #656565;
}

.cs .owl-carousel .owl-nav > div:after {
	color: #ffffff;
}

.owl-carousel .owl-nav > div:first-child:after {
	content: "\f104";
}

.owl-carousel .owl-nav > div:hover:after {
	color: #bee27b;
}

.top-right-nav .owl-nav {
	position: absolute;
	top: -70px;
	right: 0;
}

.top-right-nav .owl-nav > div {
	background: transparent;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* Owl Carousel - Animate Plugin */
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* Owl Carousel - Auto Height Plugin */
.owl-height {
	transition: height 500ms ease-in-out;
}

/* Owl Carousel - Lazy Load Plugin */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

/* Owl Carousel - Video Plugin */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url("../img/owl.video.play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: -webkit-transform 100ms ease;
	transition: transform 100ms ease;
	transition: transform 100ms ease, -webkit-transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}

/* Owl Default Theme */
.owl-theme:hover .owl-nav {
	opacity: 1;
}

.owl-theme .owl-nav {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	text-align: center;
	-webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav > div {
	font-size: 0;
	position: absolute;
	right: 0;
	text-align: center;
	margin-top: -23px;
	width: 30px;
}

.owl-theme .owl-nav .disabled {
	opacity: 0.5;
	cursor: default;
}

@media (min-width: 1300px) {
	.container .col-sm-12 .owl-theme .owl-nav {
		left: -60px;
		right: -60px;
	}
}

@media (min-width: 1600px) {
	.container .col-sm-12 .owl-theme .owl-nav {
		left: -150px;
		right: -150px;
	}
}

.owl-theme .owl-dots {
	margin-top: 60px;
}

/* .owl-center theme */
.owl-center .owl-nav {
	top: 0;
	bottom: 0;
	position: static;
	margin-top: 0;
	opacity: 1;
}

.owl-center .owl-nav > div {
	right: 0;
	width: 30px;
	top: 50%;
	bottom: 0;
	margin: 0;
	height: 80px;
	text-align: center;
	opacity: 1;
	background-color: rgba(50, 50, 50, 0.6);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.owl-center .owl-nav > div:after {
	position: absolute;
	top: 50%;
	color: #ffffff;
	font-size: 20px;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.owl-center-scale.owl-carousel {
	margin-bottom: -30px;
}

.owl-center-scale.owl-carousel .owl-stage-outer {
	padding-top: 30px;
	padding-bottom: 30px;
}

.owl-center-scale.owl-carousel .owl-item > * > * {
	transition: all 0.2s ease 0s;
}

.owl-center-scale.owl-carousel .center {
	z-index: 2;
}

.owl-center-scale.owl-carousel .center > * > * {
	-webkit-transform: scale(1.15);
	transform: scale(1.15);
	position: relative;
}

/* owl images fix */
.owl-carousel .owl-item {
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

.owl-carousel .owl-item.active {
	opacity: 1;
}

.owl-center .owl-item {
	opacity: 1;
}

.with_shadow_items {
	padding: 20px;
	margin: -20px;
	width: calc(100% + 40px);
	overflow: hidden;
}

.with_shadow_items .owl-stage-outer {
	overflow: visible;
}

/* Text nav */
.owl-carousel.text-nav {
	padding-top: 60px;
}

.owl-carousel.text-nav .owl-nav {
	top: 0;
}

.owl-carousel.text-nav .owl-nav:before {
	content: "";
	width: 2px;
	height: 11px;
	position: absolute;
	background-color: #656565;
	top: 9px;
	left: calc(50% - 2px);
}

.owl-carousel.text-nav .owl-nav > div {
	position: static;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	color: #656565;
	width: auto;
	padding: 5px 30px;
}

.owl-carousel.text-nav .owl-nav > div:hover {
	color: #0074ba;
}

.owl-carousel.text-nav .owl-nav > div:after {
	display: none;
}

.owl-carousel.text-nav .owl-nav > div.disabled {
	pointer-events: none;
}

/*
** Flexslider
* http://www.woothemes.com/flexslider/
*
* Copyright 2012 WooThemes
* Free to use under the GPLv2 license.
* http://www.gnu.org/licenses/gpl-2.0.html
*
* Contributing author: Tyler Smith (@mbmufffin)
*/
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
	outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider {
	margin: 0;
	padding: 0;
}

.flexslider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
	width: 100%;
	display: block;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slides {
	display: block;
}

* html .slides {
	height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
* include js that eliminates this class on page load */
.no-js .slides > li:first-child {
	display: block;
}

.flexslider .slides > li {
	position: relative;
	height: 960px;
	background-position: center;
	background-size: cover;
}

/* FlexSlider Default Theme */
.flexslider {
	margin: 0 0;
	position: relative;
	zoom: 1;
}

.flex-viewport {
	max-height: 2000px;
	transition: all 1s ease;
}

.loading .flex-viewport {
	max-height: 300px;
}

.flexslider .slides {
	zoom: 1;
	z-index: 1;
}

/* Direction Nav */
.flex-direction-nav {
	*height: 0;
}

.flex-direction-nav a {
	text-decoration: none;
	display: block;
	width: 60px;
	height: 60px;
	margin: -20px 0 0;
	position: absolute;
	top: 50%;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	font-size: 0;
	color: #323232;
	background-color: transparent;
	text-align: center;
}

.flex-direction-nav a:hover, .flex-direction-nav a:active, .flex-direction-nav a:focus {
	color: #0074ba;
}

.ds .flex-direction-nav a {
	color: #ffffff;
}

.ds .flex-direction-nav a:hover {
	color: #0074ba;
}

.flex-direction-nav .flex-prev {
	left: 0px;
}

.flex-direction-nav .flex-next {
	right: 0px;
}

@media (min-width: 1450px) {
	.flex-direction-nav .flex-prev {
		left: 60px;
	}

	.flex-direction-nav .flex-next {
		right: 60px;
	}
}

@media (min-width: 1800px) {
	.flex-direction-nav .flex-prev {
		left: 240px;
	}

	.flex-direction-nav .flex-next {
		right: 240px;
	}
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
	opacity: 1;
}

.flex-direction-nav .flex-disabled {
	opacity: 0;
	filter: alpha(opacity=0);
	cursor: default;
}

.flex-direction-nav a:before {
	font-family: "FontAwesome";
	font-size: 36px;
	line-height: 56px;
	content: '\f104';
}

.flex-direction-nav a.flex-next:before {
	content: '\f105';
}

/* Pause/Play */
.flex-pauseplay a {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 10px;
	opacity: 0.8;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #000;
}

.flex-pauseplay a:before {
	font-family: 'rt-icons-2';
	font-size: 20px;
	display: inline-block;
	content: "\e6a2";
}

.flex-pauseplay a:hover {
	opacity: 1;
}

.flex-pauseplay a.flex-play:before {
	content: "\e711";
}

/* Control Nav */
.flex-control-nav {
	left: 0;
	right: 0;
	bottom: 25px;
	text-align: center;
	z-index: 10;
}

@media (min-width: 992px) {
	.flex-control-nav {
		bottom: 35px;
	}
}

.overlapping-nav .flex-control-nav {
	position: absolute;
}

.flex-control-nav li {
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	padding: 0 10px;
	zoom: 1;
	*display: inline;
}

.flex-control-nav li:first-child {
	padding-left: 0;
}

.flex-control-nav li:last-child {
	padding-right: 0;
}

.flex-control-paging li a {
	width: 12px;
	height: 12px;
	display: block;
	border: 1px solid #656565;
	background-color: transparent;
	color: transparent;
	cursor: pointer;
	text-indent: -9999px;
	border-radius: 50%;
}

.ds .flex-control-paging li a,
.cs .flex-control-paging li a {
	border-color: #000;
}

.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
	background-color: #656565;
}
#vehicules .page_breadcrumbs{
    background-size: contain;
    height: 0;
    padding-bottom: 12%;
    background-position: 50% -25px!important;
}
#vehicules .page_breadcrumbs h2{
	margin-top: 34px;
    display: block;
    margin-bottom: 10px;
}
.cs .flex-control-paging li a:hover, .cs .flex-control-paging li a.flex-active,
.ds .flex-control-paging li a:hover,
.ds .flex-control-paging li a.flex-active {
	background-color: #000;
}

.flex-control-thumbs {
	margin: 5px 0 0;
	position: static;
	overflow: hidden;
}

.flex-control-thumbs li {
	width: 25%;
	float: left;
	margin: 0;
}

.flex-control-thumbs img {
	width: 100%;
	display: block;
	opacity: .7;
	cursor: pointer;
}

.flex-control-thumbs img:hover {
	opacity: 1;
}

.flex-control-thumbs .flex-active {
	opacity: 1;
	cursor: default;
}

@media screen and (max-width: 600px) {
	.flex-direction-nav .flex-prev {
		top: 24%;
	}

	.flex-direction-nav .flex-next {
		top: 24%;
	}
}

@media (min-width: 1300px) {
	.vertical-nav .flex-control-nav {
		position: absolute;
		margin: 0;
		bottom: auto;
		top: 50%;
		right: auto;
		width: auto;
		height: 10px;
		line-height: 10px;
		left: 0;
		-webkit-transform: rotate(90deg) translateY(50%);
		transform: rotate(90deg) translateY(50%);
	}

	.vertical-nav .flex-control-nav:after {
		bottom: -70px;
		height: 50px;
	}
}

@media (min-width: 1600px) {
	.vertical-nav .flex-control-nav {
		left: 4.5%;
	}
}

/*
** prettyPhoto
*/
div.facebook .pp_description {
	margin: 0 37px 0 0;
}

div.pp_pic_holder a:focus {
	outline: none;
}

div.pp_overlay {
	background: #000;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9500;
}

div.pp_pic_holder {
	display: none;
	position: absolute;
	width: 100px;
	z-index: 10000;
}

.pp_content {
	height: 40px;
	min-width: 40px;
	overflow: hidden;
}

* html .pp_content {
	width: 40px;
}

.pp_content_container {
	position: relative;
	text-align: left;
	width: 100%;
}

.pp_content_container .pp_left {
	padding-left: 20px;
}

.pp_content_container .pp_right {
	padding-right: 20px;
}

.pp_content_container .pp_details {
	float: left;
	line-height: 1;
	background-color: #ffffff;
}

.pp_description {
	display: none;
	margin: 0;
}

.pp_social {
	float: left;
	margin: 0 0 0 30px;
}

.pp_social .facebook {
	float: left;
	margin-left: 5px;
	width: auto;
	overflow: hidden;
}

.pp_social .facebook iframe {
	max-width: 100px;
}

.pp_social .twitter {
	float: left;
}

.pp_nav p {
	float: left;
	white-space: nowrap;
	margin: 4px 6px;
	font-weight: bold;
	opacity: 0.7;
}

.pp_nav .pp_play,
.pp_nav .pp_pause,
a.pp_arrow_previous,
a.pp_arrow_next {
	float: left;
	text-align: center;
	font-size: 0;
	color: transparent;
}

.pp_nav .pp_play:before,
.pp_nav .pp_pause:before,
a.pp_arrow_previous:before,
a.pp_arrow_next:before {
	line-height: 20px;
	display: inline-block;
	width: 20px;
	font-family: 'FontAwesome';
	font-size: 12px;
	color: #323232;
	opacity: 0.7;
	content: "\f04b";
}

.pp_nav .pp_play:hover:before,
.pp_nav .pp_pause:hover:before,
a.pp_arrow_previous:hover:before,
a.pp_arrow_next:hover:before {
	opacity: 1;
}

.pp_nav .pp_pause:before {
	content: "\f04c";
}

a.pp_arrow_previous:before {
	content: "\f04a";
}

a.pp_arrow_next:before {
	content: "\f04e";
}

a.pp_arrow_previous.disabled,
a.pp_arrow_next.disabled {
	visibility: hidden;
	cursor: default;
}

.pp_hoverContainer {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;
}

.pp_gallery {
	display: none;
	left: 50%;
	margin-top: -50px;
	position: absolute;
	z-index: 10000;
}

.pp_gallery div {
	float: left;
	overflow: hidden;
	position: relative;
}

.pp_gallery ul {
	float: left;
	height: 35px;
	position: relative;
	white-space: nowrap;
	margin: 0 0 0 5px;
	padding: 0;
}

.pp_gallery ul a {
	border: 1px rgba(0, 0, 0, 0.1) solid;
	display: block;
	float: left;
	height: 33px;
	overflow: hidden;
}

.pp_gallery ul a:hover,
.pp_gallery ul .selected a {
	border-color: #0074ba;
}

.pp_gallery ul a img {
	border: 0;
}

.pp_gallery li {
	display: block;
	float: left;
	margin: 0 5px 0 0;
	padding: 0;
}

.pp_gallery li.default a {
	background: url(../img/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
	display: block;
	height: 33px;
	width: 50px;
}

div.facebook .pp_gallery .pp_arrow_previous, div.facebook .pp_gallery .pp_arrow_next {
	margin-top: 7px;
}

a.pp_next, a.pp_previous {
	position: relative;
	display: block;
	float: right;
	height: 100%;
	width: 49%;
	font-size: 0;
	color: transparent;
	opacity: 0;
}

a.pp_next:before, a.pp_previous:before {
	line-height: 1;
	background-color: rgba(50, 50, 50, 0.3);
	font-size: 18px;
	padding: 10px 15px 10px 18px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f105";
	position: absolute;
	top: 50%;
	margin-top: -3px;
	right: 30px;
}

a.pp_next:hover, a.pp_previous:hover {
	opacity: 1;
}

a.pp_previous {
	float: left;
}

a.pp_previous:before {
	content: "\f104";
	right: auto;
	padding: 10px 18px 10px 15px;
	left: 30px;
}

a.pp_expand, a.pp_contract {
	cursor: pointer;
	display: none;
	position: absolute;
	right: 30px;
	top: 19px;
	z-index: 20000;
	font-size: 0;
	color: transparent;
	text-align: center;
}

a.pp_expand:before, a.pp_contract:before {
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f0b2";
	background-color: rgba(50, 50, 50, 0.3);
	padding: 12px 13px;
}

a.pp_contract:before {
	content: "\f066";
}

a.pp_close {
	position: absolute;
	right: -7px;
	top: -25px;
	font-size: 0;
	color: transparent;
}

a.pp_close:before {
	background-color: #272727;
	padding: 6px 8px;
	font-size: 14px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f00d";
}

.pp_loaderIcon {
	height: 50px;
	width: 50px;
	left: 50%;
	top: 50%;
	position: absolute;
	margin: -25px 0 0 -25px;
	text-align: center;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}

.pp_loaderIcon:before {
	display: block;
	font-family: 'FontAwesome';
	content: "\f1ce";
	color: #ffffff;
	font-size: 50px;
	line-height: 50px;
	opacity: 0.6;
}

#pp_full_res {
	line-height: 1;
}

#pp_full_res .pp_inline {
	text-align: left;
}

#pp_full_res .pp_inline p {
	margin: 0 0 15px;
}

div.ppt {
	color: #ffffff;
	display: none;
	font-size: 18px;
	z-index: 9999;
	margin: 0 0 5px 20px;
}

.pp_top, .pp_bottom {
	position: relative;
}

* html .pp_top, * html .pp_bottom {
	padding: 0 20px;
}

.pp_top .pp_left, .pp_bottom .pp_left {
	height: 20px;
	left: 0;
	position: absolute;
	width: 20px;
}

.pp_top .pp_middle, .pp_bottom .pp_middle {
	height: 20px;
	left: 20px;
	position: absolute;
	right: 20px;
}

* html .pp_top .pp_middle, * html .pp_bottom .pp_middle {
	left: 0;
	position: static;
}

.pp_top .pp_right, .pp_bottom .pp_right {
	height: 20px;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}

.pp_fade, .pp_gallery li.default a img {
	display: none;
}

/*
** Easy pieChart
*/
.chart {
	position: relative;
	display: inline-block;
	margin-bottom: 10px;
	text-align: center;
	line-height: 0;
}

.chart .chart-meta {
	position: absolute;
	width: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.chart .chart-meta h4 {
	font-size: 20px;
	margin: 0;
	font-weight: 500;
	color: #656565;
}

.percent {
	display: inline-block;
	font-size: 60px;
	font-weight: 700;
	line-height: 1;
	z-index: 2;
}

.percent:after {
	content: '%';
	font-size: 35px;
}

.angular {
	margin-top: 100px;
}

.angular .chart {
	margin-top: 0;
}

/*
** Time Table
*/
#timetable span {
	display: block;
}

#timetable th, #timetable td {
	vertical-align: middle;
	font-weight: normal;
	text-align: center;
	padding: 25px 5px;
}

#timetable thead {
	color: #ffffff;
}

#timetable thead th {
	border: 1px solid rgba(255, 255, 255, 0.23);
	border-width: 0 1px;
}

#timetable tbody th, #timetable tbody td {
	border: 1px solid #f2f2f2;
	transition: all 0.2s ease-in-out 0s;
}

#timetable tbody .current {
	background-color: #0074ba;
	border-color: #0074ba;
}

#timetable tbody .current a {
	color: #ffffff;
}

/*
** toTop
*/
#toTop {
	bottom: 10px;
	right: 10px;
	display: none;
	width: 50px;
	height: 50px;
	overflow: hidden;
	position: fixed;
	text-decoration: none;
	z-index: 9999;
	font-size: 0;
	color: transparent;
	border-radius: 50%;
	color: #ffffff;
	background: #0074ba;
}

@media (min-width: 768px) {
	#toTop {
		bottom: 90px;
		right: 30px;
	}
}

@media (min-width: 992px) {
	#toTop {
		width: 60px;
		height: 60px;
		bottom: 90px;
		right: 60px;
	}
}

#toTop:after {
	color: #ffffff;
	content: "\f106";
	font-size: 16px;
	line-height: 50px;
	font-family: FontAwesome;
	text-align: center;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all 0.2s linear;
}

@media (min-width: 992px) {
	#toTop:after {
		line-height: 60px;
	}
}

#toTopHover, #toTop:hover {
	bottom: 15px;
	opacity: 1;
}

@media (min-width: 768px) {
	#toTopHover, #toTop:hover {
		bottom: 35px;
	}
}

@media (min-width: 992px) {
	#toTopHover, #toTop:hover {
		bottom: 65px;
	}
}

#toTop:hover:after {
	color: #ffffff;
}

#toTop:active, #toTop:focus {
	outline: medium none;
}

.modal-open #toTop {
	visibility: hidden;
}

/* Countdown */
#comingsoon-countdown {
	text-align: center;
}

#comingsoon-countdown:before,
#comingsoon-countdown:after {
	clear: both;
	display: table;
	content: ' ';
}

.countdown-rtl {
	direction: rtl;
}

.countdown-row {
	clear: both;
	display: table;
	margin: auto;
	padding: 20px 0;
	text-align: center;
}

.countdown-section {
	display: table-cell;
	padding: 10px 30px 20px;
	text-align: center;
	border-left: 1px solid #f2f2f2;
}

.countdown-section:first-child {
	border-left-width: 0;
}

.countdown-amount {
	font-size: 80px;
	line-height: 1;
	font-weight: 700;
	color: #0074ba;
}

.countdown-period {
	display: block;
	color: #323232;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 700;
}

.countdown-descr {
	display: block;
}

@media (max-width: 767px) {
	.countdown-section {
		padding: 20px 8px 0;
	}

	.countdown-amount {
		font-size: 40px;
	}

	.countdown-period {
		min-width: 4em;
		letter-spacing: 0.1em;
	}
}

/*
** Vertical Margins and Paddings
*/
/*same top and bottom paddings for sections*/
.section_padding_0 > [class*="container"] {
	padding: 0;
}

.section_padding_15 > [class*="container"] {
	padding-top: 15px;
	padding-bottom: 15px;
}

.section_padding_25 > [class*="container"] {
	padding-top: 25px;
	padding-bottom: 25px;
}

.section_padding_50 > [class*="container"] {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section_padding_75 > [class*="container"] {
	padding-top: 75px;
	padding-bottom: 75px;
}

.section_padding_100 > [class*="container"] {
	padding-top: 100px;
	padding-bottom: 100px;
}

/*top paddings for sections*/
.section_padding_top_0 > [class*="container"] {
	padding-top: 0;
}

.section_padding_top_5 > [class*="container"] {
	padding-top: 5px;
}

.section_padding_top_10 > [class*="container"] {
	padding-top: 10px;
}

.section_padding_top_15 > [class*="container"] {
	padding-top: 15px;
}

.section_padding_top_20 > [class*="container"] {
	padding-top: 20px;
}

.section_padding_top_25 > [class*="container"] {
	padding-top: 25px;
}

.section_padding_top_30 > [class*="container"] {
	padding-top: 30px;
}

.section_padding_top_40 > [class*="container"] {
	padding-top: 40px;
}

.section_padding_top_50 > [class*="container"] {
	padding-top: 50px;
}

.section_padding_top_65 > [class*="container"] {
	padding-top: 65px;
}

.section_padding_top_75 > [class*="container"] {
	padding-top: 75px;
}

.section_padding_top_100 > [class*="container"] {
	padding-top: 100px;
}

.section_padding_top_130 > [class*="container"] {
	padding-top: 130px;
}

.section_padding_top_150 > [class*="container"] {
	padding-top: 150px;
}

/*bottom paddings for sections*/
.section_padding_bottom_0 > [class*="container"] {
	padding-bottom: 0;
}

.section_padding_bottom_5 > [class*="container"] {
	padding-bottom: 5px;
}

.section_padding_bottom_10 > [class*="container"] {
	padding-bottom: 10px;
}

.section_padding_bottom_15 > [class*="container"] {
	padding-bottom: 15px;
}

.section_padding_bottom_20 > [class*="container"] {
	padding-bottom: 20px;
}

.section_padding_bottom_25 > [class*="container"] {
	padding-bottom: 25px;
}

.section_padding_bottom_30 > [class*="container"] {
	padding-bottom: 30px;
}

.section_padding_bottom_40 > [class*="container"] {
	padding-bottom: 40px;
}

.section_padding_bottom_50 > [class*="container"] {
	padding-bottom: 50px;
}

.section_padding_bottom_65 > [class*="container"] {
	padding-bottom: 65px;
}

.section_padding_bottom_75 > [class*="container"] {
	padding-bottom: 75px;
}

.section_padding_bottom_100 > [class*="container"] {
	padding-bottom: 100px;
}

.section_padding_bottom_130 > [class*="container"] {
	padding-bottom: 130px;
}

.section_padding_bottom_150 > [class*="container"] {
	padding-bottom: 150px;
}

@media (max-width: 1200px) {
	.section_padding_100 > [class*="container"],
  .section_padding_top_100 > [class*="container"],
  .section_padding_top_130 > [class*="container"],
  .section_padding_top_150 > [class*="container"] {
		padding-top: 75px;
	}

	.section_padding_100 > [class*="container"],
  .section_padding_bottom_100 > [class*="container"],
  .section_padding_bottom_130 > [class*="container"],
  .section_padding_bottom_150 > [class*="container"] {
		padding-bottom: 75px;
	}
}

[class*="col-"] {
	margin-top: 10px;
	margin-bottom: 10px;
}

.columns_margin_0 [class*="col-"] {
	margin-top: 0;
	margin-bottom: 0;
}

.columns_padding_1 [class*="col-"] {
	margin-top: 0;
	margin-bottom: 1px;
}

.columns_padding_2 [class*="col-"] {
	margin-top: 1px;
	margin-bottom: 1px;
}

.columns_padding_5 [class*="col-"] {
	margin-top: 5px;
	margin-bottom: 5px;
}

.columns_padding_10 [class*="col-"] {
	margin-top: 10px;
	margin-bottom: 10px;
}

.columns_padding_1 [class*="col-"] {
	padding-left: 0;
	padding-right: 1px;
}

.columns_padding_2 [class*="col-"] {
	padding-left: 1px;
	padding-right: 1px;
}

.columns_padding_5 [class*="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}

.columns_padding_10 [class*="col-"] {
	padding-left: 10px;
	padding-right: 10px;
}

.columns_padding_0 [class*="col-"] {
	padding: 0;
}

.columns_padding_0 > .container,
.columns_padding_1 > .container,
.columns_padding_2 > .container {
	padding-left: 30px;
	padding-right: 30px;
}

.columns_padding_5 > .container {
	padding-left: 25px;
	padding-right: 25px;
}

.columns_padding_10 > .container {
	padding-left: 20px;
	padding-right: 20px;
}

.columns_padding_10 > .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

.container_padding_0 > .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.container_padding_0 > .container-fluid > .row {
	margin: 0;
}

.row.columns_padding_0,
.columns_padding_0 .row {
	margin-left: 0;
	margin-right: 0;
}

.row.columns_padding_0[class*="display_table"],
.columns_padding_0 .row[class*="display_table"] {
	margin-right: -15px;
	margin-left: -15px;
	min-width: calc(100% + 30px);
}

.row.columns_padding_1,
.columns_padding_1 .row {
	margin-left: 0;
	margin-right: -1px;
}

.row.columns_padding_2,
.columns_padding_2 .row {
	margin-left: -1px;
	margin-right: -1px;
}

.row.columns_padding_5,
.columns_padding_5 .row {
	margin-left: -5px;
	margin-right: -5px;
}

.row.columns_padding_10,
.columns_padding_10 .row {
	margin-left: -10px;
	margin-right: -10px;
}

@media (min-width: 992px) {
	.columns_padding_25 > [class*="container"] > .row,
  .row.columns_padding_25,
  .columns_padding_50 > [class*="container"] > .row {
		margin-right: -25px;
		margin-left: -25px;
	}

	.columns_padding_25 > [class*="container"] > .row > [class*="col-"],
  .row.columns_padding_25 > [class*="col-"],
  .columns_padding_50 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 25px;
		padding-right: 25px;
	}

	.columns_padding_80 > [class*="container"] > .row {
		margin-right: -50px;
		margin-left: -50px;
	}

	.columns_padding_80 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 50px;
		padding-right: 50px;
	}
}

@media (min-width: 1200px) {
	.columns_padding_50 > [class*="container"] > .row {
		margin-right: -50px;
		margin-left: -50px;
	}

	.columns_padding_50 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 50px;
		padding-right: 50px;
	}

	.columns_padding_80 > [class*="container"] > .row {
		margin-right: -80px;
		margin-left: -80px;
	}

	.columns_padding_80 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 80px;
		padding-right: 80px;
	}
}

/*top and bottom margins for columns inside sections and rows */
.columns_margin_top_0 [class*="col-"],
.row.columns_margin_top_0 [class*="col-"] {
	margin-top: 0;
}

.columns_margin_top_5 [class*="col-"],
.row.columns_margin_top_5 [class*="col-"] {
	margin-top: 5px;
}

.columns_margin_top_10 [class*="col-"],
.row.columns_margin_top_10 [class*="col-"] {
	margin-top: 10px;
}

.columns_margin_top_15 [class*="col-"],
.row.columns_margin_top_15 [class*="col-"] {
	margin-top: 15px;
}

.columns_margin_top_20 [class*="col-"],
.row.columns_margin_top_20 [class*="col-"] {
	margin-top: 20px;
}

.columns_margin_top_30 [class*="col-"],
.row.columns_margin_top_30 [class*="col-"] {
	margin-top: 30px;
}

.columns_margin_top_60 [class*="col-"],
.row.columns_margin_top_60 [class*="col-"] {
	margin-top: 60px;
}

.columns_margin_bottom_5 [class*="col-"],
.row.columns_margin_bottom_5 [class*="col-"] {
	margin-bottom: 5px;
}

.columns_margin_bottom_10 [class*="col-"],
.row.columns_margin_bottom_10 [class*="col-"] {
	margin-bottom: 10px;
}

.columns_margin_bottom_15 [class*="col-"],
.row.columns_margin_bottom_15 [class*="col-"] {
	margin-bottom: 15px;
}

.columns_margin_bottom_20 [class*="col-"],
.row.columns_margin_bottom_20 [class*="col-"] {
	margin-bottom: 20px;
}

.columns_margin_bottom_30 [class*="col-"],
.row.columns_margin_bottom_30 [class*="col-"] {
	margin-bottom: 30px;
}

.columns_margin_bottom_40 [class*="col-"],
.row.columns_margin_bottom_40 [class*="col-"] {
	margin-bottom: 40px;
}

.columns_margin_bottom_0 [class*="col-"],
.row.columns_margin_bottom_0 [class*="col-"] {
	margin-bottom: 0;
}

/* vertical alignmetn in columns */
.table_section .row {
	min-width: 100%;
	height: 100%;
}

@media (max-width: 767px) {
	aside {
		margin-top: 60px !important;
	}
}

@media (min-width: 768px) {
	.table_section_sm .container {
		height: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.table_section.table_section_sm .row {
		display: table;
	}

	.table_section .container .row {
		margin-left: 0;
		margin-right: 0;
	}

	.table_section.table_section_sm > [class*="container"] > .row > [class*="col-"] {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}
}

@media (min-width: 992px) {
	.table_section_md .container {
		height: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.table_section.table_section_md .row {
		display: table;
	}

	.table_section.table_section_md > [class*="container"] > .row > [class*="col-"] {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}
}

@media screen and (min-width: 1200px) {
	.table_section_lg .container {
		height: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.table_section.table_section_lg .row {
		display: table;
	}

	.table_section.table_section_lg > [class*="container"] > .row > [class*="col-"] {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}
}

@media screen and (min-width: 992px) {
  /* overlap featured person */
	.top-overlap {
		margin-top: -100px;
		max-width: 125%;
		margin-left: -25%;
	}

	.top-overlap-small {
		margin-top: -40px;
		max-width: 140%;
		margin-right: -20%;
		margin-left: -20%;
	}
}

@media (min-width: 1200px) {
	.top-overlap {
		margin-top: -180px;
	}
}

.section_full_height {
	min-height: 100vh;
}

.flex-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.flex-row > [class*='col-'] {
	min-width: 300px;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.flex-row > [class*='col-'] > * {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

@media (min-width: 992px) and (max-width: 1199px) {
	.flex-row > [class*='col-'].col-md-6 {
		-webkit-box-flex: 1;
		-ms-flex: auto;
		flex: auto;
		width: 50%;
	}
}

@media (max-width: 991px) {
	.flex-row > [class*='col-'].col-sm-12 {
		-webkit-box-flex: 1;
		-ms-flex: auto;
		flex: auto;
		width: 100%;
	}
}

.flex-row.flex-row-vertical-centered > [class*='col-'] > * {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

@media (min-width: 992px) {
	.container-fluid {
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media (min-width: 1200px) {
	.container-fluid {
		padding-right: 60px;
		padding-left: 60px;
	}
}

@media (min-width: 1600px) {
	.container-fluid {
		padding-right: 120px;
		padding-left: 120px;
	}
}

.fluid_padding_0 .container-fluid {
	padding-right: 15px;
	padding-left: 15px;
}

.columns_padding_0.fluid_padding_0 .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

/* float clear fixes */
@media (min-width: 768px) and (max-width: 991px) {
	.clear-sm {
		clear: both;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.clear-md {
		clear: both;
	}
}

@media (min-width: 1200px) {
	.clear-lg {
		clear: both;
	}
}

/* additional columns classes*/
@media (max-width: 499px) {
	.col-xxs-12 {
		width: 100%;
	}
}

@media (min-width: 1600px) {
	.col-xlg-7 {
		width: 58.333333333%;
	}
}

/*
** Common Sections Styles
*/
/*parallax*/
.image_cover,
.background_cover,
.parallax {
	background-size: cover;
}

.background_cover {
	background-position: center;
}

.image_cover {
	display: block;
	position: absolute;
	width: 50%;
	z-index: 2;
	top: 0;
	bottom: 0;
	background-position: 50% 50%;
}

.image_cover > a {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.image_cover.image_cover_right {
	right: 0;
}

@media (min-width: 1600px) {
	.image_cover.small_width {
		width: 42%;
	}
}

@media (max-width: 1199px) {
	.image_cover.images_grid {
		position: relative;
		width: 100%;
		padding-bottom: 0;
	}
}

@media (max-width: 991px) {
	.image_cover {
		height: 0;
		padding-bottom: 66%;
		margin-bottom: -30px;
		width: 100%;
		position: relative;
	}

	.image_cover.small_width {
		width: 100%;
		padding-bottom: 100%;
	}
}

.image_cover + [class*='container'] [class*='col-'] > img,
.image_cover + [class*='container'] [class*='col-'] > a:after,
.image_cover + [class*='container'] [class*='col-'] > a > img {
	display: none;
}

@media (min-width: 992px) {
	.half_section.columns_padding_80 > [class*="container"] > .row {
		margin-left: 0;
		margin-right: 0;
	}
}

.half_section .image_cover_left + .container .col-md-6 {
	padding-right: 0;
}

.half_section .image_cover_right + .container .col-md-6 {
	padding-left: 0;
}

.two_col_image_grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.two_col_image_grid > div {
	width: 50%;
}

@media (min-width: 992px) and (max-width: 1199px) {
	.two_col_image_grid {
		position: relative;
	}

	.two_col_image_grid > div {
		width: 25%;
		padding-bottom: 25%;
	}
}

.parallax {
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

@media (max-width: 767px) {
	.parallax {
		background-size: auto auto;
	}
}

.parallax.ls:after {
	background-color: #ffffff;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=92)";
	filter: alpha(opacity=90);
	opacity: 0.9;
}

.parallax.ls.ms:after {
	background-color: #fafafa;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=88)";
	filter: alpha(opacity=80);
	opacity: 0.8;
}

.parallax.cs:after {
	background-color: #0074ba;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=90);
	opacity: 0.9;
}

.parallax.cs.main_color2:after {
	background-color: #bee27b;
}

.parallax.cs.main_color3:after {
	background-color: #77c7e5;
}

.cs.gradient.color_overlay:after {
	background: linear-gradient(-90deg, #bee27b, #0074ba);
}

.parallax.ds:after {
	background-color: #323232;
	opacity: 0.8;
}

.background_cover.ds:after {
	background-color: #323232;
	opacity: 0.9;
}

.parallax.ds.ms:after {
	background-color: #272727;
	opacity: 0.9;
}

.parallax.ds.bs:after {
	background-color: #00202b;
	opacity: 0.3;
}

.parallax.ds.black:after {
	background-color: #000;
	opacity: 0.6;
}

.texture_bg {
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

.muted_section:before {
	background-color: rgba(50, 50, 50, 0.1);
	opacity: 1;
}

.parallax > *,
.muted_section > *,
.radial_gradient > *,
.gradient > *,
.vertical_gradient > *,
.darken_gradient > *,
.diagonal_section > *,
.color_overlay > * {
	z-index: 4;
	position: relative;
}

.radial_gradient {
	position: relative;
	overflow: hidden;
}

.gradient,
.muted_section,
.vertical_gradient,
.darken_gradient,
.color_overlay {
	position: relative;
}

.muted_section:before,
.muted_section:after,
.parallax:before,
.parallax:after,
.gradient:before,
.gradient:after,
.vertical_gradient:before,
.vertical_gradient:after,
.darken_gradient:before,
.color_overlay:after,
.darken_gradient:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
}

.muted_section:before,
.muted_section:after,
.parallax:before,
.parallax:after,
.background_cover:after {
	opacity: 0.8;
}

.parallax.clear_parallax:before, .parallax.clear_parallax:after {
	display: none;
}

.overflow_hidden {
	overflow: hidden;
	position: relative;
}

.with_top_border,
.with_bottom_border,
.with_top_border_container .container,
.with_bottom_border_container .container {
	position: relative;
}

.with_top_border:before,
.with_bottom_border:after,
.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	left: 0;
	right: 0;
	top: 0;
	background-color: #f2f2f2;
	z-index: 1;
}

.ds .with_top_border:before, .ds
.with_bottom_border:after, .ds
.with_top_border_container .container:before, .ds
.with_bottom_border_container .container:after {
	background-color: #3b3b3b;
}

.ds.with_top_border:before, .ds.with_bottom_border:after,
.ds.with_top_border_container .container:before,
.ds.with_bottom_border_container .container:after {
	background-color: #3b3b3b;
}

.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
	right: 15px;
	left: 15px;
}

.with_bottom_border:after,
.with_bottom_border_container .container:after,
.with_bottom_border_two_colors:after {
	top: auto;
	bottom: 0;
}

.bg_image,
.bg_image:after {
	background-image: url(../img/background_section.png);
}

/* Hero text in sections */
h2.section_header {
	margin-bottom: 20px;
	font-size: 36px;
	position: relative;
	word-wrap: break-word;
	line-height: 1.4;
	font-weight: 100;
}

h2.section_header.small,
h2.section_header .small {
	display: block;
	font-size: 24px;
}

@media (min-width: 992px) {
	h2.section_header {
		font-size: 48px;
	}

	h2.section_header.small,
  h2.section_header .small {
		font-size: 30px;
	}
}

@media (min-width: 1600px) {
	h2.section_header.big {
		font-size: 42px;
	}
}

h2.section_header.text-center + hr[class*="divider_"],
h2.section_header.text-center + hr[class*="_divider"] {
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}

h2.section_header.text-center + hr[class*="divider_"]:before,
h2.section_header.text-center + hr[class*="_divider"]:before {
	margin-left: -35px;
}

.text-center h2.section_header + hr + .small-text,
h2.section_header.text-center + hr + .small-text {
	text-align: center;
	max-width: 780px;
	margin-right: auto;
	margin-left: auto;
}

h2.section_header + hr {
	margin-bottom: 30px;
}

/*page preloader*/
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.99);
	z-index: 13000;
	height: 100%;
}

.no-js .preloader,
.lt-ie9 .preloader,
.lt-ie10 .preloader {
	display: none;
}

.preloader_image {
	width: 100px;
	height: 100px;
	position: absolute;
	left: 50%;
	top: 50%;
	background: url(../img/preloader.gif) no-repeat 50% 50% transparent;
	margin: -50px 0 0 -50px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.preloader_image {
		background: url(../img/preloader.gif) no-repeat 50% 50% transparent;
	}
}

/* animated elements */
.to_animate {
	visibility: hidden;
}

.no-js .to_animate,
.lt-ie10 .to_animate,
.lt-ie9 .to_animate {
	visibility: visible;
}

.animated {
  /*IE7 fix*/
	visibility: visible !important;
}

/* boxed layout */
#box_wrapper, #canvas {
	overflow: hidden;
	position: relative;
}

#box_wrapper > section,
#box_wrapper > div > section {
	clear: both;
}

#canvas.boxed {
	padding: 1px 0;
}

body.boxed {
	position: static;
	width: auto;
	height: auto;
}

.pattern1 {
	background-image: url(../img/pattern1.png);
}

.pattern2 {
	background-image: url(../img/pattern2.png);
}

.pattern3 {
	background-image: url(../img/pattern3.png);
}

.pattern4 {
	background-image: url(../img/pattern4.png);
}

.pattern5 {
	background-image: url(../img/pattern5.png);
}

.pattern6 {
	background-image: url(../img/pattern6.png);
}

.pattern7 {
	background-image: url(../img/pattern7.png);
}

.pattern8 {
	background-image: url(../img/pattern8.png);
}

.pattern9 {
	background-image: url(../img/pattern9.png);
}

.pattern10 {
	background-image: url(../img/pattern10.png);
}

.pattern11 {
	background-image: url(../img/pattern11.png);
}

.boxed .container,
.boxed .container-fluid,
.boxed .container-left-half,
.boxed .container-right-half {
	max-width: 100%;
}

.boxed .container,
.boxed .container-left-half,
.boxed .container-right-half {
	padding-left: 25px;
	padding-right: 25px;
}

@media (max-width: 500px) {
	.boxed .container {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 1400px) {
	.boxed #box_wrapper.container {
		width: 1370px;
	}
}

@media (min-width: 1590px) {
	.boxed #box_wrapper.container {
		width: 1560px;
	}
}

.boxed #box_wrapper.container {
	padding: 0;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

/* full height for 404 page */
#box_wrapper, #canvas {
	min-height: 100vh;
}

#box_wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.section_404 {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

/*
**utilites
*/
/*
**Helper Utilites
*/
/* layout */
.clear, .clearfix {
	clear: both;
}

@media (min-width: 768px) {
	.alignleft {
		float: left;
		margin: 5px 30px 15px 0;
		max-width: 50%;
	}

	.alignright {
		float: right;
		margin: 5px 0 15px 30px;
		max-width: 50%;
	}

	.alignright.one-third,
  .alignleft.one-third {
		max-width: 33.33333%;
	}
}

@media (min-width: 1200px) {
	.col-sm-12 .alignleft {
		margin-right: 50px;
		margin-bottom: 40px;
	}

	.col-sm-12 .alignright {
		margin-left: 50px;
		margin-bottom: 40px;
	}
}

.round {
	border-radius: 50%;
}

.rounded {
	border-radius: 3px;
}

.top_rounded {
	border-radius: 3px 3px 0 0;
}

.bottom_rounded {
	border-radius: 0 0 3px 3px;
}

.overflow_hidden {
	overflow: hidden;
}

.inline-block {
	display: inline-block;
	max-width: 100%;
}

.display-block {
	display: block;
}

.divided-content > * {
	display: inline-block;
}

.divided-content > *:before,
.divided-content > *:last-child:after {
	content: "";
	width: 1px;
	height: 8px;
	background-color: rgba(101, 101, 101, 0.6);
	display: inline-block;
	margin-right: 14px;
	margin-left: 10px;
	color: #656565;
}

.divided-content > *:first-child:before {
	margin-left: 0;
	display: none;
}

.divided-content > *:last-child:after {
	margin-right: 0;
	display: none;
}

.divided-content.outside-dividers > *:first-child:before,
.divided-content.outside-dividers > *:last-child:after {
	display: inline-block;
}

.with_padding {
	padding: 20px;
}

@media (min-width: 400px) {
	.with_padding {
		padding: 35px;
	}
}

@media (min-width: 400px) {
	.with_padding.small_padding {
		padding: 25px;
	}
}

@media (min-width: 1200px) {
	.with_padding.big-padding {
		padding: 55px;
	}
}

.with_background {
	background-color: #fafafa;
}

.with_background .with_background {
	background-color: #ffffff;
}

.muted_background {
	background-color: #f2f2f2;
}

.with_border {
	border: 1px solid #f2f2f2;
}

.with_shadow {
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.with_big_shadow {
	box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.1);
}

.no_top_border {
	border-top: none;
}

.no_bottom_border {
	border-bottom: none;
}

.bottom_color_border {
	border-bottom: 5px solid #0074ba;
}

.bottom-border {
	position: relative;
}

.bottom-border:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.1);
}

.before_cover,
.after_cover {
	overflow: hidden;
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > * {
	position: relative;
	z-index: 2;
}

.before_cover:before,
.after_cover:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.top-corner {
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
}

.big {
	font-size: 40px;
	font-weight: 500;
	line-height: 1;
}

/* borders, colors and backgrouns */
.response,
.highlight,
.highlight1 {
	color: #0074ba;
	border-color: #0074ba;
}

.highlightdark {
	color: #323232;
	border-color: #323232;
}

.highlight2 {
	color: #bee27b;
	border-color: #bee27b;
}

.highlight3 {
	color: #67dfc1;
	border-color: #67dfc1;
}

.highlight4 {
	color: #009d83;
	border-color: #009d83;
}

.red, .required {
	color: #fa5c5d;
}

.lightgrey {
	color: #939393;
}

.black {
	color: #272727;
	border-color: #272727;
}

.grey {
	color: #323232;
	border-color: #323232;
}

.light {
	color: #ffffff;
}

.fontcolor {
	color: #656565;
}

.greylinks a {
	color: #656565;
}

.greylinks a.social-icon {
	color: rgba(101, 101, 101, 0.6);
}

.greylinks a.social-icon:hover, .greylinks a.social-icon:focus, .greylinks a.social-icon:active {
	color: #0074ba;
}

.darklinks a {
	color: #323232;
}

.darklinks a:hover {
	color: #0074ba;
}

.colorlinks a {
	color: #bee27b;
}

.colorlinks a:hover {
	color: #0074ba;
}

.underlined-links a {
	text-decoration: underline;
}

.thin {
	font-weight: 100;
}

.regular {
	font-weight: 400;
}

.medium {
	font-weight: 500;
}

.semibold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.weight-black {
	font-weight: 900;
}

.success_color {
	color: #9fc201;
}

.info_color {
	color: #19bcdb;
}

.warning_color {
	color: #f4b803;
}

.danger_color {
	color: #ec5c42;
}

.main_bg_color,
.main_bg_color2,
.main_bg_color3,
.main_bg_color4,
.dark_bg_color,
.darkblue_bg_color,
.darkgrey_bg_color,
.darkgrey_bg.bg_teaser,
.success_bg_color,
.info_bg_color,
.warning_bg_color,
.danger_bg_color,
.danger_bg_color,
.transp_gradient_bg,
[class*="gradient_bg"] {
	color: #ffffff;
}

.main_bg_color h1, .main_bg_color h2, .main_bg_color h3, .main_bg_color h4, .main_bg_color h5, .main_bg_color h6,
.main_bg_color2 h1,
.main_bg_color2 h2,
.main_bg_color2 h3,
.main_bg_color2 h4,
.main_bg_color2 h5,
.main_bg_color2 h6,
.main_bg_color3 h1,
.main_bg_color3 h2,
.main_bg_color3 h3,
.main_bg_color3 h4,
.main_bg_color3 h5,
.main_bg_color3 h6,
.main_bg_color4 h1,
.main_bg_color4 h2,
.main_bg_color4 h3,
.main_bg_color4 h4,
.main_bg_color4 h5,
.main_bg_color4 h6,
.dark_bg_color h1,
.dark_bg_color h2,
.dark_bg_color h3,
.dark_bg_color h4,
.dark_bg_color h5,
.dark_bg_color h6,
.darkblue_bg_color h1,
.darkblue_bg_color h2,
.darkblue_bg_color h3,
.darkblue_bg_color h4,
.darkblue_bg_color h5,
.darkblue_bg_color h6,
.darkgrey_bg_color h1,
.darkgrey_bg_color h2,
.darkgrey_bg_color h3,
.darkgrey_bg_color h4,
.darkgrey_bg_color h5,
.darkgrey_bg_color h6,
.darkgrey_bg.bg_teaser h1,
.darkgrey_bg.bg_teaser h2,
.darkgrey_bg.bg_teaser h3,
.darkgrey_bg.bg_teaser h4,
.darkgrey_bg.bg_teaser h5,
.darkgrey_bg.bg_teaser h6,
.success_bg_color h1,
.success_bg_color h2,
.success_bg_color h3,
.success_bg_color h4,
.success_bg_color h5,
.success_bg_color h6,
.info_bg_color h1,
.info_bg_color h2,
.info_bg_color h3,
.info_bg_color h4,
.info_bg_color h5,
.info_bg_color h6,
.warning_bg_color h1,
.warning_bg_color h2,
.warning_bg_color h3,
.warning_bg_color h4,
.warning_bg_color h5,
.warning_bg_color h6,
.danger_bg_color h1,
.danger_bg_color h2,
.danger_bg_color h3,
.danger_bg_color h4,
.danger_bg_color h5,
.danger_bg_color h6,
.danger_bg_color h1,
.danger_bg_color h2,
.danger_bg_color h3,
.danger_bg_color h4,
.danger_bg_color h5,
.danger_bg_color h6,
.transp_gradient_bg h1,
.transp_gradient_bg h2,
.transp_gradient_bg h3,
.transp_gradient_bg h4,
.transp_gradient_bg h5,
.transp_gradient_bg h6,
[class*="gradient_bg"] h1,
[class*="gradient_bg"] h2,
[class*="gradient_bg"] h3,
[class*="gradient_bg"] h4,
[class*="gradient_bg"] h5,
[class*="gradient_bg"] h6 {
	color: #ffffff;
}

.main_bg_color {
	background-color: #0074ba;
}

.main_bg_color_mutted {
	background-color: #7db0bf;
}

.main_bg_color2 {
	background-color: #bee27b;
}

.main_bg_color3 {
	background-color: #67dfc1;
}

.main_bg_color4 {
	background-color: #009d83;
}

.dark_bg_color {
	background-color: #272727;
}

.darkblue_bg_color {
	color: #ffffff;
	background-color: #00202b;
}

.darkgrey_bg_color {
	color: #ffffff;
	background-color: #323232;
}

.success_bg_color {
	background-color: #9fc201;
}

.info_bg_color {
	background-color: #19bcdb;
}

.warning_bg_color {
	background-color: #f4b803;
}

.danger_bg_color {
	background-color: #ec5c42;
}

.light_bg_color {
	background-color: #ffffff;
}

.gradient_bg {
	background: linear-gradient(to right, #0074ba, #bee27b);
}

.gradient_bg1 {
	background: linear-gradient(-191deg, #c2e275, #9bdda1);
}

.gradient_bg2 {
	background: linear-gradient(-191deg, #8de2a8, #59ddc8);
}

.gradient_bg3 {
	background: linear-gradient(-191deg, #8ed9ce, #5ad3e1);
}

.transp_color4_bg {
	background-color: rgba(121, 215, 216, 0.6);
}

.transp_bg {
	background-color: transparent !important;
}

.theme_bg {
	background-color: #ffffff;
}

/*dividers, margins and paddings*/
.divider_20 {
	margin: 20px 0;
}

.divider_30 {
	margin: 30px 0;
}

.divider_40 {
	margin: 40px 0;
}

.text-center hr[class*="divider_"],
.text-center hr[class*="_divider"] {
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}

.text-center hr[class*="divider_"]:before,
.text-center hr[class*="_divider"]:before {
	margin-left: -35px;
}

.margin_0 {
	margin: 0 !important;
}

.topmargin_0 {
	margin-top: 0 !important;
}

.topmargin_1 {
	margin-top: 1px;
}

.topmargin_5 {
	margin-top: 5px;
}

.topmargin_10 {
	margin-top: 10px;
}

.topmargin_15 {
	margin-top: 15px;
}

.topmargin_20 {
	margin-top: 20px;
}

.topmargin_25 {
	margin-top: 25px;
}

.topmargin_30 {
	margin-top: 30px;
}

.topmargin_35 {
	margin-top: 35px;
}

.topmargin_40 {
	margin-top: 40px;
}

.topmargin_45 {
	margin-top: 45px;
}

.topmargin_50 {
	margin-top: 50px;
}

.topmargin_60 {
	margin-top: 60px;
}

.topmargin_80 {
	margin-top: 80px;
}

.bottommargin_0 {
	margin-bottom: 0 !important;
}

.bottommargin_5 {
	margin-bottom: 5px;
}

.bottommargin_10 {
	margin-bottom: 10px;
}

.bottommargin_15 {
	margin-bottom: 15px;
}

.bottommargin_20 {
	margin-bottom: 20px;
}

.bottommargin_25 {
	margin-bottom: 25px;
}

.bottommargin_30 {
	margin-bottom: 30px;
}

.bottommargin_40 {
	margin-bottom: 40px;
}

.bottommargin_50 {
	margin-bottom: 50px;
}

.bottommargin_60 {
	margin-bottom: 60px;
}

.leftmargin_10 {
	margin-left: 10px;
}

.leftmargin_20 {
	margin-left: 20px;
}

.leftmargin_30 {
	margin-left: 30px;
}

.leftmargin_40 {
	margin-left: 40px;
}

.leftmargin_50 {
	margin-left: 50px;
}

.leftmargin_60 {
	margin-left: 60px;
}

.rightmargin_10 {
	margin-right: 10px;
}

.rightmargin_20 {
	margin-right: 20px;
}

.rightmargin_30 {
	margin-right: 30px;
}

.rightmargin_40 {
	margin-right: 40px;
}

.rightmargin_50 {
	margin-right: 50px;
}

.rightmargin_60 {
	margin-right: 60px;
}

.padding_0 {
	padding: 0;
}

.padding_10 {
	padding: 10px;
}

.padding_20 {
	padding: 20px;
}

.padding_30 {
	padding: 30px;
}

.padding_40 {
	padding: 40px;
}

.toppadding_10 {
	padding-top: 10px;
}

.toppadding_20 {
	padding-top: 20px;
}

.toppadding_30 {
	padding-top: 30px;
}

.toppadding_40 {
	padding-top: 40px;
}

.toppadding_50 {
	padding-top: 50px;
}

.toppadding_60 {
	padding-top: 60px;
}

.bottompadding_10 {
	padding-bottom: 10px;
}

.bottompadding_20 {
	padding-bottom: 20px;
}

.bottompadding_30 {
	padding-bottom: 30px;
}

.bottompadding_40 {
	padding-bottom: 40px;
}

.bottompadding_50 {
	padding-bottom: 50px;
}

.bottompadding_60 {
	padding-bottom: 60px;
}

.leftpadding_10 {
	padding-left: 10px;
}

.leftpadding_20 {
	padding-left: 20px;
}

.leftpadding_30 {
	padding-left: 30px;
}

.leftpadding_40 {
	padding-left: 40px;
}

.leftpadding_50 {
	padding-left: 50px;
}

.leftpadding_60 {
	padding-left: 60px;
}

.rightpadding_5 {
	padding-right: 5px;
}

.rightpadding_10 {
	padding-right: 10px;
}

.rightpadding_20 {
	padding-right: 20px;
}

.rightpadding_25 {
	padding-right: 25px;
}

.rightpadding_30 {
	padding-right: 30px;
}

.rightpadding_40 {
	padding-right: 40px;
}

.rightpadding_50 {
	padding-right: 50px;
}

.rightpadding_60 {
	padding-right: 60px;
}

.fontsize_12 {
	font-size: 12px;
}

.fontsize_14 {
	font-size: 14px;
}

.fontsize_16 {
	font-size: 16px;
}

.fontsize_18 {
	font-size: 18px;
}

.fontsize_20 {
	font-size: 20px;
	line-height: 30px;
}

.fontsize_24 {
	font-size: 24px;
}

.fontsize_26 {
	font-size: 26px;
}

.fontsize_28 {
	font-size: 28px;
}

.fontsize_30 {
	font-size: 30px;
}

.fontsize_32 {
	font-size: 32px;
}

.playfair {
	font-family: 'Playfair Display', serif;
}

.lineheight_thin {
	line-height: 24px;
}

/*alignment*/
.display_table {
	display: table;
	min-width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	border-collapse: collapse;
}

.display_table_cell {
	display: table-cell;
	vertical-align: middle;
	float: none;
	overflow: hidden;
	zoom: 1;
	box-sizing: border-box;
}

@media (min-width: 1200px) {
	.pull-lg-right {
		float: right;
	}

	.pull-lg-left {
		float: left;
	}

	.text-lg-right {
		text-align: right;
	}

	.text-lg-left {
		text-align: left;
	}

	.text-lg-center {
		text-align: center;
	}

	.display_table_lg {
		display: table;
		min-width: 100%;
		/*height: 100%;*/
		margin-left: auto;
		margin-right: auto;
		border-collapse: collapse;
	}

	.display_table_cell_lg {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 992px) {
	.pull-md-right {
		float: right;
	}

	.pull-md-left {
		float: left;
	}

	.text-md-right {
		text-align: right;
	}

	.text-md-left {
		text-align: left;
	}

	.text-md-center {
		text-align: center;
	}

	.display_inline_md {
		display: inline;
	}

	.display_table_md {
		display: table;
		min-width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		border-collapse: collapse;
	}

	.display_table_cell_md {
		display: table-cell;
		vertical-align: middle;
		float: none;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 768px) {
	.pull-sm-right {
		float: right;
	}

	.pull-sm-left {
		float: left;
	}

	.text-sm-right {
		text-align: right;
	}

	.text-sm-left {
		text-align: left;
	}

	.text-sm-center {
		text-align: center;
	}

	.display_table_sm {
		display: table;
		min-width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		border-collapse: collapse;
	}

	.display_table_cell_sm {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (max-width: 767px) {
	.text-xs-right {
		text-align: right;
	}

	.text-xs-left {
		text-align: left;
	}

	.text-xs-center {
		text-align: center;
	}
}

@media (max-width: 500px) {
	.hidden-xxs {
		display: none;
	}
}

.inline-dropdown {
	list-style: none;
	margin: 0;
}

.inline-dropdown > li {
	display: inline-block;
	margin: 0 -2px;
}

.small-text {
	font-weight: 400;
	font-size: 12px;
	text-transform: uppercase;
}

.small-text.big-spacing {
	letter-spacing: 0.1em;
}

.small-text.big {
	font-size: 14px;
	letter-spacing: 0.2em;
	line-height: 25px;
}

.small-text i {
	font-size: 14px;
}

.nowrap {
	white-space: nowrap;
}

.media.inline-block {
	display: inline-block;
}

.media.inline-block .media-body {
	width: auto;
}

.content-justify {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.content-justify.content-margins > * {
	margin-top: 10px;
	margin-bottom: 10px;
}

.content-justify.nowrap {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.two-col-grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.two-col-grid > * {
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
}

.two-col-grid > * + * {
	border-top: 1px solid #f2f2f2;
}

@media (min-width: 500px) {
	.two-col-grid > * {
		width: 50%;
		padding-right: 20px;
	}

	.two-col-grid > *:nth-child(2) {
		border-top: none;
	}

	.two-col-grid > *:nth-child(even) {
		padding-right: 0;
		padding-left: 20px;
		border-left: 1px solid #f2f2f2;
	}

	.two-col-grid > *:first-child,
  .two-col-grid > *:first-child + * {
		padding-top: 10px;
	}

	.two-col-grid > *:last-child, .two-col-grid > *:nth-last-child(2) {
		padding-bottom: 10px;
	}
}

@media (min-width: 1200px) {
	.two-col-grid > * {
		padding-right: 40px;
	}

	.two-col-grid > *:nth-child(even) {
		padding-left: 40px;
	}
}

.inline-content {
	margin-right: -12px;
	margin-left: -12px;
}

.inline-content > *,
.inline-content > *:last-child {
	display: inline-block;
	vertical-align: middle;
	margin-right: 12px;
	margin-left: 12px;
	margin-bottom: 0;
}

.inline-content > *.media,
.inline-content > *:last-child.media {
	margin-top: 10px;
	margin-bottom: 10px;
}

.inline-content.content-vspacing > * {
	margin-bottom: 10px;
	margin-top: 10px;
}

@media (min-width: 1200px) {
	.inline-content {
		margin-right: -14px;
		margin-left: -14px;
	}

	.inline-content > * {
		margin-left: 14px;
		margin-right: 14px;
	}
}

.item-meta .inline-content > * {
	margin-right: 10px;
	margin-left: 10px;
}

.display-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.vertical-center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.content-3lines-ellipsis {
	-webkit-box-orient: vertical;
	display: block;
	display: -webkit-box;
	font-size: 16px;
	height: 90px;
	-webkit-line-clamp: 3;
	line-height: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* media items - common side and vertical items */
.with_border .item-media,
.with_border .entry-thumbnail {
	margin: -1px;
	z-index: 2;
}

.with_border .item-media + header,
.with_border .entry-thumbnail + header {
	margin: 0 -1px;
}

.with_border.full-padding .item-media {
	margin: 0;
}

/* side and vertical items paddings */
.item-content.with_overlapped_button {
	position: relative;
	z-index: 3;
}

.item-content.with_overlapped_button .round_button {
	position: absolute;
	top: -30px;
	left: 50%;
	margin-left: -30px;
}

.side-item .row {
	margin: 0;
}

.side-item .row [class*="col-"] {
	padding: 0;
	margin: 0;
}

.side-item.side-sm .item-content {
	padding-top: 30px;
}

@media (min-width: 768px) {
	.side-item.side-sm .row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 992px) {
	.side-item.side-md .row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.side-item.side-md .row .top_rounded {
		border-radius: 3px 0 0 3px;
	}
}

/*-xs- side-item paddings and margins*/
@media (max-width: 767px) {
	.no-content-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content,
  .full-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-xs-12 .item-content,
  .full-padding .col-xs-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-sm- side-item paddings and margins*/
@media (min-width: 768px) {
	.no-content-padding [class*='col-sm-'] .item-content,
  .full-padding [class*='col-sm-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content,
  .full-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-sm-12 .item-content,
  .full-padding .col-sm-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-md- side-item paddings and margins*/
@media (min-width: 992px) {
	.no-content-padding [class*='col-md-'] .item-content,
  .full-padding [class*='col-md-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-md-'][class*='col-md-pull'] .item-content,
  .full-padding [class*='col-md-'][class*='col-md-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-md-12 .item-content,
  .full-padding .col-md-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-lg- side-item paddings and margins*/
@media (min-width: 1200px) {
	.no-content-padding [class*='col-lg-'] .item-content,
  .full-padding [class*='col-lg-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content,
  .full-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-lg-12 .item-content,
  .full-padding .col-lg-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

.vertical-item.post .item-content {
	padding-top: 0;
}

.vertical-item.content-padding .item-content {
	padding: 30px 20px;
}

@media (min-width: 400px) {
	.vertical-item.content-padding .item-content {
		padding: 35px;
	}
}

@media (min-width: 1200px) {
	.vertical-item.big-padding .item-content {
		padding: 55px;
	}
}

@media (min-width: 1200px) {
	.vertical-item.big-vertical-padding .item-content {
		padding-top: 55px;
		padding-bottom: 55px;
	}
}

.vertical-item.content-absolute {
	position: relative;
}

.vertical-item.content-absolute .item-content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
	z-index: 2;
}

@media (min-width: 400px) {
	.vertical-item.content-absolute .item-content {
		padding: 30px;
	}
}

@media (min-width: 1200px) {
	.vertical-item.content-absolute .item-content.big-padding {
		padding: 40px;
	}
}

.vertical-item.content-absolute .item-content.ds {
	background-color: transparent;
}

.vertical-item.content-absolute.vertical-center .item-content {
	top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.vertical-item.content-absolute.hover-content .item-content {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	transition: -webkit-transform 0.3s ease 0s;
	transition: transform 0.3s ease 0s;
	transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.vertical-item.content-absolute.hover-content:hover .item-content {
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
}

.vertical-item + .item-title {
	padding: 10px 30px;
}

.vertical-item + .item-title h2, .vertical-item + .item-title h3, .vertical-item + .item-title h4 {
	margin: 0;
}

.side-item.content-padding .item-content {
	padding: 25px 20px;
}

@media (min-width: 400px) {
	.side-item.content-padding .item-content {
		padding: 35px;
	}
}

@media (min-width: 1200px) {
	.side-item.big-padding .item-content {
		padding: 45px;
	}
}

/* item meta */
/* item media links */
.item-media {
	position: relative;
	overflow: hidden;
}

.item-media img {
	width: auto;
}

.item-media.with_icon {
	overflow: visible;
}

.item-media .post_icon {
	position: absolute;
	bottom: -30px;
	left: 50%;
	margin-left: -30px;
	width: 60px;
	border-radius: 50%;
	background-color: #0074ba;
}

.item-media .post_icon i {
	color: #ffffff;
	line-height: 60px;
}

.media-links {
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.media-links div {
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -25px;
	z-index: 4;
}

.media-links:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(50, 50, 50, 0.5);
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.2s linear;
}

.media-links.color-overlay:before {
	opacity: 1;
}

[class*="-item"]:hover .media-links.color-overlay:before {
	opacity: 0;
}

[class*="-item"]:hover .media-links:before {
	opacity: 1;
}

.media-links.no-overlay:before {
	display: none;
}

.media-links.inverse:before {
	opacity: 1;
}

[class*="-item"]:hover .media-links.inverse:before {
	opacity: 0;
}

.media-links a.abs-link {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}

[class*="-item"]:hover .media-links a.abs-link {
	opacity: 1;
}

[class*="-item"] .links-wrap a,
[class*="-item"] .links-wrap > span {
	position: relative;
	width: 60px;
	height: 60px;
	line-height: 60px;
	top: -100px;
	border-radius: 30px;
	display: inline-block;
	z-index: 5;
	font-size: 16px;
	text-align: center;
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*="-item"] .links-wrap a.p-view {
	color: #ffffff;
}

[class*="-item"] .links-wrap a.p-view:hover {
	color: #0074ba;
}

[class*="-item"] .links-wrap a.p-link {
	color: #323232;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

[class*="-item"] .links-wrap a.p-link:hover {
	color: #0074ba;
}

[class*="-item"] .links-wrap a:before {
	font-family: 'FontAwesome';
	content: "\f002";
}

[class*="-item"] .links-wrap a.p-link:before {
	content: "\f067";
	font-size: 14px;
}

[class*="-item"] .links-wrap > span {
	font-size: 24px;
}

/* item icons */
.item-icons {
	display: table;
	width: 100%;
	border-top: 1px solid rgba(128, 128, 128, 0.1);
}

.item-icons i {
	font-size: 14px;
}

.item-icons > div {
	padding: 24px 50px 24px;
	display: table-cell;
	width: 400px;
	white-space: nowrap;
}

@media (max-width: 1199px) {
	.item-icons > div {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.item-icons > div.text-right {
	padding-left: 0;
}

.item-icons [data-id] {
	display: none;
}

.media-links .bottom-links {
	position: absolute;
	bottom: 15px;
	left: 0;
	right: 0;
	top: auto;
}

.media-links .bottom-links a {
	padding: 1px 11px;
	background-color: #323232;
	display: inline-block;
	margin: 0 2px;
}

.media-links .bottom-links a:hover {
	background-color: #bee27b;
}

@media (min-width: 1200px) {
	.media-links .bottom-links a {
		opacity: 0;
	}
}

[class*="-item"]:hover .links-wrap a,
[class*="-item"]:hover .links-wrap > span,
.opened-media .links-wrap a,
.opened-media .links-wrap > span {
	top: 0px;
	filter: alpha(opacity=100);
	opacity: 1;
}

[class*="-item"]:hover .media-links div,
.opened-media .media-links div {
	filter: alpha(opacity=100);
	opacity: 1;
}

@media (min-width: 1200px) {
	[class*="-item"]:hover .media-links .bottom-links a,
  .opened-media .media-links .bottom-links a {
		opacity: 1;
	}
}

/*slide media*/
.slide-media .item-media-wrap {
	position: relative;
}

.slide-media .item-media-wrap > img {
	opacity: 0;
	max-width: 65%;
	min-width: 250px;
}

.slide-media .item-media {
	position: absolute;
	width: 65%;
	min-width: 250px;
	left: 50%;
	top: 0;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	transition: all 0.3s linear 0s;
}

.slide-media .item-media.back-media {
	position: absolute;
	left: auto;
	right: 50%;
	top: 50%;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
}

.slide-media:hover .item-media,
.slide-media.opened-media .item-media {
	left: 0;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.slide-media:hover .item-media.back-media,
.slide-media.opened-media .item-media.back-media {
	right: 0;
	left: auto;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

/*
** Header
*/
.header_white {
	color: #656565;
	background-color: #ffffff;
}

.header_white.affix {
	box-shadow: 0px 1px 15px 0 rgba(0, 0, 0, 0.07);
}

.header_white a.logo {
	color: #323232;
}

.ls + .header_white {
	border-top: 1px solid #f2f2f2;
}

.header_darkgrey {
	background-color: #323232;
}

.header_darkgrey.affix {
	box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.3);
}

.header_darkgrey a[class*='socicon-'] {
	color: #ffffff;
}

.header_darkgrey .logo-meta,
.header_darkgrey .grey,
.header_darkgrey .black {
	color: #ffffff;
}

.header_darkgrey.with_top_border:before {
	background-color: #3b3b3b;
}

.ds + .header_darkgrey:not([class*="header_transparent"]) [class*="container"] {
	position: relative;
}

.ds + .header_darkgrey:not([class*="header_transparent"]) [class*="container"]:before {
	content: "";
	position: relative;
	margin-bottom: -1px;
	display: block;
	top: 0;
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.23);
}

.header_color {
	background-color: #0074ba;
}

.header_transparent_wrap {
	position: relative;
	height: 0 !important;
}

.header_transparent_wrap .header_transparent:not(.affix) {
	position: absolute;
	background-color: transparent;
}

@media (min-width: 768px) {
	.transparent_wrappper {
		position: relative;
		height: 0 !important;
	}

	.transparent_wrappper > *:not(.page_header_wrapper) {
		position: relative;
		z-index: 3;
		background-color: transparent;
	}

	.transparent_wrappper .page_header:not(.affix) {
		background-color: transparent;
	}
}

/* logo */
.logo {
	display: inline-block;
	word-break: break-word;
	font-size: 24px;
	position: relative;
	z-index: 2;
	padding: 5px 0;
}

.logo img {
	max-width: 220px;
}

.ls .logo {
	color: #323232;
}

.ds .logo,
.ds .logo:hover,
.cs .logo,
.cs .logo:hover {
	color: #ffffff;
}

.header_color .logo,
.header_darkgrey .logo {
	color: #ffffff;
}

.floating_logo .logo {
	float: left;
	margin-right: 30px;
}

.logo.vertical_logo {
	padding: 0;
}

.logo.vertical_logo img {
	display: block;
	margin: auto;
}

.logo.vertical_logo .logo_text {
	margin: 10px 0 0;
}

@media (min-width: 992px) {
	.header_logo_center .logo {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

@media (min-width: 992px) {
	.header_logo_center .logo_wrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

.logo-meta {
	line-height: 1;
	display: inline-block;
	margin: 11px 20px;
	vertical-align: middle;
	text-align: left;
}

.page_header_side .logo-meta {
	display: block !important;
}

.page_header {
	transition: background 0.2s ease-in-out 0s, margin 0.6s ease-in-out 0s;
	top: 0;
	left: 0;
	right: 0;
	position: relative;
	z-index: 1000;
	margin: 0;
	padding: 0;
	height: auto;
}

.page_header.affix {
	position: fixed;
	z-index: 1001;
}

.page_header.mobile-active {
	z-index: 1002;
}

.page_header.affix-bottom {
	top: 0 !important;
	position: fixed;
}

.page_header .search_modal_button {
	background-position: 50% 0;
	border-color: transparent;
}

.page_header [class*="display_table_cell"] {
	overflow: visible;
}

.page_header [class*="col-"] {
	min-height: 0;
}

#search_modal {
	color: #ffffff;
}

#search_modal .search-form {
	margin: 0 0 0 60px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#search_modal a {
	color: #ffffff;
	text-transform: uppercase;
}

#search_modal h2{
	margin-bottom: 5px;
	text-transform: none;
	font-size: 34px;
}

#search_modal h2 a{
	color: #fff!important;
	text-transform: none;
	opacity: 1;
}

#search_modal .result{
	border: 1px solid rgba(255, 255, 255, 0.4);
}

#search_modal h3{
	margin: 5px 0;
	font-size: 26px;
}

#search_modal a:hover {
	color: #0074ba;
	opacity: 1;
}

#search_modal .searchform-respond {
	width: 600px;
	margin: 20px auto;
}

#search_modal .form-control {
	border: none;
	background-color: transparent;
	height: 50px;
	color: #ffffff;
	width: 100%;
}

#search_modal .form-control:-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

#search_modal .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

#search_modal .theme_button {
	color: #ffffff;
	width: 50px;
	padding: 0;
}

#search_modal .theme_button:hover, #search_modal .theme_button:focus, #search_modal .theme_button:active {
	color: #0074ba;
}

#search_modal .close {
	padding: 14px 14px 13px;
	position: absolute;
	min-width: 0;
	color: #ffffff;
	text-shadow: none;
	opacity: 1;
}

.page_header .header-button {
	line-height: 60px;
}

.header_right_buttons .inline-list.small-padding > li {
	padding-top: 0;
	padding-bottom: 0;
}

/* header floats */
.page_header:before,
.page_header:after {
	content: '';
	display: block;
	clear: both;
}

.page_header {
	height: auto;
}

.header_left_logo {
	white-space: nowrap;
	padding: 0 15px;
	overflow: visible;
}

.header_mainmenu {
	overflow: visible;
	width: 10000px;
	padding: 0 15px;
}

.header_right_buttons {
	white-space: nowrap;
	padding: 0 15px;
	text-align: right;
	overflow: visible;
}

.header_right_buttons.ls {
	background-color: transparent !important;
}

/*
** Side Header
*/
/* side header layout */
body {
	position: relative;
	left: 0;
}

body:after {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	visibility: hidden;
	opacity: 0;
}

body,
body:after,
.page_header_side {
	transition: all 0.6s ease-in-out;
}

body {
	transition-property: left, right;
}

body.active-side-header {
	left: 270px;
}

body.active-side-header .page_header_side {
	left: 0;
}

body.active-side-header:after {
	visibility: visible;
	opacity: 1;
}

.page_header_side {
	z-index: 1002;
	position: fixed;
	width: 270px;
	left: -270px;
	top: 0;
	bottom: 0;
}

.page_header_side.active-slide-side-header {
	box-shadow: 0px 0px 4px 3px rgba(50, 50, 50, 0.05);
}

.page_header_side ~ * .toggle_menu {
	display: none;
}

.page_header_side .widget {
	margin-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
}

.page_header_side .logo-meta {
	margin: 10px 30px;
}

.page_header_side .social-icons {
	display: block;
	margin: 20px 30px 0;
}

.side_header_inner {
	padding: 10px 0 60px;
}

.toggle_menu_side {
	position: absolute;
	top: 5px;
	right: -65px;
	background-color: #ffffff;
	width: 60px;
	height: 60px;
	cursor: pointer;
	box-shadow: 0 0 4px 2px rgba(50, 50, 50, 0.05);
	z-index: 2;
}

.toggle_menu_side:before,
.toggle_menu_side span,
.toggle_menu_side span:before,
.toggle_menu_side span:after {
	content: '';
	display: block;
	width: 24px;
	position: absolute;
	background-color: #323232;
	left: 0;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	transition: .25s ease-in-out;
	height: 2px;
}

.toggle_menu_side:before,
.toggle_menu_side span {
	left: 18px;
	top: 50%;
}

.toggle_menu_side span:before {
	opacity: 1;
	top: -6px;
}

.toggle_menu_side span:after {
	opacity: 1;
	top: 6px;
}

.active-slide-side-header .toggle_menu_side:before,
.active-side-header .toggle_menu_side:before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.active-slide-side-header .toggle_menu_side span,
.active-side-header .toggle_menu_side span {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.active-slide-side-header .toggle_menu_side span:before, .active-slide-side-header .toggle_menu_side span:after,
.active-side-header .toggle_menu_side span:before,
.active-side-header .toggle_menu_side span:after {
	opacity: 0;
}

.ds .toggle_menu_side {
	background-color: #3d3d47;
}

.ds .toggle_menu_side span,
.ds .toggle_menu_side span:before,
.ds .toggle_menu_side span:after, .ds .toggle_menu_side:before {
	background-color: #ffffff;
}

.bs .toggle_menu_side {
	background-color: #00202b;
}

@media (max-width: 350px) {
	.toggle_menu_side {
		top: 0;
		right: -50px;
	}
}

.page_header_side.page_header_side_sticked .toggle_menu_side {
	transition: 0.6s ease-in-out;
	right: -65px;
	top: 5px;
}

.admin .page_header_side.page_header_side_sticked .toggle_menu_side {
	right: -60px;
	top: 0;
}

.admin .page_header_side.page_header_side_sticked.active-slide-side-header .toggle_menu_side {
	top: 0;
	right: 0;
	box-shadow: none;
}

.page_header_side.active-slide-side-header {
	left: 0;
}

.container {
	max-width: 100%;
}

@media (max-width: 767px) {
	.container {
		max-width: 500px;
	}
}

@media (min-width: 600px) {
	.page_header_side.page_header_side_sticked.active-slide-side-header {
		left: 0;
	}

	.page_header_side_sticked ~ * {
		transition: margin 0.6s ease-in-out;
	}

	.page_header_side_sticked ~ div .page_header {
		padding-left: 35px;
	}

	.page_header_side_sticked.active-slide-side-header ~ div .page_header {
		padding-left: 0;
		margin-left: 270px;
	}

	.page_header_side_sticked.active-slide-side-header ~ * {
		margin-left: 270px;
	}

	.boxed .page_header_side_sticked.active-slide-side-header ~ *,
  .boxed .page_header_side_sticked.active-slide-side-header ~ div .page_header {
		margin-left: 0;
	}

	.page_header_side_sticked ~ .page_header_wrapper {
		margin-left: 0;
	}
}

.page_header_side.header_side_right {
	left: auto;
	right: -270px;
}

.page_header_side.header_side_right.active-slide-side-header {
	right: 0;
}

.page_header_side.header_side_right .toggle_menu_side {
	right: auto;
	left: -65px;
}

@media (max-width: 350px) {
	.page_header_side.header_side_right .toggle_menu_side {
		left: -50px;
	}
}

body.active-side-header.slide-right {
	left: -270px;
}

body.active-side-header.slide-right .page_header_side {
	left: auto;
	right: 0;
}

/* side clickable menu styles */
.menu-side-click {
	padding: 0;
	margin: 19px 0;
	line-height: 1;
}

.menu-side-click i {
	width: 1em;
	text-align: center;
	margin-right: 10px;
}

.menu-side-click .activate_submenu {
	cursor: pointer;
	position: absolute;
	right: 30px;
	top: 9px;
	font-size: 11px;
	color: #323232;
	text-align: center;
	width: 10px;
	opacity: 0.9;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	transition: .25s ease-in-out;
}

.menu-side-click .activate_submenu:before {
	font-family: 'FontAwesome';
	content: "\f0dd";
}

.ds .menu-side-click .activate_submenu {
	color: #ffffff;
}

.menu-side-click .active-submenu > .activate_submenu {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
}

.menu-side-click li {
	position: relative;
	list-style: none;
	margin-top: 10px;
	margin-bottom: 10px;
}

.menu-side-click > li {
	padding: 0 40px 0 27px;
	font-weight: 400;
	background-color: transparent;
	border-left: 3px solid transparent;
	transition: 0.3s ease-in-out;
}

.menu-side-click > li a {
	display: block;
	padding: 7px 0;
}

.menu-side-click > li.active-submenu {
	padding-top: 19px;
	padding-bottom: 10px;
	border-color: #0074ba;
}

.bs .menu-side-click > li.active-submenu {
	background-color: #00202b;
}

.ls .menu-side-click > li.active-submenu {
	background-color: #fafafa;
}

.menu-side-click > li.active-submenu > .activate_submenu {
	top: 22px;
}

.menu-side-click > li li {
	padding-right: 40px;
	font-weight: 500;
}

.menu-side-click li.active > a {
	color: #0074ba;
}

.menu-side-click ul {
	opacity: 0;
	height: 0;
	padding-left: 26px;
}

.menu-side-click ul ul {
	padding-left: 10px;
	font-size: 95%;
}

.menu-side-click .active-submenu > div.mega-menu {
	height: auto;
}

.menu-side-click .active-submenu > ul,
.menu-side-click .active-submenu > div.mega-menu > ul {
	opacity: 1;
	height: auto;
}

.menu-side-click ul, .menu-side-click div.mega-menu {
	margin-right: -40px;
	margin-top: 0;
	margin-bottom: 0;
	height: 0;
	overflow: hidden;
	transition: opacity 0.6s, margin-top 0.4s, margin-bottom 0.4s, height 0.3s ease-in-out;
}

.menu-side-click ul li.sfHover > .mega-menu ul {
	display: block;
	height: 100%;
}

.menu-side-click div.mega-menu > ul {
	margin-right: 0;
	height: auto;
}

.side_header_social {
	margin: 30px;
	text-align: center;
}

/* sidebar scroll plugin */
.scroll-wrapper {
	overflow: hidden;
	padding: 0;
	position: relative;
	height: 100%;
}

.scroll-wrapper > .scroll-content {
	border: none;
	box-sizing: content-box;
	height: auto;
	left: 0;
	margin: 0;
	max-height: none;
	max-width: none;
	overflow: scroll;
	padding: 0;
	position: relative;
	top: 0;
	width: auto;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
	height: 0;
	width: 0;
}

.scroll-wrapper.scroll--rtl {
	direction: rtl;
}

.scroll-element {
	box-sizing: content-box;
	display: none;
}

.scroll-element div {
	box-sizing: content-box;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
	cursor: default;
}

.scroll-element.scroll-x.scroll-scrollx_visible, .scroll-element.scroll-y.scroll-scrolly_visible {
	display: block;
}

.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div {
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}

.scrollbar-macosx > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}

.scrollbar-macosx > .scroll-element .scroll-element_track {
	display: none;
}

.scrollbar-macosx > .scroll-element .scroll-bar {
	background-color: rgba(127, 127, 127, 0.4);
	display: block;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	border-radius: 1px;
	transition: opacity 0.2s linear;
}

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	filter: alpha(opacity=70);
	opacity: 0.7;
}

.scrollbar-macosx > .scroll-element.scroll-x {
	bottom: 0px;
	height: 0px;
	left: 0;
	min-width: 100%;
	overflow: visible;
	width: 100%;
}

.scrollbar-macosx > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	right: 0px;
	top: 0;
	width: 0px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
	height: 7px;
	min-width: 10px;
	top: -9px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
	left: -9px;
	min-height: 10px;
	width: 7px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
	left: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
	left: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
	top: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
	left: -11px;
}

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
	top: -11px;
}

/*
** Light Section - .ls
*/
.ls {
	background-color: #ffffff;
	color: #656565;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
	color: #323232;
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a {
	color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover {
	color: #0074ba;
}

.ls h1.hover-color2 a:hover,
.ls h2.hover-color2 a:hover,
.ls h3.hover-color2 a:hover,
.ls h4.hover-color2 a:hover,
.ls h5.hover-color2 a:hover,
.ls h6.hover-color2 a:hover {
	color: #bee27b;
}

.ls h1.hover-color3 a:hover,
.ls h2.hover-color3 a:hover,
.ls h3.hover-color3 a:hover,
.ls h4.hover-color3 a:hover,
.ls h5.hover-color3 a:hover,
.ls h6.hover-color3 a:hover {
	color: #67dfc1;
}

.ls .main_bg_color,
.ls .main_bg_color2,
.ls .main_bg_color3,
.ls .dark_bg_color,
.ls .darkblue_bg_color,
.ls .darkgrey_bg_color,
.ls .darkgrey_bg.bg_teaser,
.ls .success_bg_color,
.ls .info_bg_color,
.ls .warning_bg_color,
.ls .danger_bg_color,
.ls .danger_bg_color,
.ls .transp_gradient_bg,
.ls .gradient_bg {
	color: #ffffff;
}

.ls .main_bg_color h1,
.ls .main_bg_color h2,
.ls .main_bg_color h3,
.ls .main_bg_color h4,
.ls .main_bg_color h5,
.ls .main_bg_color h6,
.ls .main_bg_color2 h1,
.ls .main_bg_color2 h2,
.ls .main_bg_color2 h3,
.ls .main_bg_color2 h4,
.ls .main_bg_color2 h5,
.ls .main_bg_color2 h6,
.ls .main_bg_color3 h1,
.ls .main_bg_color3 h2,
.ls .main_bg_color3 h3,
.ls .main_bg_color3 h4,
.ls .main_bg_color3 h5,
.ls .main_bg_color3 h6,
.ls .dark_bg_color h1,
.ls .dark_bg_color h2,
.ls .dark_bg_color h3,
.ls .dark_bg_color h4,
.ls .dark_bg_color h5,
.ls .dark_bg_color h6,
.ls .darkblue_bg_color h1,
.ls .darkblue_bg_color h2,
.ls .darkblue_bg_color h3,
.ls .darkblue_bg_color h4,
.ls .darkblue_bg_color h5,
.ls .darkblue_bg_color h6,
.ls .darkgrey_bg_color h1,
.ls .darkgrey_bg_color h2,
.ls .darkgrey_bg_color h3,
.ls .darkgrey_bg_color h4,
.ls .darkgrey_bg_color h5,
.ls .darkgrey_bg_color h6,
.ls .darkgrey_bg.bg_teaser h1,
.ls .darkgrey_bg.bg_teaser h2,
.ls .darkgrey_bg.bg_teaser h3,
.ls .darkgrey_bg.bg_teaser h4,
.ls .darkgrey_bg.bg_teaser h5,
.ls .darkgrey_bg.bg_teaser h6,
.ls .success_bg_color h1,
.ls .success_bg_color h2,
.ls .success_bg_color h3,
.ls .success_bg_color h4,
.ls .success_bg_color h5,
.ls .success_bg_color h6,
.ls .info_bg_color h1,
.ls .info_bg_color h2,
.ls .info_bg_color h3,
.ls .info_bg_color h4,
.ls .info_bg_color h5,
.ls .info_bg_color h6,
.ls .warning_bg_color h1,
.ls .warning_bg_color h2,
.ls .warning_bg_color h3,
.ls .warning_bg_color h4,
.ls .warning_bg_color h5,
.ls .warning_bg_color h6,
.ls .danger_bg_color h1,
.ls .danger_bg_color h2,
.ls .danger_bg_color h3,
.ls .danger_bg_color h4,
.ls .danger_bg_color h5,
.ls .danger_bg_color h6,
.ls .danger_bg_color h1,
.ls .danger_bg_color h2,
.ls .danger_bg_color h3,
.ls .danger_bg_color h4,
.ls .danger_bg_color h5,
.ls .danger_bg_color h6,
.ls .transp_gradient_bg h1,
.ls .transp_gradient_bg h2,
.ls .transp_gradient_bg h3,
.ls .transp_gradient_bg h4,
.ls .transp_gradient_bg h5,
.ls .transp_gradient_bg h6,
.ls .gradient_bg h1,
.ls .gradient_bg h2,
.ls .gradient_bg h3,
.ls .gradient_bg h4,
.ls .gradient_bg h5,
.ls .gradient_bg h6 {
	color: #ffffff;
}

.ls .main_bg_color h1 a:hover, .ls .main_bg_color h1 a:active, .ls .main_bg_color h1 a:focus,
.ls .main_bg_color h2 a:hover,
.ls .main_bg_color h2 a:active,
.ls .main_bg_color h2 a:focus,
.ls .main_bg_color h3 a:hover,
.ls .main_bg_color h3 a:active,
.ls .main_bg_color h3 a:focus,
.ls .main_bg_color h4 a:hover,
.ls .main_bg_color h4 a:active,
.ls .main_bg_color h4 a:focus,
.ls .main_bg_color h5 a:hover,
.ls .main_bg_color h5 a:active,
.ls .main_bg_color h5 a:focus,
.ls .main_bg_color h6 a:hover,
.ls .main_bg_color h6 a:active,
.ls .main_bg_color h6 a:focus,
.ls .main_bg_color2 h1 a:hover,
.ls .main_bg_color2 h1 a:active,
.ls .main_bg_color2 h1 a:focus,
.ls .main_bg_color2 h2 a:hover,
.ls .main_bg_color2 h2 a:active,
.ls .main_bg_color2 h2 a:focus,
.ls .main_bg_color2 h3 a:hover,
.ls .main_bg_color2 h3 a:active,
.ls .main_bg_color2 h3 a:focus,
.ls .main_bg_color2 h4 a:hover,
.ls .main_bg_color2 h4 a:active,
.ls .main_bg_color2 h4 a:focus,
.ls .main_bg_color2 h5 a:hover,
.ls .main_bg_color2 h5 a:active,
.ls .main_bg_color2 h5 a:focus,
.ls .main_bg_color2 h6 a:hover,
.ls .main_bg_color2 h6 a:active,
.ls .main_bg_color2 h6 a:focus,
.ls .main_bg_color3 h1 a:hover,
.ls .main_bg_color3 h1 a:active,
.ls .main_bg_color3 h1 a:focus,
.ls .main_bg_color3 h2 a:hover,
.ls .main_bg_color3 h2 a:active,
.ls .main_bg_color3 h2 a:focus,
.ls .main_bg_color3 h3 a:hover,
.ls .main_bg_color3 h3 a:active,
.ls .main_bg_color3 h3 a:focus,
.ls .main_bg_color3 h4 a:hover,
.ls .main_bg_color3 h4 a:active,
.ls .main_bg_color3 h4 a:focus,
.ls .main_bg_color3 h5 a:hover,
.ls .main_bg_color3 h5 a:active,
.ls .main_bg_color3 h5 a:focus,
.ls .main_bg_color3 h6 a:hover,
.ls .main_bg_color3 h6 a:active,
.ls .main_bg_color3 h6 a:focus,
.ls .dark_bg_color h1 a:hover,
.ls .dark_bg_color h1 a:active,
.ls .dark_bg_color h1 a:focus,
.ls .dark_bg_color h2 a:hover,
.ls .dark_bg_color h2 a:active,
.ls .dark_bg_color h2 a:focus,
.ls .dark_bg_color h3 a:hover,
.ls .dark_bg_color h3 a:active,
.ls .dark_bg_color h3 a:focus,
.ls .dark_bg_color h4 a:hover,
.ls .dark_bg_color h4 a:active,
.ls .dark_bg_color h4 a:focus,
.ls .dark_bg_color h5 a:hover,
.ls .dark_bg_color h5 a:active,
.ls .dark_bg_color h5 a:focus,
.ls .dark_bg_color h6 a:hover,
.ls .dark_bg_color h6 a:active,
.ls .dark_bg_color h6 a:focus,
.ls .darkblue_bg_color h1 a:hover,
.ls .darkblue_bg_color h1 a:active,
.ls .darkblue_bg_color h1 a:focus,
.ls .darkblue_bg_color h2 a:hover,
.ls .darkblue_bg_color h2 a:active,
.ls .darkblue_bg_color h2 a:focus,
.ls .darkblue_bg_color h3 a:hover,
.ls .darkblue_bg_color h3 a:active,
.ls .darkblue_bg_color h3 a:focus,
.ls .darkblue_bg_color h4 a:hover,
.ls .darkblue_bg_color h4 a:active,
.ls .darkblue_bg_color h4 a:focus,
.ls .darkblue_bg_color h5 a:hover,
.ls .darkblue_bg_color h5 a:active,
.ls .darkblue_bg_color h5 a:focus,
.ls .darkblue_bg_color h6 a:hover,
.ls .darkblue_bg_color h6 a:active,
.ls .darkblue_bg_color h6 a:focus,
.ls .darkgrey_bg_color h1 a:hover,
.ls .darkgrey_bg_color h1 a:active,
.ls .darkgrey_bg_color h1 a:focus,
.ls .darkgrey_bg_color h2 a:hover,
.ls .darkgrey_bg_color h2 a:active,
.ls .darkgrey_bg_color h2 a:focus,
.ls .darkgrey_bg_color h3 a:hover,
.ls .darkgrey_bg_color h3 a:active,
.ls .darkgrey_bg_color h3 a:focus,
.ls .darkgrey_bg_color h4 a:hover,
.ls .darkgrey_bg_color h4 a:active,
.ls .darkgrey_bg_color h4 a:focus,
.ls .darkgrey_bg_color h5 a:hover,
.ls .darkgrey_bg_color h5 a:active,
.ls .darkgrey_bg_color h5 a:focus,
.ls .darkgrey_bg_color h6 a:hover,
.ls .darkgrey_bg_color h6 a:active,
.ls .darkgrey_bg_color h6 a:focus,
.ls .darkgrey_bg.bg_teaser h1 a:hover,
.ls .darkgrey_bg.bg_teaser h1 a:active,
.ls .darkgrey_bg.bg_teaser h1 a:focus,
.ls .darkgrey_bg.bg_teaser h2 a:hover,
.ls .darkgrey_bg.bg_teaser h2 a:active,
.ls .darkgrey_bg.bg_teaser h2 a:focus,
.ls .darkgrey_bg.bg_teaser h3 a:hover,
.ls .darkgrey_bg.bg_teaser h3 a:active,
.ls .darkgrey_bg.bg_teaser h3 a:focus,
.ls .darkgrey_bg.bg_teaser h4 a:hover,
.ls .darkgrey_bg.bg_teaser h4 a:active,
.ls .darkgrey_bg.bg_teaser h4 a:focus,
.ls .darkgrey_bg.bg_teaser h5 a:hover,
.ls .darkgrey_bg.bg_teaser h5 a:active,
.ls .darkgrey_bg.bg_teaser h5 a:focus,
.ls .darkgrey_bg.bg_teaser h6 a:hover,
.ls .darkgrey_bg.bg_teaser h6 a:active,
.ls .darkgrey_bg.bg_teaser h6 a:focus,
.ls .success_bg_color h1 a:hover,
.ls .success_bg_color h1 a:active,
.ls .success_bg_color h1 a:focus,
.ls .success_bg_color h2 a:hover,
.ls .success_bg_color h2 a:active,
.ls .success_bg_color h2 a:focus,
.ls .success_bg_color h3 a:hover,
.ls .success_bg_color h3 a:active,
.ls .success_bg_color h3 a:focus,
.ls .success_bg_color h4 a:hover,
.ls .success_bg_color h4 a:active,
.ls .success_bg_color h4 a:focus,
.ls .success_bg_color h5 a:hover,
.ls .success_bg_color h5 a:active,
.ls .success_bg_color h5 a:focus,
.ls .success_bg_color h6 a:hover,
.ls .success_bg_color h6 a:active,
.ls .success_bg_color h6 a:focus,
.ls .info_bg_color h1 a:hover,
.ls .info_bg_color h1 a:active,
.ls .info_bg_color h1 a:focus,
.ls .info_bg_color h2 a:hover,
.ls .info_bg_color h2 a:active,
.ls .info_bg_color h2 a:focus,
.ls .info_bg_color h3 a:hover,
.ls .info_bg_color h3 a:active,
.ls .info_bg_color h3 a:focus,
.ls .info_bg_color h4 a:hover,
.ls .info_bg_color h4 a:active,
.ls .info_bg_color h4 a:focus,
.ls .info_bg_color h5 a:hover,
.ls .info_bg_color h5 a:active,
.ls .info_bg_color h5 a:focus,
.ls .info_bg_color h6 a:hover,
.ls .info_bg_color h6 a:active,
.ls .info_bg_color h6 a:focus,
.ls .warning_bg_color h1 a:hover,
.ls .warning_bg_color h1 a:active,
.ls .warning_bg_color h1 a:focus,
.ls .warning_bg_color h2 a:hover,
.ls .warning_bg_color h2 a:active,
.ls .warning_bg_color h2 a:focus,
.ls .warning_bg_color h3 a:hover,
.ls .warning_bg_color h3 a:active,
.ls .warning_bg_color h3 a:focus,
.ls .warning_bg_color h4 a:hover,
.ls .warning_bg_color h4 a:active,
.ls .warning_bg_color h4 a:focus,
.ls .warning_bg_color h5 a:hover,
.ls .warning_bg_color h5 a:active,
.ls .warning_bg_color h5 a:focus,
.ls .warning_bg_color h6 a:hover,
.ls .warning_bg_color h6 a:active,
.ls .warning_bg_color h6 a:focus,
.ls .danger_bg_color h1 a:hover,
.ls .danger_bg_color h1 a:active,
.ls .danger_bg_color h1 a:focus,
.ls .danger_bg_color h2 a:hover,
.ls .danger_bg_color h2 a:active,
.ls .danger_bg_color h2 a:focus,
.ls .danger_bg_color h3 a:hover,
.ls .danger_bg_color h3 a:active,
.ls .danger_bg_color h3 a:focus,
.ls .danger_bg_color h4 a:hover,
.ls .danger_bg_color h4 a:active,
.ls .danger_bg_color h4 a:focus,
.ls .danger_bg_color h5 a:hover,
.ls .danger_bg_color h5 a:active,
.ls .danger_bg_color h5 a:focus,
.ls .danger_bg_color h6 a:hover,
.ls .danger_bg_color h6 a:active,
.ls .danger_bg_color h6 a:focus,
.ls .danger_bg_color h1 a:hover,
.ls .danger_bg_color h1 a:active,
.ls .danger_bg_color h1 a:focus,
.ls .danger_bg_color h2 a:hover,
.ls .danger_bg_color h2 a:active,
.ls .danger_bg_color h2 a:focus,
.ls .danger_bg_color h3 a:hover,
.ls .danger_bg_color h3 a:active,
.ls .danger_bg_color h3 a:focus,
.ls .danger_bg_color h4 a:hover,
.ls .danger_bg_color h4 a:active,
.ls .danger_bg_color h4 a:focus,
.ls .danger_bg_color h5 a:hover,
.ls .danger_bg_color h5 a:active,
.ls .danger_bg_color h5 a:focus,
.ls .danger_bg_color h6 a:hover,
.ls .danger_bg_color h6 a:active,
.ls .danger_bg_color h6 a:focus,
.ls .transp_gradient_bg h1 a:hover,
.ls .transp_gradient_bg h1 a:active,
.ls .transp_gradient_bg h1 a:focus,
.ls .transp_gradient_bg h2 a:hover,
.ls .transp_gradient_bg h2 a:active,
.ls .transp_gradient_bg h2 a:focus,
.ls .transp_gradient_bg h3 a:hover,
.ls .transp_gradient_bg h3 a:active,
.ls .transp_gradient_bg h3 a:focus,
.ls .transp_gradient_bg h4 a:hover,
.ls .transp_gradient_bg h4 a:active,
.ls .transp_gradient_bg h4 a:focus,
.ls .transp_gradient_bg h5 a:hover,
.ls .transp_gradient_bg h5 a:active,
.ls .transp_gradient_bg h5 a:focus,
.ls .transp_gradient_bg h6 a:hover,
.ls .transp_gradient_bg h6 a:active,
.ls .transp_gradient_bg h6 a:focus,
.ls .gradient_bg h1 a:hover,
.ls .gradient_bg h1 a:active,
.ls .gradient_bg h1 a:focus,
.ls .gradient_bg h2 a:hover,
.ls .gradient_bg h2 a:active,
.ls .gradient_bg h2 a:focus,
.ls .gradient_bg h3 a:hover,
.ls .gradient_bg h3 a:active,
.ls .gradient_bg h3 a:focus,
.ls .gradient_bg h4 a:hover,
.ls .gradient_bg h4 a:active,
.ls .gradient_bg h4 a:focus,
.ls .gradient_bg h5 a:hover,
.ls .gradient_bg h5 a:active,
.ls .gradient_bg h5 a:focus,
.ls .gradient_bg h6 a:hover,
.ls .gradient_bg h6 a:active,
.ls .gradient_bg h6 a:focus {
	color: rgba(255, 255, 255, 0.5);
}

.ls [class*='color_bg'] .highlight {
	color: #ffffff;
}

.ls .hoverable-banner.gradient_bg_color:hover .highlight2 {
	color: #0074ba;
}

.ls .light_bg_color {
	color: #323232;
}

.ls a {
	color: #0074ba;
}

.ls a:hover {
	color: #323232;
}

.ls .grey {
	color: #323232;
}

.ls .highlight,
.ls .highlight1 {
	color: #0074ba;
	border-color: #0074ba;
}

.ls .highlight2 {
	color: #bee27b;
	border-color: #bee27b;
}

.ls .highlight3 {
	color: #67dfc1;
	border-color: #67dfc1;
}

.ls .highlight4 {
	color: #009d83;
	border-color: #009d83;
}

.ls .greylinks a {
	color: #656565;
}

.ls .greylinks a:hover, .ls .greylinks a:focus, .ls .greylinks a:active {
	color: #0074ba;
}

.ls .greylinks.color2 a:hover, .ls .greylinks.color2 a:focus, .ls .greylinks.color2 a:active {
	color: #bee27b;
}

.ls .darklinks a {
	color: #323232;
}

.ls .darklinks a:hover {
	color: #0074ba;
}

.ls .highlightlinks a,
.ls .highlightlink {
	color: #0074ba;
}

.ls .highlightlinks a:hover,
.ls .highlightlink:hover {
	color: #323232;
}

.ls .highlight2links a,
.ls .highlight2link {
	color: #bee27b;
}

.ls .highlight2links a:hover,
.ls .highlight2link:hover {
	color: #323232;
}

.ls .highlight3links a,
.ls .highlight3link {
	color: #67dfc1;
}

.ls .highlight3links a:hover,
.ls .highlight3link:hover {
	color: #323232;
}

.ls .highlight4links a,
.ls .highlight4link {
	color: #009d83;
}

.ls .highlight4links a:hover,
.ls .highlight4link:hover {
	color: #323232;
}

.ls .colorlinks a {
	color: #bee27b;
}

.ls .colorlinks a:hover {
	color: #0074ba;
}

.ls .breadcrumb > li + li:before {
	color: #656565;
}

.ls .breadcrumb > .active {
	color: #323232;
}

.ls .nav-unstyled .active a {
	color: #0074ba;
}

.ls .theme_buttons a,
.ls .theme_button {
	color: #009d83;
}

.ls .theme_buttons a:hover, .ls .theme_buttons a:focus, .ls .theme_buttons a:active,
.ls .theme_button:hover,
.ls .theme_button:focus,
.ls .theme_button:active {
	color: #ffffff;
}

.ls .theme_buttons.inverse a,
.ls .theme_button.inverse {
	color: #323232;
}

.ls .theme_buttons.inverse a:hover, .ls .theme_buttons.inverse a:focus, .ls .theme_buttons.inverse a:active,
.ls .theme_button.inverse:hover,
.ls .theme_button.inverse:focus,
.ls .theme_button.inverse:active {
	color: #0074ba;
}

.ls .theme_buttons[class*="color"],
.ls .theme_button[class*="color"] {
	color: #ffffff;
}

.ls .theme_buttons[class*="color"]:hover, .ls .theme_buttons[class*="color"]:focus, .ls .theme_buttons[class*="color"]:active,
.ls .theme_button[class*="color"]:hover,
.ls .theme_button[class*="color"]:focus,
.ls .theme_button[class*="color"]:active {
	color: #323232;
}

.ls .theme_button.no_bg_button {
	background-color: transparent !important;
	color: #656565;
}

.ls .theme_button.no_bg_button:hover, .ls .theme_button.no_bg_button:focus, .ls .theme_button.no_bg_button:active {
	color: #bee27b;
}

.ls .theme_button.no_bg_button.color1 {
	color: #0074ba;
}

.ls .theme_button.no_bg_button.color2 {
	color: #bee27b;
}

.ls .theme_button.no_bg_button.color3 {
	color: #67dfc1;
}

.ls .theme_button.no_bg_button.color4 {
	color: #009d83;
}

.ls .theme_button.no_bg_button[class*="color"]:hover, .ls .theme_button.no_bg_button[class*="color"]:focus, .ls .theme_button.no_bg_button[class*="color"]:active {
	color: #323232;
}

.ls:not(.ms) + .page_copyright.ls {
	background-color: #fafafa;
}

.ls .select-group:hover select + .theme_button,
.ls .select-group:hover select + i {
	color: #0074ba;
}

/*
** Grey Section - light section, muted section - .ls.ms
*/
.ls.ms {
	background-color: #fafafa;
}

.ls.ms.dark {
	background-color: #eaeaea;
}

.ls.ms .with_background {
	background-color: #ffffff;
}

.ls.ms .with_background hr {
	background-color: #f2f2f2;
}

.ls.ms .with_background .with_background {
	background-color: #fafafa;
}

.ls.ms .theme_bg .with_background {
	background-color: #fafafa;
}

.ls.ms hr {
	background-color: rgba(0, 0, 0, 0.05);
}

.ls.ms input[type="text"],
.ls.ms input[type="email"],
.ls.ms input[type="url"],
.ls.ms input[type="password"],
.ls.ms input[type="search"],
.ls.ms input[type="tel"],
.ls.ms input[type="number"],
.ls.ms textarea,
.ls.ms .select-group,
.ls.ms .form-control {
	background-color: #ffffff;
}

.ls.ms .with_background input[type="text"],
.ls.ms .with_background input[type="email"],
.ls.ms .with_background input[type="url"],
.ls.ms .with_background input[type="password"],
.ls.ms .with_background input[type="search"],
.ls.ms .with_background input[type="tel"],
.ls.ms .with_background input[type="number"],
.ls.ms .with_background textarea,
.ls.ms .with_background select,
.ls.ms .with_background .form-control {
	border-color: rgba(0, 0, 0, 0.05);
	background-color: #fafafa;
}

.ls.ms td,
.ls.ms th,
.ls.ms .with_border {
	border-color: #e5e5e5;
}

.ls.ms .nav-tabs > li.active > a,
.ls.ms .nav-tabs > li.active > a:hover,
.ls.ms .nav-tabs > li.active > a:focus {
	background-color: #ffffff;
}

.ls.ms .tab-content {
	background-color: #ffffff;
}

.ls.ms .tab-content .with_border {
	border-color: #e6e6e6;
}

.ls.ms .tab-content.no-border {
	background-color: transparent;
}

.ls.ms .tab-content .form-control {
	background-color: #fafafa;
}

.ls.ms .vertical-tabs .nav > li > a {
	background-color: #ffffff;
	border-color: #f2f2f2;
}

.ls.ms .vertical-tabs .nav > li > a:hover,
.ls.ms .vertical-tabs .nav > li.active > a {
	background-color: #36afb7;
	border-color: #36afb7;
}

.ls.ms .panel-title > a.collapsed {
	background-color: #ffffff;
	border-color: #f2f2f2;
}

.ls.ms .with_background .panel-title > a {
	border: 1px solid #f2f2f2;
}

.ls.ms .panel-title > a.collapsed:hover,
.ls.ms .panel-title > a:hover {
	background-color: #36afb7;
	border-color: #36afb7;
}

/*
** Dark Section and Dark Muted sections - .ds and .ds.ms
*/
/* darkgrey section - .ds */
.ds {
	background-color: #323232;
	color: #a2a2a2;
}

.ds.parallax, .ds.background_cover {
	color: rgba(255, 255, 255, 0.8);
}

.ds.parallax .form-control:-moz-placeholder, .ds.background_cover .form-control:-moz-placeholder {
	color: rgba(255, 255, 255, 0.6);
}

.ds.parallax .form-control::-moz-placeholder, .ds.background_cover .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.6);
}

.ds.parallax .form-control:-ms-input-placeholder, .ds.background_cover .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.6);
}

.ds.parallax .form-control::-webkit-input-placeholder, .ds.background_cover .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.6);
}

.ds.parallax .form-control:focus:-moz-placeholder, .ds.background_cover .form-control:focus:-moz-placeholder {
	color: transparent;
}

.ds.parallax .form-control:focus::-moz-placeholder, .ds.background_cover .form-control:focus::-moz-placeholder {
	color: transparent;
}

.ds.parallax .form-control:focus:-ms-input-placeholder, .ds.background_cover .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.ds.parallax .form-control:focus::-webkit-input-placeholder, .ds.background_cover .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

/* dark section - .ds.ms */
.ds.ms {
	background-color: #272727;
}

.ds.ms .with_background {
	background-color: #323232;
}

/* dark section blue - .ds.bs */
.ds.bs {
	background-color: #00202b;
}

.ds {
  /* shop */
}

.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6 {
	color: #ffffff;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a {
	color: inherit;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover {
	color: #0074ba;
}

.ds [class*='main_bg'],
.ds .dark_bg_color,
.ds .darkblue_bg_color,
.ds .darkgrey_bg_color,
.ds .success_bg_color,
.ds .info_bg_color,
.ds .warning_bg_color,
.ds .danger_bg_color {
	color: #ffffff;
}

.ds [class*='main_bg'] h1,
.ds [class*='main_bg'] h2,
.ds [class*='main_bg'] h3,
.ds [class*='main_bg'] h4,
.ds [class*='main_bg'] h5,
.ds [class*='main_bg'] h6,
.ds .dark_bg_color h1,
.ds .dark_bg_color h2,
.ds .dark_bg_color h3,
.ds .dark_bg_color h4,
.ds .dark_bg_color h5,
.ds .dark_bg_color h6,
.ds .darkblue_bg_color h1,
.ds .darkblue_bg_color h2,
.ds .darkblue_bg_color h3,
.ds .darkblue_bg_color h4,
.ds .darkblue_bg_color h5,
.ds .darkblue_bg_color h6,
.ds .darkgrey_bg_color h1,
.ds .darkgrey_bg_color h2,
.ds .darkgrey_bg_color h3,
.ds .darkgrey_bg_color h4,
.ds .darkgrey_bg_color h5,
.ds .darkgrey_bg_color h6,
.ds .success_bg_color h1,
.ds .success_bg_color h2,
.ds .success_bg_color h3,
.ds .success_bg_color h4,
.ds .success_bg_color h5,
.ds .success_bg_color h6,
.ds .info_bg_color h1,
.ds .info_bg_color h2,
.ds .info_bg_color h3,
.ds .info_bg_color h4,
.ds .info_bg_color h5,
.ds .info_bg_color h6,
.ds .warning_bg_color h1,
.ds .warning_bg_color h2,
.ds .warning_bg_color h3,
.ds .warning_bg_color h4,
.ds .warning_bg_color h5,
.ds .warning_bg_color h6,
.ds .danger_bg_color h1,
.ds .danger_bg_color h2,
.ds .danger_bg_color h3,
.ds .danger_bg_color h4,
.ds .danger_bg_color h5,
.ds .danger_bg_color h6 {
	color: #ffffff;
}

.ds .ls {
	background-color: #ffffff;
	color: #656565;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6 {
	color: #323232;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a {
	color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover {
	color: #0074ba;
}

.ds .ls a {
	color: #0074ba;
}

.ds .ls a:hover {
	color: #323232;
}

.ds .ls .darklinks a {
	color: #323232;
}

.ds .ls .darklinks a:hover {
	color: #0074ba;
}

.ds .ls hr {
	background-color: #f2f2f2;
}

.ds .ls blockquote {
	color: #323232;
}

.ds .ls .highlight {
	color: #0074ba;
}

.ds .ls .grey {
	color: #323232;
}

.ds label {
	color: #ffffff;
}

.ds a {
	color: #ffffff;
}

.ds a:hover {
	color: #0074ba;
}

.ds .slide_description a {
	color: #0074ba;
}

.ds blockquote {
	color: #ffffff;
}

.ds blockquote:before {
	color: #ffffff;
}

.ds hr {
	border-color: #fff;
	background-color: #fff;
}

.ds .social-icon.color-icon:hover {
	color: #ffffff;
}

.ds .muted_background,
.ds .with_background,
.ds .well {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .theme_bg {
	background-color: #000;
}

.ds .main_bg_color {
	background-color: #0074ba;
}

.ds .main_bg_color2 {
	background-color: #bee27b;
}

.ds.muted_section:before {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .with_border {
	border-color: #3b3b3b;
}

.ds .highlight,
.ds .highlight1 {
	color: #0074ba;
	border-color: #0074ba;
}

.ds .highlight2 {
	color: #bee27b;
	border-color: #bee27b;
}

.ds .highlight3 {
	color: #67dfc1;
	border-color: #67dfc1;
}

.ds .highlight4 {
	color: #009d83;
	border-color: #009d83;
}

.ds .grey, .ds .black {
	color: #ffffff;
	border-color: #ffffff;
}

.ds .lightgrey {
	color: #a2a2a2;
}

.ds .darkgrey {
	color: #656565;
}

.ds .greylinks a {
	color: #a2a2a2;
}

.ds .greylinks a:hover {
	color: #0074ba;
}

.ds .greylinks a.social-icon {
	color: #a2a2a2;
}

.ds .darklinks a,
.ds .darklink {
	color: #ffffff;
}

#home header.affix .sf-menu > li > a{
 color: #fff;
}

header.affix-top #currency_switcher a{
	color: #333;
}
header.affix-top .search_modal_button i{
	color: #333;
}
header.affix-top #currency_switcher a.active{
	color: #0073b7;
}

#currency-switcher a{
	color: #000;
}

.page_toplogo a{
	color: #000
}

.ds .darklinks a:hover,
.ds .darklink:hover {
	color: #0074ba;
}

.ds .highlightlinks a,
.ds .highlightlink {
	color: #0074ba;
}

.ds .highlightlinks a:hover,
.ds .highlightlink:hover {
	color: #ffffff;
}

.ds .highlight2links a,
.ds .highlight2link {
	color: #bee27b;
}

.ds .highlight2links a:hover,
.ds .highlight2link:hover {
	color: #ffffff;
}

.ds .colorlinks a {
	color: #bee27b;
}

.ds .colorlinks a:hover {
	color: #0074ba;
}

.ds.with_top_border:before, .ds.with_bottom_border:after {
	background-color: #3b3b3b;
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds input[type="number"],
.ds textarea,
.ds select,
.ds .form-control,
.ds .select-group {
	background-color: rgba(255, 255, 255, 0.1);
	color: #ffffff;
	border-color: transparent;
}

.intro_overlap_section .ds input[type="text"],
.intro_overlap_section .ds input[type="email"],
.intro_overlap_section .ds input[type="url"],
.intro_overlap_section .ds input[type="password"],
.intro_overlap_section .ds input[type="search"],
.intro_overlap_section .ds input[type="tel"],
.intro_overlap_section .ds input[type="number"],
.intro_overlap_section .ds textarea,
.intro_overlap_section .ds select,
.intro_overlap_section .ds .form-control,
.intro_overlap_section .ds .select-group {
	background-color: #ffffff;
	color: #323232;
	border-color: transparent;
}

.ds form label + [class*="icon-"] {
	color: #656565;
}

.ds .form-group-select:before {
	border-color: #3b3b3b;
}

.ds .contact-form.transparent-background .form-control {
	border-color: #3b3b3b;
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td,
.ds table th,
.ds table td {
	border-color: #3b3b3b;
}

.ds table th {
	color: #ffffff;
}

.ds .table_template th,
.ds .table_template td {
	border-color: transparent;
}

.ds .table_template th {
	color: inherit;
}

.ds .table-striped > tbody > tr:nth-child(odd),
.ds .table-striped > tbody > tr:nth-child(odd) > td,
.ds .table-striped > tbody > tr:nth-child(odd) > th {
	border-color: #3b3b3b;
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon:hover {
	color: #ffffff;
}

.ds .color-bg-icon:hover {
	background-color: #0074ba;
}

.ds .nav-unstyled {
	border-color: #3b3b3b;
}

.ds .nav-tabs > li > a {
	border-color: #ffffff;
	color: #ffffff;
}

.ds .tab-content {
	color: #ffffff;
	background-color: #656565;
}

.ds .tab-content.no-border {
	background-color: transparent;
}

.ds .nav-tabs > li > a:hover,
.ds .nav-tabs > li.active > a,
.ds .nav-tabs > li.active > a:hover,
.ds .nav-tabs > li.active > a:focus {
	border-color: transparent;
	background-color: #656565;
	color: #ffffff;
}

.ds .price-table.style1 {
	border-color: #3b3b3b;
}

.ds .plan-price p {
	color: #ffffff;
}

.ds .features-list .disabled:before {
	color: #ffffff;
}

.ds .features-list li + li {
	border-color: #3b3b3b;
}

.ds ul.list1 li {
	border-color: #3b3b3b;
}

.ds .testimonials-carousel .media + p {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .post-adds .share_button > i,
.ds .post-adds .like_button > i {
	color: #ffffff;
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li {
	border-color: #3b3b3b;
}

.ds .widget_nav_menu ul ul {
	border-color: #3b3b3b;
}

.ds .widget_popular_entries .media-heading {
	color: #ffffff;
}

.ds .tweet_list li + li .tweet_right {
	border-color: #3b3b3b;
}

.ds .widget_tag_cloud a {
	color: #ffffff;
}

.ds .widget_shopping_cart li a + a {
	color: #ffffff;
}

.ds .widget_shopping_cart li a + a:hover {
	color: #0074ba;
}

.ds .widget .woocommerce-Price-amount {
	color: #ffffff;
}

.ds .summary .price {
	color: #ffffff;
	border-color: #3b3b3b;
}

.ds .summary .product_meta {
	border-color: #3b3b3b;
}

.ds .widget_price_filter .price_label {
	color: #ffffff;
}

.ds #calendar_wrap {
	border-color: #3b3b3b;
}

.ds .widget_calendar table {
	border-color: #3b3b3b;
}

.ds .widget_calendar tfoot td a {
	border-color: #3b3b3b;
}

.ds .widget_calendar caption {
	color: #ffffff;
}

.ds .widget_calendar thead {
	background-color: #fafafa;
}

.ds .widget_calendar tbody {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .widget_calendar th {
	color: #323232;
}

.ds .widget_calendar tbody td a:hover {
	color: #ffffff;
}

.ds .grid-view h3:before, .ds .grid-view h3:after {
	background-color: #3b3b3b;
}

.ds .owl-carousel.product-thumbnails .owl-nav > div:after {
	color: #ffffff;
}

.ds .ui-slider {
	background-color: #ffffff;
}

.ds .ui-slider .ui-slider-handle {
	background-color: #ffffff;
}

.ds #sort_view {
	background-color: transparent;
	color: #ffffff;
}

.ds #toggle_shop_view.grid-view:after, .ds #toggle_shop_view:before {
	background-color: rgba(247, 247, 247, 0.05);
}

.ds .widget_layered_nav .color-filters a:before {
	border-color: #3b3b3b;
}

.ds .table.cart a {
	color: #ffffff;
}

.ds .pagination > li > a,
.ds .pagination > li > span {
	color: #ffffff;
}

.ds .pagination > li.active > a,
.ds .pagination > li.active > span,
.ds .pagination > li > a:hover,
.ds .pagination > li > span:hover,
.ds .pagination > li > a:focus,
.ds .pagination > li > span:focus {
	color: #009d83;
}

.ds .thumbnail {
	background-color: transparent;
}

.ds .thumbnail h3 a {
	color: #ffffff;
}

.ds .thumbnail .caption {
	border-color: transparent;
	background-color: rgba(252, 252, 252, 0.05);
}

.ds .progress {
	background-color: #fafafa;
}

.ds .panel-default {
	border-color: #3b3b3b;
}

.ds .panel-heading .panel-title > a.collapsed {
	background-color: rgba(255, 255, 255, 0.05);
	color: #656565;
	border-color: #3b3b3b;
}

.ds .panel-heading .panel-title > a,
.ds .panel-heading .panel-title > a:hover {
	border-color: #36afb7;
	background-color: #36afb7;
	color: #ffffff;
}

.ds .panel-heading .panel-title > a:hover:after,
.ds .vertical-tabs .nav > li > a:hover:after {
	color: #ffffff;
}

.ds .panel-body {
	border-color: #3b3b3b;
}

.ds .vertical-tabs .nav > li > a {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .vertical-tabs .nav > li.active a,
.ds .vertical-tabs .nav > li > a:hover {
	color: #ffffff;
	background-color: #0074ba;
}

.ds .vertical-tabs .nav > li.active a:after,
.ds .vertical-tabs .nav > li > a:hover:after {
	color: #ffffff;
}

.ds .entry-meta .comments-link a {
	color: #ffffff;
}

.ds #timetable tbody th,
.ds #timetable tbody td {
	border-color: #3b3b3b;
}

.ds div .fw-shortcode-calendar-wrapper .page-header h3,
.ds div .btn-group button[data-calendar-nav],
.ds div .btn-group button[data-calendar-nav*='today'] {
	color: #ffffff;
}

.ds .entry-meta.with_top_border:before {
	background-color: #3b3b3b;
}

.ds .text-nav .owl-nav > div {
	background-color: #323232;
}

.ds .text-nav .owl-nav > div:after {
	color: #ffffff;
}

.ds .text-nav .owl-nav > div:hover:after {
	color: #bee27b;
}

.ds a.border-icon {
	border-color: #656565;
}

.ds .nav-unstyled .active a {
	color: #ffffff;
}

.ds .countdown-row .countdown-period {
	color: #ffffff;
}

.ds .widget_slider .owl-carousel .owl-nav > div:after {
	color: #ffffff;
}

.ds .widget_slider .owl-carousel .owl-nav > div:hover:after {
	color: #0074ba;
}

.ds .divided-content > * + *:before {
	color: #a2a2a2;
}

.ds .nav-unstyled .active a {
	color: #0074ba;
}

.ds .theme_buttons a,
.ds .theme_button {
	color: #009d83;
	background-color: #ffffff;
}

.ds .theme_buttons a:hover, .ds .theme_buttons a:focus, .ds .theme_buttons a:active,
.ds .theme_button:hover,
.ds .theme_button:focus,
.ds .theme_button:active {
	color: #323232;
}

.ds .theme_buttons.inverse a,
.ds .theme_button.inverse {
	color: #ffffff;
	background-color: transparent;
	border-color: #ffffff;
}

.ds .theme_buttons.inverse a:hover, .ds .theme_buttons.inverse a:focus, .ds .theme_buttons.inverse a:active,
.ds .theme_button.inverse:hover,
.ds .theme_button.inverse:focus,
.ds .theme_button.inverse:active {
	color: #0074ba;
}

.ds .theme_buttons[class*="color"],
.ds .theme_button[class*="color"] {
	color: #ffffff;
}

.ds .theme_buttons[class*="color"]:hover, .ds .theme_buttons[class*="color"]:focus, .ds .theme_buttons[class*="color"]:active,
.ds .theme_button[class*="color"]:hover,
.ds .theme_button[class*="color"]:focus,
.ds .theme_button[class*="color"]:active {
	color: #323232;
}

.ds .theme_buttons.color1 a,
.ds .theme_button.color1,
.ds .button.alt,
.ds .btn-accent,
.ds .wc-proceed-to-checkout .checkout-button,
.ds .form-submit [type="submit"] {
	color: #ffffff;
	background-color: #0074ba;
}

.ds .theme_buttons.color1 a:hover, .ds .theme_buttons.color1 a:focus, .ds .theme_buttons.color1 a:active,
.ds .theme_button.color1:hover,
.ds .theme_button.color1:focus,
.ds .theme_button.color1:active,
.ds .button.alt:hover,
.ds .button.alt:focus,
.ds .button.alt:active,
.ds .btn-accent:hover,
.ds .btn-accent:focus,
.ds .btn-accent:active,
.ds .wc-proceed-to-checkout .checkout-button:hover,
.ds .wc-proceed-to-checkout .checkout-button:focus,
.ds .wc-proceed-to-checkout .checkout-button:active,
.ds .form-submit [type="submit"]:hover,
.ds .form-submit [type="submit"]:focus,
.ds .form-submit [type="submit"]:active {
	color: #323232;
}

.ds .theme_buttons.color1.dark a,
.ds .theme_button.color1.dark {
	background-color: #0c9ece;
}

.ds .theme_buttons.color2 a,
.ds .theme_button.color2 {
	background-color: #bee27b;
}

.ds .theme_buttons.color2.dark a,
.ds .theme_button.color2.dark {
	background-color: #009d83;
}

.ds .theme_buttons.color3 a,
.ds .theme_button.color3 {
	background-color: #67dfc1;
}

.ds .theme_buttons.color3.dark a,
.ds .theme_button.color3.dark {
	background-color: #6fc3b4;
}

.ds .theme_buttons.color4 a,
.ds .theme_button.color4 {
	background-color: #009d83;
}

.ds .theme_buttons.color4.dark a,
.ds .theme_button.color4.dark {
	background-color: #009d83;
}

.ds .theme_button.no_bg_button.color1 {
	color: #0074ba;
}

.ds .theme_button.no_bg_button.color1:hover {
	color: #323232;
}

.ds .theme_button.no_bg_button.color2 {
	color: #bee27b;
}

.ds .theme_button.no_bg_button.color2:hover {
	color: #323232;
}

.ds .dropdown-menu {
	background-color: #323232;
}

.ds .content-padding footer:before {
	background-color: #3b3b3b;
}

.ds .item-content + footer.item-meta > div + div,
.ds .item-content + footer.item-meta {
	border-color: #3b3b3b;
}

.ds .teaser .dashed_border {
	background-image: none;
	border: 1px solid #3b3b3b;
}

.ds .teaser .border_icon {
	border-color: #3b3b3b;
}

.ds .format-chat.with_background .entry-content p:nth-child(2n) {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .two-col-grid > *:nth-child(even),
.ds .two-col-grid > * + * {
	border-color: #3b3b3b;
}

/*
** Color Section - .cs
*/
.cs.main_color2,
.cs.main_bg_color2 {
	background-color: #bee27b;
}

.cs.main_color2 .highlight,
.cs.main_bg_color2 .highlight {
	color: #0074ba;
}

.cs.main_color3,
.cs.main_bg_color3 {
	background-color: #67dfc1;
}

.cs.main_color3 .highlight,
.cs.main_bg_color3 .highlight {
	color: #0074ba;
}

.cs.main_color4,
.cs.main_bg_color4 {
	background-color: #009d83;
}

.cs.main_color4 .highlight,
.cs.main_bg_color4 .highlight {
	color: #0074ba;
}

.cs.gradient {
	background: linear-gradient(-90deg, #bee27b, #0074ba);
}

.cs.gradient .breadcrumb > .active {
	color: #323232;
}

.cs.radial_lighting {
	background-image: radial-gradient(circle, rgba(255, 255, 255, 0.4), rgba(12, 158, 206, 0.5));
}

.cs {
	background-color: #0074ba;
	color: #fff;
}

.cs.parallax {
	color: rgba(255, 255, 255, 0.5);
}

.cs .ls .greylinks a {
	color: #656565;
}

.cs .ls .greylinks a:hover, .cs .ls .greylinks a:focus, .cs .ls .greylinks a:active {
	color: #656565;
}

.cs .ls h1 a:hover,
.cs .ls h2 a:hover,
.cs .ls h3 a:hover,
.cs .ls h4 a:hover,
.cs .ls h5 a:hover,
.cs .ls h6 a:hover {
	color: #0074ba;
}

.cs .ds .greylinks a {
	color: #656565;
}

.cs .ds .greylinks a:hover, .cs .ds .greylinks a:focus, .cs .ds .greylinks a:active {
	color: #656565;
}

.cs .ds h1,
.cs .ds h2,
.cs .ds h3,
.cs .ds h4,
.cs .ds h5,
.cs .ds h6 {
	color: #ffffff;
}

.cs .ds h1 a,
.cs .ds h2 a,
.cs .ds h3 a,
.cs .ds h4 a,
.cs .ds h5 a,
.cs .ds h6 a {
	color: inherit;
}

.cs .ds h1 a:hover,
.cs .ds h2 a:hover,
.cs .ds h3 a:hover,
.cs .ds h4 a:hover,
.cs .ds h5 a:hover,
.cs .ds h6 a:hover {
	color: #0074ba;
}

.cs h1,
.cs h2,
.cs h3,
.cs h4,
.cs h5,
.cs h6 {
	color: #ffffff;
}

.cs h1 a,
.cs h2 a,
.cs h3 a,
.cs h4 a,
.cs h5 a,
.cs h6 a {
	color: inherit;
}

.cs h1 a:hover,
.cs h2 a:hover,
.cs h3 a:hover,
.cs h4 a:hover,
.cs h5 a:hover,
.cs h6 a:hover {
	color: rgba(255, 255, 255, 0.5);
}

.cs a {
	color: #ffffff;
}

.cs a:hover, .cs a:active, .cs a:focus {
	color: rgba(255, 255, 255, 0.5);
}

.cs blockquote, .cs .blockquote {
	color: #ffffff;
}

.cs .with_background {
	background-color: rgba(255, 255, 255, 0.23);
}

.cs .with_border {
	border-color: rgba(255, 255, 255, 0.23);
}

.cs.with_top_border .container:before,
.cs.with_bottom_border .container:after {
	background-color: #7accea;
}

.cs .greylinks a,
.cs .greylinks a.social-icon {
	color: #323232;
}

.cs .greylinks a:hover,
.cs .greylinks a.social-icon:hover {
	color: #ffffff;
}

.cs .darklinks a {
	color: #ffffff;
}

.cs .darklinks a:hover {
	color: #bee27b;
}

.cs .highlightlinks a,
.cs .highlightlinks a.social-icon {
	color: rgba(255, 255, 255, 0.5);
}

.cs .black {
	border-color: #ffffff;
	color: #ffffff;
}

.cs .grey {
	border-color: #323232;
	color: #323232;
}

.cs .lightgrey {
	color: rgba(255, 255, 255, 0.6);
}

.cs .response,
.cs .highlight {
	color: #fff;
}

.cs label + .highlight,
.cs label + .highlight {
	color: #0074ba;
}

.cs .light_bg_color .highlight, .cs .light_bg_color.highlight {
	color: #0074ba;
}

.cs ul.list1 li {
	border-color: rgba(255, 255, 255, 0.23);
}

.cs [class*='socicon-'] {
	border-color: rgba(255, 255, 255, 0.23);
}

.cs .color-icon.bg-icon {
	background-color: rgba(255, 255, 255, 0.23);
}

.cs a.social-icon.color-bg-icon:hover {
	background-color: #323232;
}

.cs a.social-icon.theme-color-icon {
	color: #ecf8f9;
}

.cs a.social-icon.theme-color-icon:hover, .cs a.social-icon.theme-color-icon:focus, .cs a.social-icon.theme-color-icon:active {
	color: #ffffff;
}

.cs .pagination > li > a,
.cs .pagination > li > span {
	color: #ffffff;
}

.cs .pagination > li.active > a,
.cs .pagination > li.active > span,
.cs .pagination > li > a:hover,
.cs .pagination > li > span:hover,
.cs .pagination > li > a:focus,
.cs .pagination > li > span:focus {
	color: #ffffff;
}

.cs .widget_recent_entries li + li,
.cs .widget_recent_comments li + li,
.cs .widget_archive li + li,
.cs .widget_categories li + li,
.cs .widget_meta li + li,
.cs .widget_popular_entries li + li,
.cs .widget_nav_menu li + li,
.cs .widget_pages li + li {
	border-top-color: rgba(255, 255, 255, 0.23);
}

.cs .theme_buttons a,
.cs .theme_button {
	color: #009d83;
}

.cs .theme_buttons a:hover, .cs .theme_buttons a:focus, .cs .theme_buttons a:active,
.cs .theme_button:hover,
.cs .theme_button:focus,
.cs .theme_button:active {
	color: #ffffff;
}

.cs .theme_buttons.inverse a,
.cs .theme_button.inverse {
	color: #ffffff;
	border-color: #ffffff;
}

.cs .theme_buttons.inverse a:hover, .cs .theme_buttons.inverse a:focus, .cs .theme_buttons.inverse a:active,
.cs .theme_button.inverse:hover,
.cs .theme_button.inverse:focus,
.cs .theme_button.inverse:active {
	color: #323232;
}

.cs .theme_buttons[class*="color"],
.cs .theme_button[class*="color"] {
	color: #ffffff;
}

.cs .theme_buttons[class*="color"]:hover, .cs .theme_buttons[class*="color"]:focus, .cs .theme_buttons[class*="color"]:active,
.cs .theme_button[class*="color"]:hover,
.cs .theme_button[class*="color"]:focus,
.cs .theme_button[class*="color"]:active {
	color: #323232;
}

.cs .theme_buttons.color1 a,
.cs .theme_button.color1 {
	color: #0074ba;
	background-color: #ffffff;
}

.cs .theme_button.no_bg_button:hover, .cs .theme_button.no_bg_button:active, .cs .theme_button.no_bg_button:focus {
	color: #323232 !important;
}

.cs .theme_button.no_bg_button.color1 {
	color: #0074ba;
}

.cs .theme_button.no_bg_button.color2 {
	color: #bee27b;
}

.cs .theme_button.no_bg_button.color3 {
	color: #67dfc1;
}

.cs .divided-content > * + *:before {
	color: #ffffff;
}

.cs .list3 li:after {
	color: #fff;
}

.cs #isotope_filters a,
.cs .filters a,
.cs #isotope_filters a.selected,
.cs #isotope_filters a:hover,
.cs .filters a.selected,
.cs .filters a:hover {
	color: #ffffff;
}

.cs #isotope_filters a:after,
.cs .filters a:after,
.cs #isotope_filters a.selected:after,
.cs #isotope_filters a:hover:after,
.cs .filters a.selected:after,
.cs .filters a:hover:after {
	background-color: #ffffff;
}

.cs.with_top_border:before, .cs.with_bottom_border:after,
.cs.with_top_border_container .container:before,
.cs.with_bottom_border_container .container:after {
	background-color: rgba(255, 255, 255, 0.23);
}

.cs .flex-control-nav.owl-carousel:after {
	background-color: #ffffff;
}

.cs .breadcrumb > li + li:before {
	color: #ffffff;
}

.cs input[type="text"],
.cs input[type="email"],
.cs input[type="url"],
.cs input[type="password"],
.cs input[type="search"],
.cs input[type="tel"],
.cs input[type="number"],
.cs textarea,
.cs select,
.cs .form-control,
.cs .select-group,
.background_cover input[type="text"],
.background_cover input[type="email"],
.background_cover input[type="url"],
.background_cover input[type="password"],
.background_cover input[type="search"],
.background_cover input[type="tel"],
.background_cover input[type="number"],
.background_cover textarea,
.background_cover select,
.background_cover .form-control,
.background_cover .select-group {
	color: #323232;
	background-color: #ffffff;
	border-color: #ffffff;
}

.cs .select-group .theme_button,
.background_cover .select-group .theme_button {
	color: #fff;
	background-color: transparent !important;
	border-color: transparent !important;
}

.cs input[type="checkbox"] + label:before,
.background_cover input[type="checkbox"] + label:before {
	border-color: #bee27b;
}

.cs .form-control:focus,
.background_cover .form-control:focus {
	border-color: rgba(255, 255, 255, 0.6);
}

.cs .form-control:-moz-placeholder,
.background_cover .form-control:-moz-placeholder {
	color: #656565;
	opacity: 1;
}

.cs .form-control::-moz-placeholder,
.background_cover .form-control::-moz-placeholder {
	color: #656565;
	opacity: 1;
}

.cs .form-control:-ms-input-placeholder,
.background_cover .form-control:-ms-input-placeholder {
	color: #656565;
}

.cs .form-control::-webkit-input-placeholder,
.background_cover .form-control::-webkit-input-placeholder {
	color: #656565;
}

.cs .form-control:focus:-moz-placeholder,
.background_cover .form-control:focus:-moz-placeholder {
	color: transparent;
}

.cs .form-control:focus::-moz-placeholder,
.background_cover .form-control:focus::-moz-placeholder {
	color: transparent;
}

.cs .form-control:focus:-ms-input-placeholder,
.background_cover .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.cs .form-control:focus::-webkit-input-placeholder,
.background_cover .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

.cs .teaser .border_icon,
.background_cover .teaser .border_icon {
	border-color: rgba(255, 255, 255, 0.23);
}

/*
** Template Specific Styles
*/
/* topline styles */
.page_topline {
	font-size: 12px;
	line-height: 30px;
	position: relative;
	z-index: 1001;
}

.page_topline .small-text {
	font-size: 12px;
}

.page_topline i {
	font-size: 18px;
	vertical-align: middle;
	display: inline-block;
}

/* toplogo styles */
@media (max-width: 991px) {
	.page_toplogo.section_padding_top_15.section_padding_bottom_15 > [class*="container"] {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.page_toplogo .dropdown-menu {
	min-width: 0;
}

.page_toplogo .display_table_cell {
	overflow: visible;
}

.small-text .dropdown-menu > li > a {
	font-size: 12px;
}

#account-dropdown + .dropdown-menu > li > a:before {
	content: "\f114";
	font-family: FontAwesome;
	font-size: 14px;
	font-weight: 400;
	width: 23px;
	display: inline-block;
	position: relative;
	top: 1px;
}

#account-dropdown + .dropdown-menu > li > a:hover:before {
	content: "\f115";
}

#search-dropdown {
	width: auto;
}

#search-dropdown i {
	font-size: 14px;
	top: 0;
}

#search-dropdown + .dropdown-menu {
	top: 0;
	right: calc(100% + 10px);
	padding: 0;
	box-shadow: none;
}

#search-dropdown + .dropdown-menu .widget_search .form-group + .theme_button {
	right: -24px;
	width: auto;
}

#search-dropdown + .dropdown-menu .widget_search .form-group + .theme_button:before {
	position: relative;
	top: -1px;
	left: -1px;
}

@media (min-width: 992px) {
	.with_bottom_overlap_logo + .page_header_wrapper .page_header,
  .with_bottom_overlap_logo + .page_header {
		padding-top: 65px;
	}

	.with_bottom_overlap_logo + .page_header_wrapper .page_header.affix,
  .with_bottom_overlap_logo + .page_header.affix {
		padding-top: 0;
	}
}

/* title section (breadcrumbs) styles */
.page_breadcrumbs .breadcrumbs_logo {
	margin: 0 0 15px;
}

.page_breadcrumbs .breadcrumbs_logo img {
	opacity: 0.3;
}

.page_breadcrumbs h2 {
	display: inline;
	font-size: 30px;
	font-weight: 100;
	word-wrap: break-word;
}

.page_breadcrumbs h2 + .breadcrumb {
	margin-top: 3px;
}

@media (min-width: 992px) {
	.page_breadcrumbs h2 {
		font-size: 48px;
	}
}

.page_breadcrumbs h2.small {
	font-size: 20px;
}

@media screen and (min-width: 992px) {
	.page_breadcrumbs .display_table_md {
		width: auto;
		min-width: 0;
	}

	.page_breadcrumbs h2.display_table_cell_md {
		padding-right: 20px;
		padding-left: 15px;
	}

	.page_breadcrumbs .breadcrumb.display_table_cell_md {
		border-left: 1px solid rgba(255, 255, 255, 0.23);
		padding-left: 25px;
	}
}

.page_breadcrumbs .bottom_breadcrumbs {
	text-align: center;
	float: right;
	padding: 4px 20px 0;
	margin: 0 0 -25px 0;
}

.taxonomy-name-title {
	display: none;
}

/* intro overlap section */
@media (min-width: 992px) {
	.intro_section + .intro_overlap_section {
		position: relative;
	}

	.intro_section + .intro_overlap_section .container {
		position: absolute;
		top: -870px;
		left: 0;
		right: 0;
	}

	.intro_section + .intro_overlap_section .container [class*="col-"] {
		z-index: 10;
	}
}

/* template parallax sections */
@media (min-width: 992px) {
	#features .image_cover {
		background-position: top left;
	}
}

#guide.parallax {
	background-image: url(../img/parallax/progress.jpg);
}

#testimonials.background_cover {
	background-image: url(../img/parallax/testimonials.jpg);
}

@media (min-width: 1200px) {
	#testimonials > [class*="container"] {
		padding-top: 220px;
		padding-bottom: 250px;
	}
}

#subscribe.parallax,
.call_to_action.parallax,
.section_subscribe.parallax {
	background-image: url(../img/parallax/progress.jpg);
}

.section_404.background_cover input[type="text"] {
	color: #323232;
	background-color: #ffffff;
	border-color: #ffffff;
}

.page_breadcrumbs.parallax {
	background-image: none;
	background: transparent;
	overflow: hidden;
}

.page_footer.parallax,
.page_copyright.parallax,
.page_contacts.parallax {
	background-image: url(../img/parallax/bottom.jpg);
}

/* footer styles */
.page_footer .footer_logo {
	padding-top: 5px;
	padding-bottom: 5px;
}

.page_footer .widget {
	margin-top: 35px;
}

@media (min-width: 992px) {
	.page_footer .widget_media_margin {
		margin-top: 60px;
	}
}

/* copyrights styles */
.page_copyright {
	font-size: 14px;
}

.page_copyright p {
	margin: 0;
}

.page_copyright i.fa {
	opacity: 0.8;
}

.page_copyright.table_section .bottom_logo img {
	margin-bottom: 0;
}

.page_footer.ds + .page_copyright.ds .container {
	position: relative;
}

.page_footer.ds + .page_copyright.ds .container:before {
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	left: 15px;
	right: 15px;
	top: 0;
	background-color: #3b3b3b;
	z-index: 1;
}

.ds.ms + .page_footer.ds.ms .container {
	position: relative;
}

.ds.ms + .page_footer.ds.ms .container:before {
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	left: 0;
	right: 0;
	top: 0;
	background-color: #3b3b3b;
	z-index: 1;
}

/* google map  */

#contact-map {
	padding-bottom: 25%;
	min-height: 250px;
}

#map, .page_map {
	height: 0;
	padding-bottom: 46%;

	@media screen and (max-width: 899px) {
		padding-bottom: 100%;
	}
}

@media screen and (max-width: 899px) {
	#collecte #map, #garagepartners #map {
		padding-bottom: 150%;
	}
}

#map img,
.page_map img {
	max-width: inherit;
}

#map p,
.page_map p {
	line-height: 1.3;
	margin-bottom: 0;
}

#map .map_marker_description,
.page_map .map_marker_description {
	padding: 10px 0px 5px 10px;
}
.map_marker_description{

	h3{
		margin-bottom: 10px;
	}

	p{
		font-size: 16px;
	}
}

#map .map_marker_icon,
.page_map .map_marker_icon {
	display: none;
}

.widget #map, .widget
.page_map {
	height: 225px;
}

/* Other */
.page_portfolio {
	overflow: hidden;
}

/* Wide Banner */
.wide_banner {
	display: block;
	padding: 15px 15px 5px;
	background-image: url(../img/banner-wide.jpg);
	background-size: cover;
	background-position: center;
}

@media (min-width: 992px) {
	.wide_banner {
		padding: 15px 80px 5px;
	}
}

/* Tile Gallery section with filters */
@media (min-width: 992px) {
	.tile_gallery .isotope-item .item-media {
		padding-bottom: 66.666667%;
	}

	.tile_gallery .isotope-item .item-media img {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 100%;
	}

	.tile_gallery .isotope-item.high-item .item-media {
		padding-bottom: 133.333333334%;
	}

	.tile_gallery .isotope-item.high-item .item-media img {
		width: 200%;
		max-width: 200%;
	}

	.tile_gallery .isotope-item.col-lg-6.col-md-8.col-sm-6 .item-media {
		padding-bottom: 33.33333333334%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.tile_gallery .isotope-item:first-child .item-media {
		padding-bottom: 133.333333334%;
	}

	.tile_gallery .isotope-item:first-child .item-media img {
		width: 200%;
		max-width: 200%;
	}
}

/*
** Intro Section Styles
*/
.intro_section {
	position: relative;
	clear: both;
	max-width: 100%;
	overflow: hidden;

	.slides li{
        background-color: #efefef;
        background-repeat: no-repeat;
        width: 100%;
        float: left;
        margin-right: -100%;
        position: relative;
        opacity: 1;
        display: block;
        z-index: 2;
	}

	> img {
		width: 100%;
	}

	&.ds{
		color: #ffffff;
	}
}

@media (min-width: 992px) {
	.intro_section.ds .grey {
		color: #323232;
	}
}

.intro_section .slides > li {
	position: relative;
	overflow: hidden;
}

.intro_section .flexslider:before, .intro_section .flexslider:after {
	content: '';
	display: block;
	clear: both;
}

.intro_section .flexslider .intro-layer img {
	width: auto;
	display: inline-block;
}

.intro_section .flex-control-nav {
	position: absolute;
}

.intro_section.blog_slider {
	margin-bottom: 40px;
}

@media (min-width: 992px) {
	.intro_section.blog_slider {
		margin-bottom: 60px;
	}

	.intro_section.blog_slider .slides > li {
		max-height: 600px;
	}
}

.intro_section.blog_slider img {
	opacity: 0.4;
}

.intro_section.blog_slider .slide_description_wrapper .slide_description {
	max-width: none;
}

.intro_section .flexslider .container,
.intro_section img + .container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.intro_section [class*="col-"] {
	position: static;
	min-height: 0;
}

.intro_section .slide_description_wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100%;
	font-size: 0;
	z-index: 5;
	padding: 30px 0;
}

@media (min-width: 600px) {
	.boxed .intro_section .slide_description_wrapper {
		padding-left: 60px;
		padding-right: 60px;
	}
}

@media (min-width: 992px) {
	.intro_section .slide_description_wrapper {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.intro_section .slide_description {
	font-size: 20px;
	line-height: 1.5em;
}

.intro_section .slide_description_wrapper:before,
.intro_section .slide_description {
	display: inline-block;
	vertical-align: middle;
}

.intro_section .slide_description_wrapper:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
}

.intro_section .slide_description_wrapper.slide_text_top:before {
	vertical-align: top;
}

.intro_section h2 {
	font-size: 54px;
	line-height: 1;
	margin-bottom: 0.43em;
}

.intro_section .slide_buttons {
	margin-top: 10%;
}

@media (min-width: 992px) {
	.page_mainslider > img,
  .page_mainslider .slides > li > img {
		min-width: 1920px;
	}
}

@media (min-width: 768px) {
	.intro_section h2 {
		font-size: 60px;
	}

	.intro_section .small-text {
		letter-spacing: 0.2em;
	}
}

@media (min-width: 992px) {
	.intro_section .slide_description {
		font-size: 24px;
		max-width: 57.5%;
	}

	.transparent_wrappper + .page_mainslider .slide_description_wrapper {
		padding-top: 170px;
	}
}

@media (min-width: 1200px) {
	.intro_section h2 {
		font-size: 61px;
	}

	.intro_section .slide_description {
		font-size: 20px;
		max-width: 60%;
	}
}

@media (min-width: 1600px) {
	.intro_section .flex-control-nav {
		bottom: 60px;
	}
}

@media (max-width: 991px) {
	.intro_section {
		text-align: center;
	}

	.intro_section .nav-container {
		padding: 0;
	}

	.intro_section .flex-control-nav,
  .intro_section .flex-custom-nav {
		position: static;
		text-align: center;
		margin-top: 0;
		margin-bottom: 50px;
	}

	.intro_section .flexslider .container {
		position: static;
		width: auto;
		max-width: 100%;
	}

	.intro_section .slide_description_wrapper:before {
		display: none;
	}

	.intro_section .slide_description {
		padding: 30px 0;
		display: block;
		margin: auto;
		text-align: center;
	}

	.intro_section .slide_description_wrapper {
		padding: 0;
		position: static;
	}
}

/*
** Main Menu styles
*/
.sf-menu a {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 400;
}

/* Desktop menu - (min 992px) styles */
@media (min-width: 992px) {
	.mainmenu_wrapper {
		position: relative;
	}

	.header_darkgrey.with_menu_icon .mainmenu_wrapper:after {
		color: #ffffff;
	}
  /* menu layout */
	.sf-menu, .sf-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.sf-menu li {
		position: relative;
	}

	.sf-menu li > ul {
		position: absolute;
		display: none;
		top: 100%;
		z-index: 99;
	}

	.sf-menu li:hover > ul,
  .sf-menu li.sfHover > ul {
		display: block;
	}

	.sf-menu a {
		display: block;
		position: relative;
	}

	.sf-menu li > ul > li > ul {
		top: -20px;
		left: 100%;
	}
  /* menu skin */
	.sf-menu a {
		padding: 5px 10px;
		text-decoration: none;
		zoom: 1;
    /* IE7 */
		transition-property: color, background-color, border-color, padding-top, padding-bottom;
	}

	.sf-menu a {
		color: #323232;
	}

	.sf-menu li {
		white-space: nowrap;
    /* no need for Supersubs plugin */
		*white-space: normal;
    /* ...unless you support IE7 (let it wrap) */
		transition: background .2s;
	}

	.sf-menu > li {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		margin: 0 -2px;
	}

	.sf-menu > li:first-child {
		margin-left: 0;
	}

	.sf-menu > li:last-child {
		margin-right: 0;
	}

	.sf-menu > li > a {
		padding-top: 22px;
		padding-bottom: 22px;
		padding-right: 0;
		padding-left: 0;
	}

	.header_transparent .sf-menu > li > a:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 2px;
	}

	.thin_header .sf-menu > li > a {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.item_with_border .sf-menu > li.active > a {
		border-top: 5px solid #0074ba;
	}

	.bordered_items .sf-menu > li {
		padding: 0;
	}

	.bordered_items .sf-menu > li + li:before {
		content: '';
		width: 1px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.23);
	}

	.header_white .sf-menu > li > a {
		color: #323232;
	}

	.header_white .sf-menu > li.active > a {
		color: #0074ba;
	}

	.header_white .sf-menu > li.active > a:before {
		background-color: #323232;
	}

	.header_white .sf-menu > li > a:hover {
		color: #0074ba;
	}

	.header_white .sf-menu > li + li:before {
		background-color: #f2f2f2;
	}

	.header_darkgrey .sf-menu > li > a {
		color: #000;
	}

	.header_darkgrey .sf-menu > li.active > a {
		color: #0073b7;
	}

	.header_darkgrey .sf-menu > li.active > a:before {
		background-color: #ffffff;
	}

	.header_darkgrey .sf-menu > li > a:hover {
		color: #0074ba;
	}

	.header_color ul a {
		color: #ffffff;
	}

	.header_color ul a:hover,
  .header_color ul li.active > a {
		color: #323232;
	}

	.header_color .sf-menu ul a {
		color: #323232;
	}

	.sf-menu li:hover,
  .sf-menu li.sfHover {
    /* only transition out, not in */
		transition: none;
	}
  /* second and next levels */
	.sf-menu > li .mega-menu,
  .sf-menu li > ul {
		margin-top: 10px;
		text-align: left;
		list-style: none;
		padding: 20px 0;
		background-color: #ffffff;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
		min-width: 17em;
    /* allow long menu items to determine submenu width */
	}

	.sf-menu > li .mega-menu > li > ul,
  .sf-menu li > ul > li > ul {
		z-index: -1;
	}

	.sf-menu ul a {
		padding-left: 30px;
		padding-right: 50px;
	}
}

@media (min-width: 992px) and (min-width: 1600px) {
	.sf-menu ul a {
		padding-right: 40px;
		padding-left: 40px;
	}
}

@media (min-width: 992px) {
	.sf-menu .mega-menu ul {
		box-shadow: none;
		padding: 0;
	}

	.sf-menu .mega-menu ul a {
		padding-left: 0;
		padding-right: 0;
	}

	.sf-menu ul a:hover,
  .sf-menu ul li.active > a {
		color: #0074ba;
	}

	.sf-menu ul a:hover:after,
  .sf-menu ul li.active > a:after {
		display: none;
	}
  /* third level */
	.sf-menu ul ul {
		margin-top: 0;
	}

	.divided_items .sf-menu > li + li {
		position: relative;
	}

	.divided_items .sf-menu > li + li:before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -5px;
		width: 1px;
		height: 10px;
		background-color: rgba(255, 255, 255, 0.23);
	}

	.header_white .divided_items .sf-menu > li + li:before {
		background-color: #323232;
	}

	ul .dropdown-menu a:not(.theme_button) {
		padding: 0;
	}

	ul .dropdown-menu .theme_button {
		padding: 13px 30px 13px;
	}
}

/* common paddings */
@media (min-width: 992px) {
	.sf-menu > li > a {
		margin-left: 2px;
		margin-right: 2px;
	}

	[class*="container"] .sf-menu > li:first-child > a {
		margin-left: 0;
	}

	[class*="container"] .sf-menu > li:last-child > a {
		margin-right: 0;
	}

	.bordered_items .sf-menu > li > a {
		margin: 0;
		padding: 15px 30px;
	}
}

/* -md- paddings */
@media (min-width: 992px) and (max-width: 1199px) {
	.sf-menu > li > a {
		margin-left: 15px;
		margin-right: 15px;
	}
}

/* -lg- paddings */
@media (min-width: 1200px) {
	.sf-menu > li > a {
		margin-left: 19px;
		margin-right: 19px;
	}

	.boxed .col-md-6 .sf-menu > li > a {
		margin-left: 14px;
		margin-right: 14px;
	}

	.bordered_items .sf-menu > li > a {
		padding: 15px 40px;
	}
}

.sf-menu i[class] {
	margin-right: 7px;
}

.sf-menu .header-button {
	font-size: 14px;
}

.sf-menu .header-button i[class] {
	margin-right: 0;
}

/* hidden menu items */
@media (min-width: 992px) {
	.sf-menu .sf-md-hidden {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.sf-more-li {
		display: none;
	}
}

/* logo in center of menu */
.sf-menu > .sf-logo-li {
	visibility: hidden;
}

/* Mobile menu toggler */
@media (max-width: 991px) {
	.toggle_menu {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -30px;
		margin-top: -30px;
		width: 60px;
		height: 60px;
		cursor: pointer;
		z-index: 1001;
		visibility: visible;
	}

	.toggler_right .toggle_menu {
		right: 0;
		left: auto;
	}

	.toggler_left .toggle_menu {
		right: auto;
		left: 0;
		margin-left: 0;
	}
}

@media (max-width: 991px) and (max-width: 767px) {
	.toggler_xs_right .toggle_menu {
		right: 0;
		left: auto;
	}
}

@media (max-width: 991px) and (max-width: 499px) {
	.toggler_xxs_right .toggle_menu {
		right: 0;
		left: auto;
	}
}

@media (max-width: 991px) {
	.relative_toggler .toggle_menu {
		position: relative;
		display: block;
		top: auto;
		left: auto;
		margin: 0;
	}

	.ls .toggle_menu span,
  .ls .toggle_menu span:before,
  .ls .toggle_menu span:after,
  .header_white .toggle_menu span,
  .header_white .toggle_menu span:before,
  .header_white .toggle_menu span:after {
		background-color: #323232;
	}

	.intro_section + .page_header_wrapper .toggle_menu span,
  .intro_section + .page_header_wrapper .toggle_menu span:before,
  .intro_section + .page_header_wrapper .toggle_menu span:after {
		background-color: #ffffff;
	}

	.intro_section + .page_header_wrapper .affix .toggle_menu span,
  .intro_section + .page_header_wrapper .affix .toggle_menu span:before,
  .intro_section + .page_header_wrapper .affix .toggle_menu span:after {
		background-color: #323232;
	}

	.intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span,
  .intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span:before,
  .intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span:after {
		background-color: #ffffff;
	}

	.page_toplogo .toggle_menu {
		position: fixed;
		top: 0;
		left: auto;
		right: 0;
		margin: 0;
		z-index: 10;
		background-color: #ffffff;
	}

	.header_logo_center .toggle_menu {
		right: 0;
		left: auto;
	}

	.toggle_menu:hover span:before {
		top: -9px;
	}

	.toggle_menu:hover span:after {
		top: 9px;
	}

	.toggle_menu:before,
  .toggle_menu span,
  .toggle_menu span:before,
  .toggle_menu span:after {
		display: block;
		width: 30px;
		position: absolute;
		background-color: #ffffff;
		content: '';
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		transition: .25s ease-in-out;
		height: 2px;
	}

	.page_toplogo .toggle_menu:before, .page_toplogo
  .toggle_menu span, .page_toplogo
  .toggle_menu span:before, .page_toplogo
  .toggle_menu span:after {
		background-color: #323232;
	}

	.toggle_menu:before,
  .toggle_menu span {
		left: 15px;
		top: 50%;
	}

	.toggle_menu span:before {
		opacity: 1;
		top: -8px;
	}

	.toggle_menu span:after {
		opacity: 1;
		top: 8px;
	}

	.toggle_menu.mobile-active {
		background-color: #323232;
		border: none;
		left: auto;
		top: 0;
		right: 0;
		position: fixed;
		margin: 0;
		z-index: 3000;
	}

	.toggle_menu.mobile-active:before {
		background-color: #ffffff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.toggle_menu.mobile-active span {
		background-color: #ffffff;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.toggle_menu.mobile-active span:before,
  .toggle_menu.mobile-active span:after {
		opacity: 0;
	}
}

/* Mobile Menu (max 991px) styles */
@media (max-width: 991px) {
	.mainmenu_wrapper {
    /*position: relative;*/
		position: fixed;
		visibility: hidden;
		z-index: 10;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: transparent;
		opacity: 0;
		transition: all 0.3s ease 0s;
	}

	.mobile-active .mainmenu_wrapper {
		visibility: visible;
		opacity: 1;
		background-color: #111;
		background-color: #272727;
	}

	.nav-justified > li > a {
		text-align: left;
	}

	.sf-menu {
		position: fixed;
		overflow: auto;
		background-color: #323232;
		padding: 20px 30px 20px 10px;
		top: 0px;
		left: 0px;
		bottom: 0px;
		right: 100%;
		font-size: 1.1em;
		text-transform: uppercase;
		font-weight: 400;
		word-wrap: break-word;
		text-align: left;
		transition: all 0.4s ease-out 0s;
		opacity: 0;
	}

	.mobile-active .sf-menu {
		opacity: 1;
		right: 30px;
	}

	.sf-menu > li > a,
  .sf-menu a {
		color: #ffffff;
	}

	.sf-menu ul {
		list-style: none;
		padding-left: 10px;
	}

	.sf-menu ul li a {
		display: block;
		padding: 10px;
		font-weight: 300;
		font-size: 16px;
		text-transform: none;
	}

	.sf-menu ul li a:before {
		content: '-';
		padding-right: 5px;
	}

	.sf-menu ul ul li a:before {
		content: '--';
		padding-right: 5px;
	}
  /* arrows (for all except IE7) */
	.sf-arrows .sf-with-ul {
		padding-right: 3.5em;
	}
  /* styling for both css and generated arrows */
	.sf-arrows .sf-with-ul:after {
		font-family: 'FontAwesome';
		content: '\f107';
		position: absolute;
		right: 30px;
		height: 0;
		width: 0;
		font-size: 20px;
	}
}

/* Mega Menu */
@media screen and (min-width: 992px) {
	.mainmenu_wrapper ul .mega-menu .mega-menu-col {
		padding: 0;
	}

	.mainmenu_wrapper ul .mega-menu .mega-menu-col a {
		padding-right: 30px;
		padding-left: 30px;
	}

	.mainmenu_wrapper ul .mega-menu ul {
		min-width: auto;
		border: none;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu {
		position: relative;
	}

	.mainmenu_wrapper .mega-menu {
		display: none;
		position: absolute;
		width: 940px;
		top: 100%;
		z-index: 10;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu:hover .mega-menu {
		display: block;
	}
  /* row */
	.mainmenu_wrapper .mega-menu-row {
		position: static;
		display: table;
		table-layout: fixed;
	}

	.mainmenu_wrapper .mega-menu-row ul {
		position: static;
		display: block;
		border-top: none;
	}
  /* column */
	.mainmenu_wrapper .mega-menu-col {
		display: table-cell;
		width: 2000px;
		padding: 0 20px;
		border-left: 1px solid #f2f2f2;
    /* overrides from settings / styling / mega-menu */
	}

	.mainmenu_wrapper .mega-menu-col > a {
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 700;
	}

	.mainmenu_wrapper .mega-menu-col:first-child {
		border-left: none;
	}

	.mainmenu_wrapper ul ul .mega-menu {
		width: auto;
		top: -20px;
		margin-left: 1px;
		display: block;
		width: auto;
	}

	.mainmenu_wrapper ul ul .mega-menu li {
		display: block;
		width: auto;
		border: none;
	}
}

@media screen and (min-width: 1200px) {
	.mainmenu_wrapper .mega-menu {
		width: 1140px;
	}

	.mainmenu_wrapper ul .mega-menu ul {
		min-width: 10em;
	}
}

@media screen and (max-width: 991px) {
	.mainmenu_wrapper .mega-menu {
		position: static;
	}
}

/*
**Subpages
*/
.edit-link {
	text-align: right;
	display: block;
}

/*404*/
.not_found {
	position: relative;
	font-size: 96px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 0;
}

.not_found + h3 {
	font-size: 30px;
	margin: 5px 0 70px;
}

.not_found + h3 + p {
	margin: 0 0 15px;
}

.not_found + h3 + p + .widget_search {
	max-width: 340px;
	margin: auto;
}

/*Blog*/
article iframe {
	margin-bottom: -8px;
}

.entry-header {
	margin-bottom: 20px;
}

.entry-header:last-child {
	margin-bottom: 0;
}

.entry-title {
	line-height: 1.2;
}

.entry-meta + .entry-title,
.catogories-links + .entry-title {
	margin-top: 5px;
}

.round_button + .entry-title {
	margin-top: 0;
}

.entry-title + .entry-meta {
	margin-top: 0;
}

.entry-title:last-child {
	margin-bottom: 0;
}

h1.entry-title {
	font-size: 28px;
}

@media (min-width: 992px) {
	h1.entry-title {
		font-size: 36px;
	}
}

.entry-meta + h1.entry-title {
	margin-top: 5px;
}

h3.entry-title {
	font-size: 28px;
	margin-bottom: 0.5em;
}

@media (min-width: 1600px) {
	h3.entry-title {
		font-size: 36px;
	}
}

h4.entry-title {
	font-size: 24px;
}

.entry-meta {
	font-size: 14px;
	font-weight: 400;
}

.entry-title + .entry-meta {
	margin-top: -5px;
}

footer.entry-footer {
	margin-top: 25px;
}

article.post + article.post,
.search article + article,
.archive article + article {
	margin-top: 60px;
}

article.post + article.post.topmargin_30,
.search article + article.topmargin_30,
.archive article + article.topmargin_30 {
	margin-top: 30px;
}

/* mosaic blog */
.mosaic-post p {
	margin-bottom: 0;
}

.mosaic-post .post-social-links {
	margin: -1px;
	padding: 18px 40px 17px;
}

.entry-title + .entry-date {
	margin-bottom: 15px;
}

.entry-tags {
	margin-top: 30px;
}

.entry-tags .theme_button {
	margin-right: 6px;
}

.entry-tags span + span {
	margin-left: 14px;
}

.entry-tags i {
	font-size: 14px;
	margin-right: 5px;
}

.entry-content:before,
.entry-content:after,
.comment-meta:before,
.comment-meta:after {
	display: block;
	clear: both;
	content: '';
	width: 0;
	height: 0;
}

.entry-thumbnail {
	position: relative;
}

.entry-thumbnail .entry-thumbnail-carousel {
	margin-bottom: 0;
}

.entry-thumbnail .entry-thumbnail-carousel .owl-dots {
	position: absolute;
	bottom: 40px;
	text-align: center;
	left: 0;
	right: 0;
}

.entry-thumbnail[class*='col-'] {
	margin-bottom: 0;
}

/* additional meta at the bottom of entry-title */
.post-adds {
	font-size: 12px;
	margin: 0 0 30px;
	text-transform: uppercase;
	font-weight: 400;
}

.post-adds span, .post-adds a {
	display: inline-block;
}

.post-adds .theme_button,
.post-adds .like_button {
	margin: 0;
	padding: 0;
	width: 40px;
	line-height: 40px;
	border-radius: 0;
	text-align: center;
}

.post-adds .theme_button i,
.post-adds .like_button i {
	line-height: 1;
}

.post-adds .like_button {
	margin-left: -3px;
	border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.post-adds .votes_count,
.post-adds .views-count,
.post-adds .comments-link {
	padding-left: 5px;
	padding-right: 5px;
}

.post-adds .views-count {
	line-height: 40px;
	padding: 0 10px;
}

.post-adds .like_button {
	margin-left: -2px;
}

@media (min-width: 992px) {
	.post-adds .theme_button,
  .post-adds .like_button {
		width: 60px;
		line-height: 60px;
	}

	.post-adds .votes_count,
  .post-adds .views-count,
  .post-adds .comments-link {
		padding-left: 20px;
		padding-right: 20px;
	}

	.post-adds .views-count {
		line-height: 60px;
		padding: 0 30px;
	}
}

.post-adds .post-adds .dropdown-menu {
	margin: 5px 0 0;
	padding: 0;
	border: none;
	background-color: transparent;
}

/* share buttons */
@media (min-width: 500px) {
	.share_buttons {
		min-width: 320px;
	}
}

.item-title + .categories-links {
	display: block;
	margin: 0 0 40px;
}

@media (min-width: 1200px) {
	.item-title + .categories-links {
		margin-top: -43px;
	}
}

.categories-links a,
.categories-links span {
	margin-right: 6px;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
}

.categories-links a:last-child,
.categories-links span:last-child {
	margin-right: 0;
}

p.categories-links {
	margin-bottom: 7px;
}

.item-button {
	margin-top: 40px;
}

.st_facebook_hcount,
.st_twitter_hcount,
.st_googleplus_hcount {
	position: relative;
	text-align: right;
}

.st_facebook_hcount span,
.st_twitter_hcount span,
.st_googleplus_hcount span {
	-ms-box-sizing: content-box;
	box-sizing: content-box;
}

/*single post*/
.single-post article > .entry-thumbnail {
	margin-bottom: 40px;
}

.single-post .video-entry-thumbnail .entry-thumbnail {
	margin-bottom: 0;
}

.author-meta {
	margin-top: 60px;
}

/*comments*/
.comments-area h3 {
	margin-bottom: 30px;
	font-size: 24px;
}

@media (min-width: 1200px) {
	.comments-area h3 {
		font-size: 30px;
	}
}

.comments-area li.comment {
	list-style: none;
}

.comments-area .media {
	position: relative;
}

.comment .comment-author {
	display: inline-block;
	vertical-align: top;
	padding-right: 10px;
}

.comment .comment-author img {
	border-radius: 50%;
	max-width: 70px;
}

.comment .comment-body {
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin-left: -5px;
	width: calc(100% - 60px);
}

@media (min-width: 992px) {
	.comment .comment-author {
		padding-right: 20px;
	}

	.comment .comment-body {
		width: calc(100% - 90px);
	}
}

.comment-list {
	margin: 0;
	padding: 0;
	position: relative;
	list-style: none;
}

.comment-list ol {
	padding-left: 25px;
}

.comment-list li.comment {
	margin-top: 20px;
	margin-bottom: 20px;
}

@media (min-width: 992px) {
	.comment-list ol {
		padding-left: 50px;
	}
}

.comment-meta {
	margin-bottom: 0;
	padding: 10px 30px 15px 0;
}

.comment-meta .comment-metadata {
	display: block;
	margin: 7px 0 10px;
}

.comment-meta .comment-date {
	display: block;
	line-height: 1;
	font-size: 14px;
}

.comment-meta .author_url {
	font-weight: 400;
}

@media (min-width: 768px) {
	.comment-meta .author_url {
		font-size: 20px;
	}
}

.reply a {
	color: #323232;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-weight: 600;
}

.reply a i {
	padding-left: 5px;
}

.reply a {
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 18px;
	text-align: center;
	opacity: 0.5;
}

.reply a:hover {
	opacity: 1;
}

.comments-wrapper {
	margin-top: 60px;
}

.comment-respond h3 {
	font-size: 24px;
	margin: 0 0 25px;
}

@media (min-width: 1200px) {
	.comment-respond h3 {
		font-size: 30px;
	}
}

.comment-respond p {
	margin-bottom: 10px;
	position: relative;
}

.comment-list + .comment-respond,
.comment + .comment-respond {
	margin-top: 30px;
}

.comment-form.muted_background {
	padding: 30px 30px 20px;
}

.comment-form p {
	margin-bottom: 0;
}

.comment-form label[for] {
	display: none;
}

.comment-form .theme_button {
	margin-right: 10px;
}

@media (max-width: 500px) {
	.comment-respond i.pull-left {
		display: none;
	}

	.comment-respond .comment-form {
		margin-left: 0;
	}
}

.comment-navigation {
	margin-top: 40px;
}

.comment-navigation .pagination > li > a,
.comment-navigation .pagination > li > span {
	padding: 5px 10px;
}

.page-nav {
	margin-top: 60px;
}

.page-nav h4 {
	margin-top: 10px;
	min-height: 2.6em;
}

.page-links > *:not(:first-child) {
	font-size: 18px;
	padding: 0 5px;
}

.post-password-form input[type="password"].form-control {
	border-color: rgba(0, 0, 0, 0.1);
}

.post-password-form .theme_button {
	padding: 13px 20px 13px;
}

#timetable_filter {
	padding: 0;
}

/* gallery */
.page_content + .page_portfolio {
	margin-top: -150px;
}

#isotope_filters,
.filters {
	text-align: center;
	margin-bottom: 40px;
	margin-right: -15px;
	margin-left: -15px;
	padding: 0;
	list-style: none;
}

.content-justify #isotope_filters, .content-justify
.filters {
	text-align: left;
}

#isotope_filters.bottommargin_30,
.filters.bottommargin_30 {
	margin-bottom: 30px;
}

#isotope_filters li,
.filters li {
	display: inline-block;
	margin: 0;
	padding: 0;
}

#isotope_filters a,
.filters a {
	position: relative;
	display: inline-block;
	padding: 9px 15px;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	color: #323232;
}

#isotope_filters a.selected,
.filters a.selected {
	color: #0074ba;
}

#isotope_filters a:hover,
.filters a:hover {
	color: #0074ba;
}
.pb50{
	padding-bottom: 50px;
}
#isotope_filters a:hover:after,
.filters a:hover:after {
	opacity: 1;
	-webkit-transform: translateY(0) scaleX(1);
	transform: translateY(0) scaleX(1);
}

@media (min-width: 992px) {
	#isotope_filters a,
  .filters a {
		padding: 9px 13px;
	}
}

.filters.divided-content > *:before,
.filters.divided-content > *:last-child:after {
	position: absolute;
	left: -2px;
	top: 50%;
	margin: -4px 0 0 0;
}

.filters + .isotope_container {
	margin-top: 30px;
}

.gallery-item {
	overflow: hidden;
	position: relative;
}

.gallery-item .item-content h4 {
	margin-bottom: 0;
	font-size: 14px;
	z-index: 5;
	transition: all 0.15s ease-in-out;
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.gallery-item:hover .item-content h4 {
	-webkit-transform: scale(1);
	transform: scale(1);
	filter: alpha(opacity=100);
	opacity: 1;
}

.gallery-title-item + .item-title {
	padding: 25px 0 40px;
}

.gallery-title-item + .item-title h3 {
	font-size: 30px;
}

.gallery-title-item + .item-title .categories-links + h3 {
	margin-top: -5px;
}

i.loadmore_spinner {
	font-size: 30px;
	opacity: 0.3;
	margin: 10px 0 20px;
}

/* gallery single */
.gallery-single-title {
	margin-bottom: 30px;
	font-size: 40px;
}

.gallery-single-title + .block-header {
	margin-bottom: 40px;
}

.share-gallery-single {
	margin: 40px 0 16px;
}

.items-nav {
	padding: 30px 23px;
}

.items-nav[class*='display_table_'] {
	margin-top: 40px;
}

.items-nav h4 {
	margin: -5px 0 0;
}

.items-nav .media {
	position: relative;
	padding: 10px 0;
}

.items-nav .next-item img {
	max-width: none;
	padding: 0 30px 0 0;
}

.items-nav .next-item i {
	right: 0;
}

.items-nav .prev-item img {
	max-width: none;
	padding: 0 0 0 30px;
}

.items-nav a {
	position: relative;
}

.items-nav a i {
	top: 50%;
	margin-top: -14px;
	font-size: 20px;
}

.items-nav a i.position-absolute {
	position: absolute;
}

.loop-pagination {
	margin-top: 60px;
}

.muted_background .loop-pagination {
	margin-top: 0;
}

.owl-carousel.related-photos-carousel {
	margin-bottom: 0;
}

/* post formats */
.format-status {
	line-height: 24px;
}

.format-status .entry-date + .entry-title {
	margin-top: 20px;
}

.format-status .avatar {
	max-width: 100px;
	border-radius: 50%;
}

.format-status .entry-meta {
	margin-top: 20px;
}

.format-quote blockquote {
	margin-bottom: 0;
}

.format-chat .entry-content p {
	padding: 7px 0;
	margin-bottom: 0;
}

.format-chat .entry-content p strong {
	font-weight: 400;
}

.format-chat .entry-content p:nth-child(2n) {
	background-color: #fafafa;
	padding-left: 15px;
}

.format-chat.with_background .entry-content p:nth-child(2n) {
	background-color: #ffffff;
}

.format-gallery .flex-control-paging li a {
	border-color: #ffffff;
}

.format-gallery .flex-control-paging li a:hover,
.format-gallery .flex-control-paging li a.flex-active {
	background-color: #000;
}

/*
isotope portfolio and gallery post
*/
.gallery-item {
	text-align: center;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	width: 50%;
}

.gallery-columns-3 .gallery-item {
	width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	width: 25%;
}

.gallery-columns-5 .gallery-item {
	width: 20%;
}

.gallery-columns-6 .gallery-item {
	width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	width: 11.11%;
}

.gallery-icon img {
	display: block;
	margin: 0 auto;
	width: 100%;
}

.gallery-caption {
	display: block;
	padding: 0.5em;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

@media (max-width: 600px) {
	.gallery-columns-4 .gallery-item,
  .gallery-columns-5 .gallery-item,
  .gallery-columns-6 .gallery-item,
  .gallery-columns-7 .gallery-item,
  .gallery-columns-8 .gallery-item,
  .gallery-columns-9 .gallery-item {
		width: 33%;
	}

	.gallery-columns-4 .gallery-caption,
  .gallery-columns-5 .gallery-caption,
  .gallery-columns-6 .gallery-caption,
  .gallery-columns-7 .gallery-caption,
  .gallery-columns-8 .gallery-caption,
  .gallery-columns-9 .gallery-caption {
		display: none;
	}
}

.isotope_container {
	transition: height 0.2s ease-in-out 0s;
}

.single-post .isotope_container {
	margin: 20px 0;
	clear: both;
}

/*
** Widgets
*/
/* common widgets styles */
aside > div + div {
	margin-top: 60px;
}

aside .widget + .widget {
	margin-top: 55px;
}

.widget-theme-wrapper.with_background, .widget-theme-wrapper.muted_background, .widget-theme-wrapper.cs, .widget-theme-wrapper.ds, .widget-theme-wrapper.ls {
	padding: 35px;
}

/* affix-aside */
.affix-aside {
	position: relative;
}

.affix-aside.affix {
	position: fixed;
	margin-top: -30px;
}

@media (max-width: 767px) {
	.affix-aside, .affix-aside.affix, .affix-aside.affix-bottom {
		position: static;
		width: inherit !important;
	}
}

.widget > h3,
.widget-title {
	position: relative;
	font-size: 24px;
	margin-bottom: 25px;
}

.page_footer .widget > h3, .page_footer
.widget-title {
	font-size: 18px;
	text-transform: uppercase;
	margin-bottom: 25px;
}

.widget:before, .widget:after {
	display: block;
	content: '';
	clear: both;
}

.widget ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.widget ul ul li {
	padding-left: 20px;
}

.widget ul.list2 {
	padding-left: 20px;
}

.widget ul.list2.checklist {
	padding-left: 30px;
}

.widget li {
	position: relative;
}

.widget .list-unstyled li {
	padding-top: 3px;
	padding-bottom: 3px;
}

.widget .list-unstyled li:first-child {
	padding-top: 0;
}

.widget select {
	width: 100%;
	max-width: 100%;
}

.page_footer .row > .widget {
	max-width: 400px;
}

.widget_nav_menu li,
.widget_archive li,
.widget_meta li,
.widget_pages li {
	padding-top: 5px;
	padding-bottom: 5px;
}

/*
widget_search,
widget_mailchimp
*/
.widget_mailchimp form + p {
	padding-top: 10px;
}

.widget_search .form-inline,
.widget_product_search form,
.widget_mailchimp .form-inline {
	position: relative;
}

.widget_search .form-inline .form-control,
.widget_product_search form .form-control,
.widget_mailchimp .form-inline .form-control {
	width: 100%;
	margin: 0;
}

.widget_search .form-inline label,
.widget_product_search form label,
.widget_mailchimp .form-inline label {
	display: block;
}

.widget_search .form-inline label[for],
.widget_product_search form label[for],
.widget_mailchimp .form-inline label[for] {
	display: none;
}

.widget_search .form-inline .form-group,
.widget_mailchimp .form-inline .form-group {
	display: inline-block;
	vertical-align: top;
	position: relative;
}

.widget_search [type="text"],
.widget_search [type="search"],
.widget_product_search [type="search"],
.widget_mailchimp [type="email"] {
	padding-right: 50px;
	width: 100%;
}

.page_topline .widget_search [type="text"],
.page_header .widget_search [type="text"],
.page_toplogo .widget_search [type="text"], .page_topline
.widget_search [type="search"],
.page_header
.widget_search [type="search"],
.page_toplogo
.widget_search [type="search"], .page_topline
.widget_product_search [type="search"],
.page_header
.widget_product_search [type="search"],
.page_toplogo
.widget_product_search [type="search"], .page_topline
.widget_mailchimp [type="email"],
.page_header
.widget_mailchimp [type="email"],
.page_toplogo
.widget_mailchimp [type="email"] {
	min-width: 200px;
}

@media (min-width: 1800px) {
	.page_topline .widget_search [type="text"],
  .page_header .widget_search [type="text"],
  .page_toplogo .widget_search [type="text"], .page_topline
  .widget_search [type="search"],
  .page_header
  .widget_search [type="search"],
  .page_toplogo
  .widget_search [type="search"], .page_topline
  .widget_product_search [type="search"],
  .page_header
  .widget_product_search [type="search"],
  .page_toplogo
  .widget_product_search [type="search"], .page_topline
  .widget_mailchimp [type="email"],
  .page_header
  .widget_mailchimp [type="email"],
  .page_toplogo
  .widget_mailchimp [type="email"] {
		min-width: 300px;
	}
}

.ds .widget_search [type="text"], .ds
.widget_search [type="search"], .ds
.widget_product_search [type="search"], .ds
.widget_mailchimp [type="email"] {
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.1);
}

.widget_search form,
.widget_mailchimp form {
	position: relative;
}

.widget_search .form-group + .theme_button,
.widget_product_search [type="submit"],
.widget_mailchimp .form-group + .theme_button,
.widget_mc4wp_form_widget .form-group + .theme_button {
	font-size: 14px;
	letter-spacing: 0;
	padding: 0;
	margin: 0;
	position: absolute;
	right: 5px;
	top: 5px;
	height: 40px;
	width: 44px;
	line-height: 40px;
	border: none;
	background-color: #f2f2f2;
	box-shadow: none;
	border-radius: 5px;
	color: #0074ba;
}

.ds .widget_search .form-group + .theme_button, .ds
.widget_product_search [type="submit"], .ds
.widget_mailchimp .form-group + .theme_button, .ds
.widget_mc4wp_form_widget .form-group + .theme_button {
	right: 3px;
	top: 3px;
	height: 44px;
	width: 46px;
	line-height: 44px;
	color: #0074ba;
	background-color: #323232;
}

.ds .widget_search .form-group + .theme_button:hover, .ds .widget_search .form-group + .theme_button:focus, .ds .widget_search .form-group + .theme_button:active, .ds
.widget_product_search [type="submit"]:hover, .ds
.widget_product_search [type="submit"]:focus, .ds
.widget_product_search [type="submit"]:active, .ds
.widget_mailchimp .form-group + .theme_button:hover, .ds
.widget_mailchimp .form-group + .theme_button:focus, .ds
.widget_mailchimp .form-group + .theme_button:active, .ds
.widget_mc4wp_form_widget .form-group + .theme_button:hover, .ds
.widget_mc4wp_form_widget .form-group + .theme_button:focus, .ds
.widget_mc4wp_form_widget .form-group + .theme_button:active {
	color: #ffffff;
}

.ds.ms .widget_search .form-group + .theme_button, .ds.ms
.widget_product_search [type="submit"], .ds.ms
.widget_mailchimp .form-group + .theme_button, .ds.ms
.widget_mc4wp_form_widget .form-group + .theme_button {
	background-color: #272727;
}

.widget_search .form-group + .theme_button:hover, .widget_search .form-group + .theme_button:focus, .widget_search .form-group + .theme_button:active,
.widget_product_search [type="submit"]:hover,
.widget_product_search [type="submit"]:focus,
.widget_product_search [type="submit"]:active,
.widget_mailchimp .form-group + .theme_button:hover,
.widget_mailchimp .form-group + .theme_button:focus,
.widget_mailchimp .form-group + .theme_button:active,
.widget_mc4wp_form_widget .form-group + .theme_button:hover,
.widget_mc4wp_form_widget .form-group + .theme_button:focus,
.widget_mc4wp_form_widget .form-group + .theme_button:active {
	color: #323232;
}

/*
widget_recent_entries
widget_recent_comments
widget_archive
widget_categories
widget_meta
widget_nav_menu
widget_pages
*/
.widget_recent_comments p {
	margin-bottom: 5px;
}

.widget_recent_comments li {
	padding: 10px 0;
}

.widget_recent_comments li + li {
	border-top: 1px solid #f2f2f2;
}

.widget_recent_entries h6 {
	max-width: 70%;
	line-height: 23px;
}

.ds .widget_recent_entries h6 a {
	color: rgba(255, 255, 255, 0.8);
}

.text-center .widget_recent_entries h6, .widget_recent_entries.text-center h6 {
	margin-right: auto;
	margin-left: auto;
}

.widget_recent_entries .lineheight_thin {
	line-height: 20px;
}

.widget_recent_entries li + li {
	margin-top: 20px;
}

.widget_popular_entries li {
	margin-top: 0;
}

.widget_recent_entries > ul > li:first-child,
.widget_recent_comments > ul > li:first-child,
.widget_archive > ul > li:first-child,
.widget_categories > ul > li:first-child,
.widget_meta > ul > li:first-child,
.widget_popular_entries > ul > li:first-child,
.widget_nav_menu > ul > li:first-child,
.widget_pages > ul > li:first-child {
	border-top: none;
}

.widget_popular_entries > ul > li:first-child {
	margin-top: 0;
}

.widget_categories li.active a {
	color: #323232;
}

.widget_archive a,
.widget_meta a,
.widget_nav_menu a,
.widget_pages a {
	display: inline-block;
	position: relative;
	padding-left: 20px;
}

.widget_archive li:first-child a,
.widget_categories li:first-child a,
.widget_meta li:first-child a,
.widget_nav_menu li:first-child a,
.widget_pages li:first-child a {
	padding-top: 0;
}

.widget_archive a:before,
.widget_meta a:before,
.widget_nav_menu a:before,
.widget_pages a:before {
	font-family: 'FontAwesome';
	content: "\f0da";
	font-size: 16px;
	color: #0074ba;
	position: absolute;
	left: 0;
}

.widget_nav_menu ul ul li:first-child a {
	padding-top: 13px;
}

.widget_nav_menu a i {
	padding-right: 7px;
}

@media (min-width: 768px) {
	.page_copyright .widget_nav_menu li, .page_topline .widget_nav_menu li {
		display: inline-block;
		position: relative;
		border: none;
		padding-right: 5px;
	}

	.page_copyright .widget_nav_menu li:before, .page_topline .widget_nav_menu li:before {
		content: '';
		position: absolute;
		padding: 0;
		left: -5px;
		top: 5px;
		bottom: 5px;
		width: 1px;
		background-color: rgba(0, 0, 0, 0.05);
		opacity: 0.3;
		display: block;
	}

	.page_copyright .widget_nav_menu li:first-child:before, .page_topline .widget_nav_menu li:first-child:before {
		display: none;
	}

	.page_copyright .widget_nav_menu a, .page_topline .widget_nav_menu a {
		padding: 0 10px;
	}

	.page_copyright .widget_nav_menu a:before, .page_topline .widget_nav_menu a:before {
		display: none;
	}
}

/* Popular Courses */
.widget_events .media-left img {
	max-width: 70px;
}

.widget_events .media h4 {
	font-size: 14px;
	line-height: 1.4;
	margin-bottom: 5px;
}

/* widget_tag_cloud */
.widget_tag_cloud a {
	padding: 4px 10px 4px;
	margin: 0 2px 4px 0;
	font-size: 12px !important;
	text-transform: uppercase;
	text-align: center;
	font-weight: 400;
	line-height: 18px;
	color: #ffffff;
	background-color: #0074ba;
	display: inline-block;
	border-radius: 3px;
}

.widget_tag_cloud a:hover, .widget_tag_cloud a:focus, .widget_tag_cloud a:active {
	color: #323232;
}

/* widget_popular_entries */
.widget_popular_entries h4 {
	margin-bottom: 0;
	font-size: 16px;
}

.widget_popular_entries p {
	font-family: 'Playfair Display', serif;
	line-height: 20px;
	margin-bottom: 0;
	text-transform: uppercase;
}

.widget_popular_entries .media-body {
	overflow: visible;
}

.widget_popular_entries .media-left {
	padding-right: 20px;
}

.widget_popular_entries a img,
.widget_popular_entries .media-object {
	max-width: 70px;
}

@media (min-width: 768px) and (max-width: 1199px) {
	.widget_popular_entries .media-object {
		max-width: 40px;
	}
}

.widget_popular_entries li:first-child {
	padding-top: 0;
}

.widget_popular_entries li:last-child {
	padding-bottom: 0;
}

/* recent posts widget */
.widget_recent_posts h4 {
	font-size: 14px;
	line-height: 1.2;
	margin-bottom: 0;
}

.widget_recent_posts .media-left img {
	max-width: 65px;
	border-radius: 50%;
}

/* widget_calendar */
#calendar_wrap {
	width: 100%;
	margin-bottom: 0;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 768px) and (max-width: 992px) {
	#calendar_wrap {
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.widget_calendar table {
	width: 100%;
	margin: 0;
	position: relative;
	text-align: center;
	background-color: transparent;
}

.widget_calendar caption {
	line-height: 1;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 20px;
	padding: 13px 0;
	text-align: center;
	color: #ffffff;
	background-color: #0074ba;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.widget_calendar thead {
	background-color: #323232;
	color: #ffffff;
}

.widget_calendar thead th {
	font-weight: 700;
	text-transform: uppercase;
}

.widget_calendar thead th:first-child {
	padding-left: 15px;
}

.widget_calendar thead th:last-child {
	padding-right: 15px;
}

.widget_calendar tbody {
	border-top: none;
	font-weight: 300;
	background-color: #fafafa;
}

.widget_calendar tbody tr:first-child td {
	padding-top: 30px;
}

.widget_calendar tbody tr:last-child td {
	padding-bottom: 30px;
}

.widget_calendar tbody td {
	border: none;
	padding: 5px 4px;
	position: relative;
}

.widget_calendar tbody td:first-child {
	padding-left: 15px;
}

.widget_calendar tbody td:last-child {
	padding-right: 15px;
}

.widget_calendar tbody td a {
	font-weight: 700;
	color: #0074ba;
}

.widget_calendar tbody td a:hover {
	color: #bee27b;
}

.widget_calendar th {
	text-align: center;
	border: 0;
	color: inherit;
}

.widget_calendar td {
	border: 0;
}

.widget_calendar tfoot {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

.widget_calendar tfoot td {
	position: absolute;
	top: 0;
}

.widget_calendar tfoot td a {
	color: #ffffff;
	text-align: center;
	font-size: 0;
	width: 46px;
	height: 46px;
	line-height: 46px;
	display: inline-block;
}

.widget_calendar tfoot td a:hover {
	color: #323232;
}

.widget_calendar tfoot #prev {
	left: 0;
	padding: 0;
}

.widget_calendar tfoot #prev a:before {
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c4';
}

.widget_calendar tfoot #next {
	right: 0;
	padding: 0;
}

.widget_calendar tfoot #next a:after {
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c5';
}

/* widget_banner */
.widget_banner {
	overflow: hidden;
	line-height: 1;
}

.widget_banner .vertical-item.content-absolute .item-content {
	padding: 20px;
}

.widget_banner .item-content span {
	display: inline-block;
	text-transform: uppercase;
	font-size: 14px;
	padding: 4px 10px;
}

.widget_banner .item-content h3 {
	margin: 0;
	font-size: 20px;
	padding: 5px;
	background-color: #323232;
}

.widget_banner .abs-link {
	z-index: 5;
}

/* widget_slider */
.widget_slider {
	position: relative;
}

.widget_slider .owl-carousel {
	margin-bottom: 0;
}

.widget_slider .owl-carousel .owl-nav {
	opacity: 1;
	top: -46px;
	right: 0;
}

.widget_slider .owl-carousel .owl-nav > div {
	width: auto;
	height: auto;
	right: 0;
	background-color: transparent;
}

.widget_slider .owl-carousel .owl-nav > div:first-child {
	left: auto;
	right: 22px;
}

.widget_slider .owl-carousel .owl-nav > div:after {
	font-size: 18px;
	color: inherit;
	color: #272727;
}

/* widget_flickr */
.widget_flickr ul {
	margin: -5px;
	font-size: 0;
}

.widget_flickr li {
	display: inline-block;
	padding: 5px;
	width: 50%;
	max-width: 180px;
}

/* widget_instagram */
.widget_instagram .instafeed {
	margin: -5px;
}

.widget_instagram .photo {
	width: 50%;
	max-width: 180px;
	padding: 5px;
	float: left;
	position: relative;
}

.widget_instagram .photo a {
	position: relative;
	display: block;
}

.widget_instagram .photo a:before {
	position: absolute;
	z-index: 1;
	transition: opacity 0.2s linear 0s;
}

.widget_instagram .photo a:before {
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(50, 50, 50, 0.8);
	opacity: 0;
}

.widget_instagram .photo a:hover:before {
	opacity: 1;
}

.widget_instagram:after {
	content: '';
	display: block;
	clear: both;
}

/* widget_twitter */
.tweet_avatar {
	float: left;
	margin-top: 4px;
}

.tweet_right {
	overflow: hidden;
	zoom: 1;
	margin-left: 60px;
}

.tweet_list li + li {
	margin-top: 16px;
	clear: both;
}

.tweet_time,
.tweet_user {
	font-size: 14px;
}

.tweet_user {
	margin-bottom: 5px;
}

.tweet_time {
	padding-top: 5px;
}

/* widget_tabs widget_theme_post_tabs */
.widget_theme_post_tabs .vertical-item {
	margin-bottom: 20px;
}

.widget_theme_post_tabs .vertical-item .item-content {
	padding-top: 10px;
}

.widget_theme_post_tabs .pull-right {
	margin-left: 10px;
}

.widget_rss li {
	margin-bottom: 15px;
}

.widget_rss li .rsswidget {
	font-size: 1.1em;
}

.widget_rss .rss-date {
	display: block;
	font-weight: 700;
}

.widget_rss cite {
	display: block;
	text-align: right;
	font-weight: 700;
}

/* access_press */
.apsc-facebook-icon {
	background-color: #3b5998;
}

.apsc-twitter-icon {
	background-color: #1dcaff;
}

.apsc-youtube-icon {
	background-color: #e52d27;
}

.apsc-soundcloud-icon {
	background-color: #ff8800;
}

.apsc-dribble-icon {
	background-color: #ea4c89;
}

.apsc-comment-icon {
	background-color: #464646;
}

.apsc-edit-icon {
	background-color: #837d7d;
}

.apsc-google-plus-icon {
	background-color: #dd4b39;
}

.apsc-instagram-icon {
	background-color: #3f729b;
}

.apsc-each-profile a {
	color: white !important;
}

.apsc-each-profile a:hover {
	color: white !important;
	text-decoration: none !important;
}

.apsc-theme-4 .apsc-each-profile {
	margin-bottom: 10px;
}

.apsc-theme-4 .social-icon {
	display: inline-block;
	position: relative;
	float: left;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-each-profile a {
	line-height: 60px;
	padding: 0 10px 0 0;
	font-size: 12px;
	text-transform: uppercase;
	display: block;
	color: #fff;
	position: relative;
	text-align: right;
}

.apsc-theme-4 .apsc-each-profile a:hover {
	opacity: 0.8;
}

.apsc-theme-4 .apsc-each-profile a .fa {
	border-right: 1px solid rgba(255, 255, 255, 0.4);
	width: 60px;
	line-height: 60px;
	height: 60px;
	vertical-align: top;
	text-align: center;
	font-size: 16px;
	display: inline-block;
	transform: none;
	-webkit-transform: none;
	-ms-transform: none;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-each-profile a:hover .fa {
	transform: none;
	-webkit-transform: snone;
	-ms-transform: none;
}

.apsc-theme-4 .media-name {
	padding: 0 0 0 10px;
	display: inline-block;
}

.apsc-theme-4 .apsc-count {
	border-radius: 0;
	color: #fff;
	display: inline-block;
	font-size: inherit;
	text-align: center;
	position: relative;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-media-type {
	display: inline-block;
	padding: 0 4px;
}

@media screen and (max-width: 479px) {
	.apsc-theme-4 .apsc-each-profile, .apsc-theme-3 .apsc-each-profile {
		float: none;
	}
}

/*
** Shortcodes
*/
/*
** Teasers
*/
/* small teaser - media bootstrap component with icon */
.small-teaser .media-body {
	line-height: 20px;
}

.media.inline-block {
	margin-top: 10px;
}

.media.inline-block.small-teaser {
	margin-top: 10px;
}

.media.inline-block.teaser:first-child {
	margin-top: 10px;
}

@media (min-width: 992px) {
	.media.topmargin_40 {
		margin-top: 80px;
	}
}

.media-left i,
.meida-right i {
	display: block;
	padding: 1px;
}

.inline-teasers-wrap {
	display: inline-block;
	margin-left: -15px;
	margin-right: -15px;
}

.inline-teasers-wrap > * {
	vertical-align: middle;
	margin: 10px 15px;
}

@media (min-width: 1200px) {
	.inline-teasers-wrap {
		margin-left: -20px;
		margin-right: -20px;
	}

	.inline-teasers-wrap > * {
		margin: 10px 20px;
	}
}

/* teaser */
.teaser {
	position: relative;
	overflow: visible;
}

.teaser h3 {
	font-size: 30px;
}

.teaser h4 {
	font-size: 24px;
}

.teaser h5 {
	font-size: 18px;
}

.teaser[class*="_bg_color"], .teaser[class*="with_"] {
	padding: 20px;
}

@media (min-width: 400px) {
	.teaser[class*="_bg_color"], .teaser[class*="with_"] {
		padding: 30px;
	}
}

@media (min-width: 1600px) {
	.teaser[class*="_bg_color"].big-padding, .teaser[class*="with_"].big-padding {
		padding: 55px;
	}
}

.teaser [class*="_bg_color"],
.teaser .with_background {
	width: 2.3em;
	height: 2.3em;
	text-align: center;
	vertical-align: bottom;
}

.teaser [class*="_bg_color"] > *,
.teaser .with_background > * {
	line-height: 2.3em;
}

.teaser .border_icon {
	width: 2em;
	height: 2em;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.05);
}

.teaser .border_icon + h3,
.teaser .border_icon + h4 {
	margin-top: 28px;
}

.teaser .border_icon i {
	line-height: 2em;
	margin-top: -1px;
}

.teaser .dashed_border {
	background-image: url(../img/round_dashed_border.png);
}

.teaser i {
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}

.teaser .media-body {
	line-height: 1.5em;
}

.teaser .media-body h3 {
	margin-bottom: 10px;
}

.teaser h3[class*="count"] {
	font-weight: 700;
	margin: 10px 0 0;
	font-size: 50px;
}

.teaser h3[class*="count"] + p {
	font-size: 18px;
}

.teaser .teaser_icon + h3[class*="count"] {
	margin-top: 20px;
}

.teaser .theme_button {
	margin: 22px 0 0;
}

.section_padding_bottom_0 .teaser {
	margin-bottom: 0;
}

.teaser .counter-background {
	position: absolute;
	right: 0;
	left: 0;
	top: 48%;
	font-size: 180px;
	font-weight: 700;
	line-height: 1;
	opacity: 0.1;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.teaser .icon-background {
	position: absolute;
	opacity: 0.1;
	top: 50%;
	left: 50%;
	right: 0;
	font-size: 300px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.teaser i.icon-background {
	margin-top: 0;
	margin-bottom: 0;
}

.teaser.font-icon-background-teaser {
	padding: 48px 25px 48px;
	overflow: hidden;
}

.teaser.counter-background-teaser {
	overflow: hidden;
	padding: 98px 25px 98px;
}

.teaser.counter-background-teaser .small-text {
	line-height: 1.4em;
}

.teaser.inner-border:before {
	content: '';
	position: absolute;
	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
	border: 1px solid rgba(255, 255, 255, 0.5);
}

.teaser_icon {
	display: inline-block;
	line-height: 1;
	text-align: center;
	transition: all 0.2s ease-in-out 0s;
}

.teaser_icon[class*='label-'] {
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 32px;
	vertical-align: bottom;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	color: #fff;
}

.teaser_icon + h2 {
	margin-top: 40px;
}

.teaser_icon.big_wrapper + h4 {
	margin-top: 33px;
}

.teaser_icon + p {
	margin-top: 27px;
}

.cons-width {
	width: 1em;
	text-align: center;
}

.size_big {
	font-size: 70px;
}

.size_normal {
	font-size: 48px;
}

.size_small {
	font-size: 28px;
}

/* background teaser */
.bg_teaser {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	color: #656565;
	overflow: hidden;
}

.bg_teaser:after {
	position: absolute;
	width: 100%;
	height: 1000px;
}

.bg_teaser .highlight {
	color: #fff;
}

.bg_teaser.darkgrey_bg .highlight, .bg_teaser.black_bg .highlight, .bg_teaser.darkblue_bg .highlight {
	color: #0074ba;
}

.bg_teaser h4 + h3 {
	margin-top: -14px;
	font-size: 50px;
}

.bg_teaser.rectangular .teaser_content {
	padding: 200px 30px 50px;
}

.bg_teaser.rectangular .teaser_content h3 {
	font-size: 40px;
}

.bg_teaser > img {
	display: none;
	visibility: hidden;
}

.bg_teaser .teaser_content {
	margin: 0;
	padding: 40px 40px 35px;
	z-index: 2;
}

.bg_teaser .teaser_content > .media-left {
	padding-right: 35px;
}

.bg_teaser .teaser_content.media {
	padding: 75px 40px 60px;
}

.color_bg_1:after {
	background-color: rgba(78, 187, 227, 0.8);
}

.color_bg_2:after {
	background-color: rgba(190, 226, 123, 0.8);
}

[class*="color_bg"] {
	color: #ffffff;
}

.teaser.media.after_cover {
	padding: 25px 30px;
}

.teaser.media.after_cover i {
	opacity: 0.3;
}

.teaser.media.after_cover p {
	text-transform: uppercase;
}

.darkgrey_bg:after {
	background-color: rgba(50, 50, 50, 0.6);
}

.darkgrey_bg.black:after {
	background-color: rgba(0, 0, 0, 0.6);
}

.darkblue_bg:after {
	background-color: rgba(0, 32, 43, 0.6);
}

.min_height_165 {
	min-height: 165px;
}

/*
** Testimonials
*/
@media (max-width: 600px) {
	.blockquote-big {
		font-size: 18px;
		max-width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}
}

/* testimonials-carousel */
.testimonials-carousel {
	padding: 0 0 20px;
}

.testimonials-carousel .item > p {
	font-style: italic;
	padding-left: 40px;
	margin-bottom: 40px;
	position: relative;
}

.testimonials-carousel .item > p:before {
	font-family: 'PT Sans', sans-serif;
	font-size: 100px;
	font-style: normal;
	content: '\201c';
	position: absolute;
	left: -3px;
	top: 30px;
	z-index: 10;
}

.testimonials-carousel h4 {
	margin: 0;
}

.testimonials-carousel h4 + p {
	margin-bottom: 0;
}

.testimonials-carousel .carousel-indicators {
	bottom: -16px;
	display: none;
}

.testimonials-carousel .carousel-control {
	display: none;
}

.testimonials-carousel .media + p {
	position: relative;
	padding: 10px 16px;
	background-color: #fafafa;
}

.testimonials-carousel .media:after {
	content: '';
	display: block;
	clear: both;
}

.testimonials-carousel .media + p:before,
.testimonials-carousel .media + p:after {
	content: '';
	position: absolute;
	left: 0;
	top: auto;
	bottom: -18px;
	border-style: solid;
	border-width: 18px 0 0 18px;
	border-color: rgba(0, 0, 0, 0.05) transparent transparent transparent;
}

.testimonials-carousel .media + p:after {
	left: auto;
	right: 0;
	border-style: solid;
	border-width: 18px 18px 0 0;
}

.testimonials-carousel .media-object {
	max-width: 50px;
	border-radius: 50%;
	margin-bottom: 10px;
}

.testimonials-carousel .carousel-indicators li {
	border-color: #0074ba;
}

.testimonials-carousel .carousel-indicators li.active {
	background-color: #0074ba;
}

.testimonials-control {
	font-size: 22px;
}

/* thumbnail */
.thumbnail {
	position: relative;
	text-align: center;
	border-radius: 0;
	padding: 0;
	border: none;
	overflow: hidden;
	background-color: transparent;
}

.isotope-item .thumbnail {
	margin-bottom: 30px;
}

.thumbnail h3 {
	font-size: 20px;
	margin-bottom: 2px;
}

.thumbnail h3 a {
	color: inherit;
}

.thumbnail h3 + p {
	color: #0074ba;
	margin-bottom: 10px;
}

.thumbnail .caption {
	border: 1px solid #f2f2f2;
	border-top-width: 0;
	border-radius: 0;
	color: #656565;
	background-color: transparent;
	padding: 26px 20px 26px;
}

/* price tables */
.price-table,
.price-table ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}

.price-table {
	margin: 0 auto;
	max-width: 500px;
}

.plan-name {
	padding: 35px 20px 20px;
}

.plan-name h4 {
	margin: 0;
	font-size: 30px;
}

.plan-icon {
	width: 135px;
	height: 135px;
	border-radius: 50%;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 36px;
	text-transform: uppercase;
	font-weight: 100;
	overflow: hidden;
	position: relative;
}

.plan-icon img {
	position: absolute;
	bottom: -10px;
	right: -40px;
	opacity: 0.15;
}

.price-table .plan-icon {
	margin: auto;
}

.plan-price {
	padding: 33px 0 0;
	position: relative;
	font-size: 60px;
	font-weight: 700;
	line-height: 1;
}

.plan-price span {
	vertical-align: top;
}

.plan-price span:first-child {
	display: inline-block;
	margin-left: -20px;
	position: relative;
	top: 8px;
	font-size: 36px;
	font-weight: 100;
}

.features-list {
	padding: 23px 55px;
}

.features-list li {
	padding: 12px 0;
}

.features-list li + li {
	border-top: 1px solid #f2f2f2;
	position: relative;
}

.features-list li:first-child {
	padding-top: 0;
}

.features-list .enabled:before,
.features-list .disabled:before {
	font-family: 'rt-icons-2';
	font-size: 24px;
	line-height: 1;
	position: relative;
	top: 5px;
	right: 4px;
	content: "\e116";
	padding-right: 8px;
	color: #0074ba;
}

.features-list .disabled:before {
	color: #323232;
	content: "\e117";
}

.call-to-action {
	padding: 5px 55px 65px;
}

.fw-package.price-table hr {
	margin-left: 30px;
	margin-right: 30px;
	opacity: 0.15;
}

.fw-switch-row {
	display: inline-block;
	font-size: 24px;
	position: relative;
	top: 4px;
	margin-right: 4px;
}

.fw-switch-row + .fw-default-row {
	display: inline-block;
}

.desc-col .fw-default-row {
	margin: 20px 0;
}

/* contact forms */
.contact-form {
	position: relative;
	z-index: 3;
}

.contact-form.ls {
	background-color: #fafafa;
}

.contact-form.ls.ms {
	background-color: #f7f7f7;
}

.contact-form label[for] {
	display: none;
}

.contact-form-message textarea {
	min-height: 180px;
}

.contact-form.parallax {
	padding: 50px 50px 35px;
	background-image: url(../img/parallax/map.jpg);
}

.contact-form-respond {
	display: block;
	font-weight: 800;
}

.contact-form-respond:before {
	font-family: 'rt-icons-2';
	content: "\e71a";
	padding-right: 10px;
}

.contact-form p.form-group {
	margin-bottom: 15px;
}

.contact-form-submit .theme_button {
	margin-right: 6px;
}

.contact-form.transparent-background .form-control {
	background-color: transparent;
}

.contact-email {
	font-size: 40px;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.contact-email {
		font-size: 20px;
		margin-bottom: 6px;
	}

	.contact-form.parallax {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 40px;
	}
}

/* Form in tab with absolute button */
.tab-pane .with_abs_button {
	padding-bottom: 42px;
}

.tab-pane .with_abs_button .theme_button {
	padding: 19px 20px;
	position: absolute;
	bottom: -30px;
	left: -20px;
	border-radius: 0;
	width: calc(100% + 40px);
	text-align: left;
}

@media (min-width: 400px) {
	.tab-pane .with_abs_button {
		padding-bottom: 52px;
	}

	.tab-pane .with_abs_button .theme_button {
		padding: 24px 30px;
		bottom: -40px;
		left: -30px;
		width: calc(100% + 60px);
	}
}

.wrap-forms .form-group {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.wrap-forms .form-group {
		margin-bottom: 0;
	}
}

.wrap-forms .form-group p {
	margin: 10px 0 0;
}

.wrap-forms .form-group textarea {
	min-height: 150px;
}

.has-placeholder label {
	display: none;
}

#messages_modal .fw-messages-wrap {
	position: absolute;
	left: 50%;
	top: 10%;
	margin-left: -150px;
	width: 300px;
}

#messages_modal ul {
	margin-bottom: 0;
}

/* partners carousel */
.owl-carousel.partners-carousel a,
.partner-link {
	display: block;
	text-align: center;
}

.owl-carousel.partners-carousel a img,
.partner-link img {
	opacity: 0.35;
}

.owl-carousel.partners-carousel a:hover img,
.partner-link:hover img {
	opacity: 1;
}

.owl-carousel.partners-carousel .center a img,
.center .partner-link img {
	opacity: 1;
}

/* Gallery Owl Carousel */
@media (min-width: 1200px) {
	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div {
		width: 12.5%;
		top: 30px;
		bottom: 30px;
		height: auto;
		-webkit-transform: none;
		transform: none;
		font-size: 30px;
		text-transform: uppercase;
		color: #ffffff;
		font-family: 'Playfair Display', serif;
		font-weight: 700;
		line-height: 100%;
	}

	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div:after {
		display: none;
	}

	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div span {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div:hover {
		color: #bee27b;
	}
}

/*Stars*/
.star-rating, p.stars a {
	font-weight: 400;
	height: 1em;
}

.star-rating:before,
.star-rating span:before {
	content: "\f005\f005\f005\f005\f005";
	top: 0;
	left: 0;
	letter-spacing: 0.25em;
	font-size: 12px;
}

.star-rating span:before {
	content: "\f005\f005\f005\f005\f005";
}

.star-rating {
	overflow: hidden;
	position: relative;
	line-height: 1;
	font-size: 12px;
	width: 5.7em;
	font-family: FontAwesome;
	display: inline-block;
	vertical-align: middle;
}

.star-rating:before {
	float: left;
	position: absolute;
}

.star-rating span {
	overflow: hidden;
	float: left;
	top: 0;
	left: 0;
	position: absolute;
}

.star-rating.right-align span {
	right: 0;
	left: auto;
}

.star-rating span:before {
	color: #009d83;
}

.star-rating:before {
	position: absolute;
	color: #939393;
}

p.stars a:before,
p.stars a:hover ~ a:before {
	color: rgba(101, 101, 101, 0.5);
	content: "\f005";
}

p.stars {
	display: inline-block;
	margin: 0 0 10px;
	line-height: 1;
}

p.stars a {
	display: inline-block;
	position: relative;
	width: 1em;
	text-indent: -999em;
	margin-right: 1px;
}

p.stars a:before {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 1em;
	height: 1em;
	line-height: 1;
	font-family: FontAwesome;
	text-indent: 0;
}

p.stars.selected a.active:before, p.stars:hover a:before {
	content: "\f005";
	color: #009d83;
	opacity: 1;
}

p.stars.selected a.active ~ a:before {
	content: "\f005";
	color: #656565;
	opacity: .75;
}

p.stars.selected a:not(.active):before {
	content: "\f005";
	color: #009d83;
	opacity: 1;
}

.comment-form-rating {
	margin-top: 20px;
	margin-bottom: 10px;
}

.comment-form-rating .select-group {
	display: none;
}

/*
** Social Icons
*/
/* layout */
a[class*='socicon-']:before {
	font-family: 'socicon';
	font-size: 14px;
	line-height: 1;
}

a.social-icon {
	font-size: 0;
	line-height: 0;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
}

a.social-icon span {
	opacity: 0.7;
	display: block;
	font-size: 14px;
	text-transform: uppercase;
}

a.social-icon span:first-child {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon [class*="rt-icon"] + span {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon + a.social-icon,
.tooltip + a.social-icon {
	margin-left: 18px;
}

a.social-icon.bg-icon + a.social-icon.bg-icon,
.tooltip + a.social-icon.bg-icon,
a.social-icon.color-bg-icon + a.social-icon.color-bg-icon,
.tooltip + a.social-icon.color-bg-icon,
a.social-icon.border-icon + a.social-icon.border-icon,
.tooltip + a.social-icon.border-icon {
	margin-left: 8px;
}

a.social-icon.block-icon {
	width: 100%;
}

a[class*='socicon-'].rounded-icon {
	border-radius: 50%;
}

a.big-icon {
	width: 112px;
	height: 112px;
	padding-top: 20px;
	padding-bottom: 20px;
	overflow: hidden;
}

a.big-icon[class*='socicon-']:before {
	font-size: 32px;
}

a.big-icon [class*='rt-icon']:before {
	font-size: 32px;
}

/* corporate colors */
a.color-icon.socicon-twitter {
	color: #00abef;
}

a.color-icon.socicon-facebook {
	color: #507cbe;
}

a.color-icon.socicon-google {
	color: #d93e2d;
}

a.color-icon.socicon-pinterest {
	color: #c92619;
}

a.color-icon.socicon-foursquare {
	color: #23b7e0;
}

a.color-icon.socicon-yahoo {
	color: #6e2a85;
}

a.color-icon.socicon-skype {
	color: #28abe3;
}

a.color-icon.socicon-yelp {
	color: #c83218;
}

a.color-icon.socicon-feedburner {
	color: #ffcc00;
}

a.color-icon.socicon-linkedin {
	color: #3371b7;
}

a.color-icon.socicon-viadeo {
	color: #e4a000;
}

a.color-icon.socicon-xing {
	color: #005a60;
}

a.color-icon.socicon-myspace {
	color: #323232;
}

a.color-icon.socicon-soundcloud {
	color: #fe3801;
}

a.color-icon.socicon-spotify {
	color: #7bb342;
}

a.color-icon.socicon-grooveshark {
	color: #f67e03;
}

a.color-icon.socicon-lastfm {
	color: #d41316;
}

a.color-icon.socicon-youtube {
	color: #d12121;
}

a.color-icon.socicon-vimeo {
	color: #51b5e7;
}

a.color-icon.socicon-dailymotion {
	color: #004e72;
}

a.color-icon.socicon-vine {
	color: #00b389;
}

a.color-icon.socicon-flickr {
	color: #0062dd;
}

a.color-icon.socicon-500px {
	color: #58a9de;
}

a.color-icon.socicon-instagram {
	color: #9c7c6e;
}

a.color-icon.socicon-wordpress {
	color: #464646;
}

a.color-icon.socicon-tumblr {
	color: #45556c;
}

a.color-icon.socicon-blogger {
	color: #ec661c;
}

a.color-icon.socicon-technorati {
	color: #5cb030;
}

a.color-icon.socicon-reddit {
	color: #e74a1e;
}

a.color-icon.socicon-dribbble {
	color: #e84d88;
}

a.color-icon.socicon-stumbleupon {
	color: #e64011;
}

a.color-icon.socicon-digg {
	color: #1d1d1b;
}

a.color-icon.socicon-envato {
	color: #597c3a;
}

a.color-icon.socicon-behance {
	color: #007eff;
}

a.color-icon.socicon-delicious {
	color: #31a9ff;
}

a.color-icon.socicon-deviantart {
	color: #c5d200;
}

a.color-icon.socicon-forrst {
	color: #5b9a68;
}

a.color-icon.socicon-play {
	color: #5acdbd;
}

a.color-icon.socicon-zerply {
	color: #9dbc7a;
}

a.color-icon.socicon-wikipedia {
	color: #000000;
}

a.color-icon.socicon-apple {
	color: #b9bfc1;
}

a.color-icon.socicon-flattr {
	color: #f67c1a;
}

a.color-icon.socicon-github {
	color: #221e1b;
}

a.color-icon.socicon-chimein {
	color: #888688;
}

a.color-icon.socicon-friendfeed {
	color: #2f72c4;
}

a.color-icon.socicon-newsvine {
	color: #075b2f;
}

a.color-icon.socicon-identica {
	color: #000000;
}

a.color-icon.socicon-bebo {
	color: #ef1011;
}

a.color-icon.socicon-zynga {
	color: #dc0606;
}

a.color-icon.socicon-steam {
	color: #8f8d8a;
}

a.color-icon.socicon-xbox {
	color: #92c83e;
}

a.color-icon.socicon-windows {
	color: #00bdf6;
}

a.color-icon.socicon-outlook {
	color: #0072c6;
}

a.color-icon.socicon-coderwall {
	color: #3e8dcc;
}

a.color-icon.socicon-tripadvisor {
	color: #000000;
}

a.color-icon.socicon-appnet {
	color: #494949;
}

a.color-icon.socicon-goodreads {
	color: #1abc9c;
}

a.color-icon.socicon-tripit {
	color: #1982c3;
}

a.color-icon.socicon-lanyrd {
	color: #3c80c9;
}

a.color-icon.socicon-slideshare {
	color: #4ba3a6;
}

a.color-icon.socicon-buffer {
	color: #000000;
}

a.color-icon.socicon-rss {
	color: #f26109;
}

a.color-icon.socicon-vkontakte {
	color: #5a7fa6;
}

a.color-icon.socicon-disqus {
	color: #2e9fff;
}

a.color-icon.socicon-houzz {
	color: #7cc04b;
}

a.color-icon.socicon-mail {
	color: #000000;
}

a.color-icon.socicon-patreon {
	color: #e44727;
}

a.color-icon.socicon-paypal {
	color: #009cde;
}

a.color-icon.socicon-playstation {
	color: #000000;
}

a.color-icon.socicon-smugmug {
	color: #acfd32;
}

a.color-icon.socicon-swarm {
	color: #fc9d3c;
}

a.color-icon.socicon-triplej {
	color: #e53531;
}

a.color-icon.socicon-yammer {
	color: #1175c4;
}

a.color-icon.socicon-stackoverflow {
	color: #fd9827;
}

a.color-icon.socicon-drupal {
	color: #00598e;
}

a.color-icon.socicon-odnoklassniki {
	color: #f48420;
}

a.color-icon.socicon-android {
	color: #8ec047;
}

a.color-icon.socicon-meetup {
	color: #e2373c;
}

a.color-icon.socicon-persona {
	color: #e6753d;
}

a.color-icon.socicon-amazon {
	color: #ff9900;
}

a.color-icon.socicon-ello {
	color: #000000;
}

a.color-icon.socicon-mixcloud {
	color: #314359;
}

a.color-icon.socicon-8tracks {
	color: #122c4b;
}

a.color-icon.socicon-twitch {
	color: #6441a5;
}

a.color-icon.socicon-airbnb {
	color: #ff5a5f;
}

a.color-icon.socicon-pocket {
	color: #ed4055;
}

a[class*="socicon-"].socicon-twitter:hover {
	color: #00abef;
	border-color: #00abef;
}

a[class*="socicon-"].socicon-facebook:hover {
	color: #507cbe;
	border-color: #507cbe;
}

a[class*="socicon-"].socicon-google:hover {
	color: #d93e2d;
	border-color: #d93e2d;
}

a[class*="socicon-"].socicon-pinterest:hover {
	color: #c92619;
	border-color: #c92619;
}

a[class*="socicon-"].socicon-foursquare:hover {
	color: #23b7e0;
	border-color: #23b7e0;
}

a[class*="socicon-"].socicon-yahoo:hover {
	color: #6e2a85;
	border-color: #6e2a85;
}

a[class*="socicon-"].socicon-skype:hover {
	color: #28abe3;
	border-color: #28abe3;
}

a[class*="socicon-"].socicon-yelp:hover {
	color: #c83218;
	border-color: #c83218;
}

a[class*="socicon-"].socicon-feedburner:hover {
	color: #ffcc00;
	border-color: #ffcc00;
}

a[class*="socicon-"].socicon-linkedin:hover {
	color: #3371b7;
	border-color: #3371b7;
}

a[class*="socicon-"].socicon-viadeo:hover {
	color: #e4a000;
	border-color: #e4a000;
}

a[class*="socicon-"].socicon-xing:hover {
	color: #005a60;
	border-color: #005a60;
}

a[class*="socicon-"].socicon-myspace:hover {
	color: #323232;
	border-color: #323232;
}

a[class*="socicon-"].socicon-soundcloud:hover {
	color: #fe3801;
	border-color: #fe3801;
}

a[class*="socicon-"].socicon-spotify:hover {
	color: #7bb342;
	border-color: #7bb342;
}

a[class*="socicon-"].socicon-grooveshark:hover {
	color: #f67e03;
	border-color: #f67e03;
}

a[class*="socicon-"].socicon-lastfm:hover {
	color: #d41316;
	border-color: #d41316;
}

a[class*="socicon-"].socicon-youtube:hover {
	color: #d12121;
	border-color: #d12121;
}

a[class*="socicon-"].socicon-vimeo:hover {
	color: #51b5e7;
	border-color: #51b5e7;
}

a[class*="socicon-"].socicon-dailymotion:hover {
	color: #004e72;
	border-color: #004e72;
}

a[class*="socicon-"].socicon-vine:hover {
	color: #00b389;
	border-color: #00b389;
}

a[class*="socicon-"].socicon-flickr:hover {
	color: #0062dd;
	border-color: #0062dd;
}

a[class*="socicon-"].socicon-500px:hover {
	color: #58a9de;
	border-color: #58a9de;
}

a[class*="socicon-"].socicon-instagram:hover {
	color: #9c7c6e;
	border-color: #9c7c6e;
}

a[class*="socicon-"].socicon-wordpress:hover {
	color: #464646;
	border-color: #464646;
}

a[class*="socicon-"].socicon-tumblr:hover {
	color: #45556c;
	border-color: #45556c;
}

a[class*="socicon-"].socicon-blogger:hover {
	color: #ec661c;
	border-color: #ec661c;
}

a[class*="socicon-"].socicon-technorati:hover {
	color: #5cb030;
	border-color: #5cb030;
}

a[class*="socicon-"].socicon-reddit:hover {
	color: #e74a1e;
	border-color: #e74a1e;
}

a[class*="socicon-"].socicon-dribbble:hover {
	color: #e84d88;
	border-color: #e84d88;
}

a[class*="socicon-"].socicon-stumbleupon:hover {
	color: #e64011;
	border-color: #e64011;
}

a[class*="socicon-"].socicon-digg:hover {
	color: #1d1d1b;
	border-color: #1d1d1b;
}

a[class*="socicon-"].socicon-envato:hover {
	color: #597c3a;
	border-color: #597c3a;
}

a[class*="socicon-"].socicon-behance:hover {
	color: #007eff;
	border-color: #007eff;
}

a[class*="socicon-"].socicon-delicious:hover {
	color: #31a9ff;
	border-color: #31a9ff;
}

a[class*="socicon-"].socicon-deviantart:hover {
	color: #c5d200;
	border-color: #c5d200;
}

a[class*="socicon-"].socicon-forrst:hover {
	color: #5b9a68;
	border-color: #5b9a68;
}

a[class*="socicon-"].socicon-play:hover {
	color: #5acdbd;
	border-color: #5acdbd;
}

a[class*="socicon-"].socicon-zerply:hover {
	color: #9dbc7a;
	border-color: #9dbc7a;
}

a[class*="socicon-"].socicon-wikipedia:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-apple:hover {
	color: #b9bfc1;
	border-color: #b9bfc1;
}

a[class*="socicon-"].socicon-flattr:hover {
	color: #f67c1a;
	border-color: #f67c1a;
}

a[class*="socicon-"].socicon-github:hover {
	color: #221e1b;
	border-color: #221e1b;
}

a[class*="socicon-"].socicon-chimein:hover {
	color: #888688;
	border-color: #888688;
}

a[class*="socicon-"].socicon-friendfeed:hover {
	color: #2f72c4;
	border-color: #2f72c4;
}

a[class*="socicon-"].socicon-newsvine:hover {
	color: #075b2f;
	border-color: #075b2f;
}

a[class*="socicon-"].socicon-identica:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-bebo:hover {
	color: #ef1011;
	border-color: #ef1011;
}

a[class*="socicon-"].socicon-zynga:hover {
	color: #dc0606;
	border-color: #dc0606;
}

a[class*="socicon-"].socicon-steam:hover {
	color: #8f8d8a;
	border-color: #8f8d8a;
}

a[class*="socicon-"].socicon-xbox:hover {
	color: #92c83e;
	border-color: #92c83e;
}

a[class*="socicon-"].socicon-windows:hover {
	color: #00bdf6;
	border-color: #00bdf6;
}

a[class*="socicon-"].socicon-outlook:hover {
	color: #0072c6;
	border-color: #0072c6;
}

a[class*="socicon-"].socicon-coderwall:hover {
	color: #3e8dcc;
	border-color: #3e8dcc;
}

a[class*="socicon-"].socicon-tripadvisor:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-appnet:hover {
	color: #494949;
	border-color: #494949;
}

a[class*="socicon-"].socicon-goodreads:hover {
	color: #1abc9c;
	border-color: #1abc9c;
}

a[class*="socicon-"].socicon-tripit:hover {
	color: #1982c3;
	border-color: #1982c3;
}

a[class*="socicon-"].socicon-lanyrd:hover {
	color: #3c80c9;
	border-color: #3c80c9;
}

a[class*="socicon-"].socicon-slideshare:hover {
	color: #4ba3a6;
	border-color: #4ba3a6;
}

a[class*="socicon-"].socicon-buffer:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-rss:hover {
	color: #f26109;
	border-color: #f26109;
}

a[class*="socicon-"].socicon-vkontakte:hover {
	color: #5a7fa6;
	border-color: #5a7fa6;
}

a[class*="socicon-"].socicon-disqus:hover {
	color: #2e9fff;
	border-color: #2e9fff;
}

a[class*="socicon-"].socicon-houzz:hover {
	color: #7cc04b;
	border-color: #7cc04b;
}

a[class*="socicon-"].socicon-mail:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-patreon:hover {
	color: #e44727;
	border-color: #e44727;
}

a[class*="socicon-"].socicon-paypal:hover {
	color: #009cde;
	border-color: #009cde;
}

a[class*="socicon-"].socicon-playstation:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-smugmug:hover {
	color: #acfd32;
	border-color: #acfd32;
}

a[class*="socicon-"].socicon-swarm:hover {
	color: #fc9d3c;
	border-color: #fc9d3c;
}

a[class*="socicon-"].socicon-triplej:hover {
	color: #e53531;
	border-color: #e53531;
}

a[class*="socicon-"].socicon-yammer:hover {
	color: #1175c4;
	border-color: #1175c4;
}

a[class*="socicon-"].socicon-stackoverflow:hover {
	color: #fd9827;
	border-color: #fd9827;
}

a[class*="socicon-"].socicon-drupal:hover {
	color: #00598e;
	border-color: #00598e;
}

a[class*="socicon-"].socicon-odnoklassniki:hover {
	color: #f48420;
	border-color: #f48420;
}

a[class*="socicon-"].socicon-android:hover {
	color: #8ec047;
	border-color: #8ec047;
}

a[class*="socicon-"].socicon-meetup:hover {
	color: #e2373c;
	border-color: #e2373c;
}

a[class*="socicon-"].socicon-persona:hover {
	color: #e6753d;
	border-color: #e6753d;
}

a[class*="socicon-"].socicon-amazon:hover {
	color: #ff9900;
	border-color: #ff9900;
}

a[class*="socicon-"].socicon-ello:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-mixcloud:hover {
	color: #314359;
	border-color: #314359;
}

a[class*="socicon-"].socicon-8tracks:hover {
	color: #122c4b;
	border-color: #122c4b;
}

a[class*="socicon-"].socicon-twitch:hover {
	color: #6441a5;
	border-color: #6441a5;
}

a[class*="socicon-"].socicon-airbnb:hover {
	color: #ff5a5f;
	border-color: #ff5a5f;
}

a[class*="socicon-"].socicon-pocket:hover {
	color: #ed4055;
	border-color: #ed4055;
}

/*corporate background colors*/
.color-bg-icon {
	color: #ffffff;
}

.color-bg-icon.socicon-twitter, a.socicon-twitter.bg-icon:hover, .socicon-twitter.color-icon.border-icon:hover {
	background-color: #00abef;
}

.color-bg-icon.socicon-facebook, a.socicon-facebook.bg-icon:hover, .socicon-facebook.color-icon.border-icon:hover {
	background-color: #507cbe;
}

.color-bg-icon.socicon-google, a.socicon-google.bg-icon:hover, .socicon-google.color-icon.border-icon:hover {
	background-color: #d93e2d;
}

.color-bg-icon.socicon-pinterest, a.socicon-pinterest.bg-icon:hover, .socicon-pinterest.color-icon.border-icon:hover {
	background-color: #c92619;
}

.color-bg-icon.socicon-foursquare, a.socicon-foursquare.bg-icon:hover, .socicon-foursquare.color-icon.border-icon:hover {
	background-color: #23b7e0;
}

.color-bg-icon.socicon-yahoo, a.socicon-yahoo.bg-icon:hover, .socicon-yahoo.color-icon.border-icon:hover {
	background-color: #6e2a85;
}

.color-bg-icon.socicon-skype, a.socicon-skype.bg-icon:hover, .socicon-skype.color-icon.border-icon:hover {
	background-color: #28abe3;
}

.color-bg-icon.socicon-yelp, a.socicon-yelp.bg-icon:hover, .socicon-yelp.color-icon.border-icon:hover {
	background-color: #c83218;
}

.color-bg-icon.socicon-feedburner, a.socicon-feedburner.bg-icon:hover, .socicon-feedburner.color-icon.border-icon:hover {
	background-color: #ffcc00;
}

.color-bg-icon.socicon-linkedin, a.socicon-linkedin.bg-icon:hover, .socicon-linkedin.color-icon.border-icon:hover {
	background-color: #3371b7;
}

.color-bg-icon.socicon-viadeo, a.socicon-viadeo.bg-icon:hover, .socicon-viadeo.color-icon.border-icon:hover {
	background-color: #e4a000;
}

.color-bg-icon.socicon-xing, a.socicon-xing.bg-icon:hover, .socicon-xing.color-icon.border-icon:hover {
	background-color: #005a60;
}

.color-bg-icon.socicon-myspace, a.socicon-myspace.bg-icon:hover, .socicon-myspace.color-icon.border-icon:hover {
	background-color: #323232;
}

.color-bg-icon.socicon-soundcloud, a.socicon-soundcloud.bg-icon:hover, .socicon-soundcloud.color-icon.border-icon:hover {
	background-color: #fe3801;
}

.color-bg-icon.socicon-spotify, a.socicon-spotify.bg-icon:hover, .socicon-spotify.color-icon.border-icon:hover {
	background-color: #7bb342;
}

.color-bg-icon.socicon-grooveshark, a.socicon-grooveshark.bg-icon:hover, .socicon-grooveshark.color-icon.border-icon:hover {
	background-color: #f67e03;
}

.color-bg-icon.socicon-lastfm, a.socicon-lastfm.bg-icon:hover, .socicon-lastfm.color-icon.border-icon:hover {
	background-color: #d41316;
}

.color-bg-icon.socicon-youtube, a.socicon-youtube.bg-icon:hover, .socicon-youtube.color-icon.border-icon:hover {
	background-color: #d12121;
}

.color-bg-icon.socicon-vimeo, a.socicon-vimeo.bg-icon:hover, .socicon-vimeo.color-icon.border-icon:hover {
	background-color: #51b5e7;
}

.color-bg-icon.socicon-dailymotion, a.socicon-dailymotion.bg-icon:hover, .socicon-dailymotion.color-icon.border-icon:hover {
	background-color: #004e72;
}

.color-bg-icon.socicon-vine, a.socicon-vine.bg-icon:hover, .socicon-vine.color-icon.border-icon:hover {
	background-color: #00b389;
}

.color-bg-icon.socicon-flickr, a.socicon-flickr.bg-icon:hover, .socicon-flickr.color-icon.border-icon:hover {
	background-color: #0062dd;
}

.color-bg-icon.socicon-500px, a.socicon-500px.bg-icon:hover, .socicon-500px.color-icon.border-icon:hover {
	background-color: #58a9de;
}

.color-bg-icon.socicon-instagram, a.socicon-instagram.bg-icon:hover, .socicon-instagram.color-icon.border-icon:hover {
	background-color: #9c7c6e;
}

.color-bg-icon.socicon-wordpress, a.socicon-wordpress.bg-icon:hover, .socicon-wordpress.color-icon.border-icon:hover {
	background-color: #464646;
}

.color-bg-icon.socicon-tumblr, a.socicon-tumblr.bg-icon:hover, .socicon-tumblr.color-icon.border-icon:hover {
	background-color: #45556c;
}

.color-bg-icon.socicon-blogger, a.socicon-blogger.bg-icon:hover, .socicon-blogger.color-icon.border-icon:hover {
	background-color: #ec661c;
}

.color-bg-icon.socicon-technorati, a.socicon-technorati.bg-icon:hover, .socicon-technorati.color-icon.border-icon:hover {
	background-color: #5cb030;
}

.color-bg-icon.socicon-reddit, a.socicon-reddit.bg-icon:hover, .socicon-reddit.color-icon.border-icon:hover {
	background-color: #e74a1e;
}

.color-bg-icon.socicon-dribbble, a.socicon-dribbble.bg-icon:hover, .socicon-dribbble.color-icon.border-icon:hover {
	background-color: #e84d88;
}

.color-bg-icon.socicon-stumbleupon, a.socicon-stumbleupon.bg-icon:hover, .socicon-stumbleupon.color-icon.border-icon:hover {
	background-color: #e64011;
}

.color-bg-icon.socicon-digg, a.socicon-digg.bg-icon:hover, .socicon-digg.color-icon.border-icon:hover {
	background-color: #1d1d1b;
}

.color-bg-icon.socicon-envato, a.socicon-envato.bg-icon:hover, .socicon-envato.color-icon.border-icon:hover {
	background-color: #597c3a;
}

.color-bg-icon.socicon-behance, a.socicon-behance.bg-icon:hover, .socicon-behance.color-icon.border-icon:hover {
	background-color: #007eff;
}

.color-bg-icon.socicon-delicious, a.socicon-delicious.bg-icon:hover, .socicon-delicious.color-icon.border-icon:hover {
	background-color: #31a9ff;
}

.color-bg-icon.socicon-deviantart, a.socicon-deviantart.bg-icon:hover, .socicon-deviantart.color-icon.border-icon:hover {
	background-color: #c5d200;
}

.color-bg-icon.socicon-forrst, a.socicon-forrst.bg-icon:hover, .socicon-forrst.color-icon.border-icon:hover {
	background-color: #5b9a68;
}

.color-bg-icon.socicon-play, a.socicon-play.bg-icon:hover, .socicon-play.color-icon.border-icon:hover {
	background-color: #5acdbd;
}

.color-bg-icon.socicon-zerply, a.socicon-zerply.bg-icon:hover, .socicon-zerply.color-icon.border-icon:hover {
	background-color: #9dbc7a;
}

.color-bg-icon.socicon-wikipedia, a.socicon-wikipedia.bg-icon:hover, .socicon-wikipedia.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-apple, a.socicon-apple.bg-icon:hover, .socicon-apple.color-icon.border-icon:hover {
	background-color: #b9bfc1;
}

.color-bg-icon.socicon-flattr, a.socicon-flattr.bg-icon:hover, .socicon-flattr.color-icon.border-icon:hover {
	background-color: #f67c1a;
}

.color-bg-icon.socicon-github, a.socicon-github.bg-icon:hover, .socicon-github.color-icon.border-icon:hover {
	background-color: #221e1b;
}

.color-bg-icon.socicon-chimein, a.socicon-chimein.bg-icon:hover, .socicon-chimein.color-icon.border-icon:hover {
	background-color: #888688;
}

.color-bg-icon.socicon-friendfeed, a.socicon-friendfeed.bg-icon:hover, .socicon-friendfeed.color-icon.border-icon:hover {
	background-color: #2f72c4;
}

.color-bg-icon.socicon-newsvine, a.socicon-newsvine.bg-icon:hover, .socicon-newsvine.color-icon.border-icon:hover {
	background-color: #075b2f;
}

.color-bg-icon.socicon-identica, a.socicon-identica.bg-icon:hover, .socicon-identica.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-bebo, a.socicon-bebo.bg-icon:hover, .socicon-bebo.color-icon.border-icon:hover {
	background-color: #ef1011;
}

.color-bg-icon.socicon-zynga, a.socicon-zynga.bg-icon:hover, .socicon-zynga.color-icon.border-icon:hover {
	background-color: #dc0606;
}

.color-bg-icon.socicon-steam, a.socicon-steam.bg-icon:hover, .socicon-steam.color-icon.border-icon:hover {
	background-color: #8f8d8a;
}

.color-bg-icon.socicon-xbox, a.socicon-xbox.bg-icon:hover, .socicon-xbox.color-icon.border-icon:hover {
	background-color: #92c83e;
}

.color-bg-icon.socicon-windows, a.socicon-windows.bg-icon:hover, .socicon-windows.color-icon.border-icon:hover {
	background-color: #00bdf6;
}

.color-bg-icon.socicon-outlook, a.socicon-outlook.bg-icon:hover, .socicon-outlook.color-icon.border-icon:hover {
	background-color: #0072c6;
}

.color-bg-icon.socicon-coderwall, a.socicon-coderwall.bg-icon:hover, .socicon-coderwall.color-icon.border-icon:hover {
	background-color: #3e8dcc;
}

.color-bg-icon.socicon-tripadvisor, a.socicon-tripadvisor.bg-icon:hover, .socicon-tripadvisor.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-appnet, a.socicon-appnet.bg-icon:hover, .socicon-appnet.color-icon.border-icon:hover {
	background-color: #494949;
}

.color-bg-icon.socicon-goodreads, a.socicon-goodreads.bg-icon:hover, .socicon-goodreads.color-icon.border-icon:hover {
	background-color: #1abc9c;
}

.color-bg-icon.socicon-tripit, a.socicon-tripit.bg-icon:hover, .socicon-tripit.color-icon.border-icon:hover {
	background-color: #1982c3;
}

.color-bg-icon.socicon-lanyrd, a.socicon-lanyrd.bg-icon:hover, .socicon-lanyrd.color-icon.border-icon:hover {
	background-color: #3c80c9;
}

.color-bg-icon.socicon-slideshare, a.socicon-slideshare.bg-icon:hover, .socicon-slideshare.color-icon.border-icon:hover {
	background-color: #4ba3a6;
}

.color-bg-icon.socicon-buffer, a.socicon-buffer.bg-icon:hover, .socicon-buffer.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-rss, a.socicon-rss.bg-icon:hover, .socicon-rss.color-icon.border-icon:hover {
	background-color: #f26109;
}

.color-bg-icon.socicon-vkontakte, a.socicon-vkontakte.bg-icon:hover, .socicon-vkontakte.color-icon.border-icon:hover {
	background-color: #5a7fa6;
}

.color-bg-icon.socicon-disqus, a.socicon-disqus.bg-icon:hover, .socicon-disqus.color-icon.border-icon:hover {
	background-color: #2e9fff;
}

.color-bg-icon.socicon-houzz, a.socicon-houzz.bg-icon:hover, .socicon-houzz.color-icon.border-icon:hover {
	background-color: #7cc04b;
}

.color-bg-icon.socicon-mail, a.socicon-mail.bg-icon:hover, .socicon-mail.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-patreon, a.socicon-patreon.bg-icon:hover, .socicon-patreon.color-icon.border-icon:hover {
	background-color: #e44727;
}

.color-bg-icon.socicon-paypal, a.socicon-paypal.bg-icon:hover, .socicon-paypal.color-icon.border-icon:hover {
	background-color: #009cde;
}

.color-bg-icon.socicon-playstation, a.socicon-playstation.bg-icon:hover, .socicon-playstation.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-smugmug, a.socicon-smugmug.bg-icon:hover, .socicon-smugmug.color-icon.border-icon:hover {
	background-color: #acfd32;
}

.color-bg-icon.socicon-swarm, a.socicon-swarm.bg-icon:hover, .socicon-swarm.color-icon.border-icon:hover {
	background-color: #fc9d3c;
}

.color-bg-icon.socicon-triplej, a.socicon-triplej.bg-icon:hover, .socicon-triplej.color-icon.border-icon:hover {
	background-color: #e53531;
}

.color-bg-icon.socicon-yammer, a.socicon-yammer.bg-icon:hover, .socicon-yammer.color-icon.border-icon:hover {
	background-color: #1175c4;
}

.color-bg-icon.socicon-stackoverflow, a.socicon-stackoverflow.bg-icon:hover, .socicon-stackoverflow.color-icon.border-icon:hover {
	background-color: #fd9827;
}

.color-bg-icon.socicon-drupal, a.socicon-drupal.bg-icon:hover, .socicon-drupal.color-icon.border-icon:hover {
	background-color: #00598e;
}

.color-bg-icon.socicon-odnoklassniki, a.socicon-odnoklassniki.bg-icon:hover, .socicon-odnoklassniki.color-icon.border-icon:hover {
	background-color: #f48420;
}

.color-bg-icon.socicon-android, a.socicon-android.bg-icon:hover, .socicon-android.color-icon.border-icon:hover {
	background-color: #8ec047;
}

.color-bg-icon.socicon-meetup, a.socicon-meetup.bg-icon:hover, .socicon-meetup.color-icon.border-icon:hover {
	background-color: #e2373c;
}

.color-bg-icon.socicon-persona, a.socicon-persona.bg-icon:hover, .socicon-persona.color-icon.border-icon:hover {
	background-color: #e6753d;
}

.color-bg-icon.socicon-amazon, a.socicon-amazon.bg-icon:hover, .socicon-amazon.color-icon.border-icon:hover {
	background-color: #ff9900;
}

.color-bg-icon.socicon-ello, a.socicon-ello.bg-icon:hover, .socicon-ello.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-mixcloud, a.socicon-mixcloud.bg-icon:hover, .socicon-mixcloud.color-icon.border-icon:hover {
	background-color: #314359;
}

.color-bg-icon.socicon-8tracks, a.socicon-8tracks.bg-icon:hover, .socicon-8tracks.color-icon.border-icon:hover {
	background-color: #122c4b;
}

.color-bg-icon.socicon-twitch, a.socicon-twitch.bg-icon:hover, .socicon-twitch.color-icon.border-icon:hover {
	background-color: #6441a5;
}

.color-bg-icon.socicon-airbnb, a.socicon-airbnb.bg-icon:hover, .socicon-airbnb.color-icon.border-icon:hover {
	background-color: #ff5a5f;
}

.color-bg-icon.socicon-pocket, a.socicon-pocket.bg-icon:hover, .socicon-pocket.color-icon.border-icon:hover {
	background-color: #ed4055;
}

a.social-icon {
	color: #b2b2b2;
}

a.border-icon {
	border: 1px solid #656565;
	padding: 8px 9px;
	margin-bottom: 4px;
	margin-top: 4px;
	border-radius: 0;
}

a.border-icon:before {
	line-height: 16px;
}

a.bg-icon {
	background-color: #f2f2f2;
	padding: 11px 13px 12px;
	margin-bottom: 4px;
}

a.bg-icon:before {
	font-size: 12px;
	line-height: 16px;
}

.ds a.bg-icon {
	background-color: rgba(255, 255, 255, 0.05);
}

a.social-icon.color-icon:hover {
	color: #323232;
}

a.social-icon.bg-icon:hover {
	color: #ffffff;
}

a.color-bg-icon {
	color: #ffffff;
	background-color: #323232;
	margin-bottom: 4px;
	padding: 11px 13px 12px;
}

a.color-bg-icon:before {
	font-size: 12px;
	line-height: 16px;
}

a.social-icon.color-bg-icon:hover {
	background-color: #fafafa;
}

a.color-icon:hover {
	color: #323232;
}

a.color-icon.border-icon:hover {
	color: #ffffff;
	background-color: #0074ba;
	border-color: transparent;
}

.big-icons a.border-icon {
	padding: 15px 14px 16px;
}

.big-icons a.border-icon:before {
	font-size: 20px;
}

.demo-icon {
	display: inline-block;
	text-align: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding: 15px 20px 30px;
	margin-bottom: 30px;
}

.demo-icon span {
	display: block;
}

.demo-icon .name {
	font-size: 1.01em;
	font-weight: 700;
}

.demo-icon a[class*="socicon-"] {
	margin: 10px 0 0;
}

.demo-icon a[class*="socicon-"]:before {
	font-size: 24px;
}

[class*="socicon-"].text-icon:before {
	display: none;
}

.text-icon {
	text-transform: uppercase;
}

.language_switcher a{
	margin: 0 3px 0 2px;
}

#price-table{
	tr[data-currency="eur"], tr[data-currency="usd"]{
		display: none;
	}
	th{
		text-align: center;
		background: #727272;
		color: #fff;
	}
	td{
		vertical-align: middle;
		text-align: center;

		img{
			float: left;
		}
	}
	i{
		font-size: 30px;
	}
	i.fa-angle-up{
		color: green
	}
	i.fa-angle-down{
		color: red
	}
	.material-miniature{
		display: flex;
	    align-items: center;
	    justify-content: center;

		img{
			max-width: 110px;
		}
	}
}
#price-table.with-titles td:nth-child(2){
    width: 230px;
    line-height: 90px;
    padding: 0;
    text-align: left;
    padding-left: 20px;

	a{
		text-align: left;
    	font-family: 'HelveticaW01-RoundedBdC 709938', Helvetica, Arial, sans-serif;
	    font-size: 18px;
	    color: #004a96;
	}
}

#currency_switcher .active{
	color: #0073b7;
}

.material-image{
	margin-bottom: 20px;
}

.intro_overlap_section .tab-pane form{
	h2{
		margin-top: 0;
	}
	.form-group{
		margin-bottom: 10px;
	}
	.row > div{
		margin-top: 0;
	}
}

@keyframes animatedBackground {
	from { background-position: 0 0; }
	to { background-position: 100% 100%; }
}
.page_breadcrumbs.parallax .wrapper-bg{
	width: 100%;
    height: 1000%;

    position: absolute;
    top: -50%;
    transform: translateY(50%);
    left: 0;
    z-index: -1;
    transform: scale(1.2);
}
.page_breadcrumbs.parallax{
	width: 100%;


	.container{
		padding: 37px 0;
	}
}

.lh20{
	line-height: 20px;
}

.mb20{
	margin-bottom: 20px;
}
.mt15{
	margin-top: 15px;
}
.mt50{
	margin-top: 50px;
}
.mb40{
	margin-bottom: 40px;
}
.mtb75{
	margin-top: 75px;
	margin-bottom: 75px;
}
.pt20{
	padding-top: 20px;
}
.mtb100{
	margin-top: 100px;
	margin-bottom: 100px;
}
.pt75{
	padding-top: 75px;
}
.pb75{
	padding-bottom: 75px;
}
#reassurance i{
	color: #B1D082;
	font-size: 43px;
}

#reassurance > div:hover h5{
	color: #B1D082;
}
#home{
	background-color: #fff;
}

#counters{
	background: #fafafa;

	i{
		color: #a2c862;
		font-size: 60px;
		line-height: 60px;
	}
}

.media-list{
	background: #fff;
	border-radius: 5px;
	padding-top: 10px!important;

	li{
		margin-top: 0;
	}
}
#copyright{
	text-align: center;

	a{
		color: #ccc;
	}
}
#footer_contact_form_submit{
	font-size: 12px;
}
#group h5{
	margin-bottom: 30px;
}
#group h4, #group h5, #addresses h4{
	background-color: #29B6A2;
	color: #fff;
	padding-left: 30px;
	position: relative;
	overflow: hidden;
}
#group h4:before, #group h5:before, #addresses h4:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 80px solid #FAFAFA;
    border-right: 43px solid #29B6A2;
    margin-left: -26px;
    width: 0;
}
#addresses h4.grey{
	background: #9c9c9c;
}
#addresses h4.grey:before{
	border-right: 43px solid #9c9c9c;
}
#addresses h4.green{
	background: #33A68A;
}
#addresses h4.green:before{
	border-right: 43px solid #33A68A;
}
#addresses h4.blue{
	background: #bee27b;
}
#addresses h4.blue:before{
	border-right: 43px solid #bee27b;
}

#group img{
	margin-bottom: 10px;
}

.h80{
	height: 80px;
}
.mt70{
	margin-top: 70px;
}
#about .image_cover img{
	max-height: 100%;
    float: right;
}

#home #about{
	background-image: url(/assets/img/eco-car.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 44%;
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: #efefef;
}
#calculator{
	.vehicules .hideable{
		display: none;
	}
}
#materials{
	td{
		border: 0;
		padding: 5px;
		vertical-align: middle;
	}

	tr:first-child .remove{
		display: none;
	}

	input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], input[type="date"], textarea, select, .form-control{
		padding-left: 10px;
		padding-right: 10px;
	}
}
.unit.input-group-addon{
	text-transform: uppercase;
}
.frdark tr:first-child{
	background-color: #757575 !important;
    color: #fff;
}
.honeypot {
	display: none;
}
#vehicule-content{
	.material-miniature img{
	    width: 67px;
	    height: 67px;
	    margin: 0 5px;
	}
}
#vehicule-content h3 i{
	color: #a2c862;
	margin-right: 10px;
}

#vehicule-content h3 img{
	width: 30px;
	margin-right: 10px;
}

a.anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
}
#counters a p{
	color: #000
}
#counters .teaser:hover h3{
	color: #A2C862;
}

.simple-table td, .simple-table th{
	border: none;
	padding: 0;
	vertical-align: top;
}
.simple-table tr td:first-child{
	width: 400px;
}
#vehicule-content{
	.table > tbody > tr > td{
		padding: 15px 0;
		font-size: 14px;
	}
}

.page_mainslider{
	background: #d8d8d8;
	color: #323232;

	p, h2, .slide_description{
		color: #323232;
	}

	.slide_description{
		margin-top: -160px;
	}
}
.ptb50{
	padding-top: 50px;
	padding-bottom: 50px;
}
.ptb100{
	padding-top: 100px;
	padding-bottom: 100px;
}
.mainmenu{
	.sub-icon{
	    float: right;
	    vertical-align: middle;
	    line-height: 27px;
	}
}
#autres_matieres{
	table{
		width: auto;
		td{
			max-width: 50%;
			width: 500px;
		}
	}
}
#eco-car img{
	position: absolute;
	left: 0;
	top: 200px;
	z-index: 999;
}
.partner-form label{
	display: none;
}
form i.highlight2{
	margin-top: -3px;
}

#addresses button, #addresses a.theme_button{
	position: relative;
	padding-left: 40px;
	text-align: left;
	white-space: initial;
	line-height: 130%;

	i{
		position: absolute;
		left: 15px;
		top: 50%;
	}
}
#map {
	img[src*="marker-grey.png"], img[src*="marker-green.png"]{
		width: 55px!important;
		height: 55px!important;
		left: 4.5px!important;
		top: 4.5px!important;
	}

	@media screen and (max-width: 899px) {
		img[src*="marker-grey.png"], img[src*="marker-green.png"]{
			width: 30px!important;
			height: 30px!important;
			left: 1.8px!important;
			top: 1.8px!important;
		}
		img[src*="marker-blue.png"]{
			width: 35px!important;
			height: 35px!important;
		}
	}
}

button.marker{
	background: #fff;
	border: 1px solid #fff;
	width: 100%;

	&.marker-grey {
		color: #9c9c9c;
	}

	&.marker-lightgreen {
		color: #bee27b;
	}

	&:hover, &:active, &:focus{
		color: #33A68A;
		border: 1px solid #33A68A;

		&.marker-grey {
			border-color: #9c9c9c;
			color: #9c9c9c;
		}
	
		&.marker-lightgreen {
			border-color: #bee27b;
			color: #bee27b;
		}
	}
}

.remise{
	background-image: url('/assets/img/pig.png');
	top: -168px;
	position: absolute;
	width: 200px;
	height: 164px;
	background-size: 100%;
	font-family: 'HelveticaW01-RoundedBdC 709938', Helvetica, Arial, sans-serif;
	text-align: center;
    padding-top: 88px;
    z-index: 10;

	strong{
		color: #fff;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 36px;
	}
}

.pt50{
	padding-top: 50px;
}
.pb30{
	padding-bottom: 30px;
}
.mb10{
	margin-bottom: 10px;
}
.static-calculator-open{
	height: 50px;
    position: relative;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
    padding: 5px 40px;
    transform: rotate(-90deg);
    display: inline-block;
    cursor: pointer;
    background: #3370AB;
    color: #fff;
    position: absolute;
    bottom: 0;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    font-family: 'HelveticaW01-RoundedBdC 709938', Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 44px;

    i{
    	margin-right: 6px;
    	font-size: 18px;
    }
}
#gallery h3.entry-title{
	font-size: 26px;
}
.static-calculator{
	max-height: 700px;
	overflow-y: scroll;
	width: 430;
    position: fixed;
    z-index: 999;
    right: -430px;
    transition: 0.5s ease;
    top: 107px;
    max-width: 100%;
}
.static-calculator.open{
	right: 0!important;
	transition: 0.5s ease;
}

.pricing-form .hideable{
	display: none;

	&.active{
		display: block;
	}
}

.cursor{
  animation-name: toggle;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-right: 2px solid #000;
  margin-left: 2px;
}
.bareclist{
	padding-left: none;
	list-style: none;

	li{
		padding-left: 2.3em;
    	font-size: 17px;

		&:before{
			font-family: FontAwesome;
			content: "\f19d";
			display: inline-block;
			margin-left: -2.3em;
  			width: 1.3em;
		    position: relative;
		    color: #a2c862;
		    left: -9px;
		    font-size: 20px;
		}
	}
}
.inpage-form{

	[class*="col-"]{
		margin-top: 3px;
		margin-bottom: 3px;
	}

	.form-group{
		margin-bottom: 8px;
	}
}

@keyframes toggle {
    from {
            visibility:hidden;
         }
    50% {
            visibility:hidden;
         }
    to {
            visibility:visible;
      }
 }


#voitures{
	section#sectioncall{
		margin-bottom: 40px;

		i{
			font-size: 13px;
			margin-left: 10px;
		}
	}

	section#reassurance{
		img{
			height: 128px;
		}
	}

	section#calculator{
		h3{
			margin-top: 25px;
			margin-bottom: 15px;

			img{
				max-width: 37px;
			}

			i{
				color:rgb(177, 208, 130);
			}
		}

		.text{
			p{
				display: none;

				&:first-child{
					display: block;

					i{
						color: #009d83;
						cursor: pointer;
					}
				}
			}
		}
	}

	section#location{
		padding-top: 30px;
		background-color: rgb(239, 239, 239);

		h2 i{
			color: #B1D082;
			font-size: 33px;
			line-height: 51px;
			vertical-align: top;
			margin-right: 5px;
		}

		.location-main{
			position: relative;
			overflow: hidden;

			.grey-slant{
			    padding-top: 14%;
			    position: absolute;
			    z-index: 999;
			    top: -68%;
			    left: -7%;
			    height: 1000px;
			    background: #efefef !important;
			    width: 55%;
			    transform: rotate(27deg);
			    padding-left: 17%;

				> div{
					transform: rotate(-27deg);
					margin-top: 10px;
				}

				.owl-carousel .owl-nav > div:after{
					color: #B1D082;
					font-size: 45px;
				}
				.owl-carousel .owl-dots .owl-dot span{
					border: 2px solid #B1D082;
					height: 14px;
					width: 14px;
				}
				.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span{
					background-color: #B1D082;
				}
			}

			> .row > div{
				margin-top: 0;
				margin-bottom: 0;
			}

			.owl-location div{
				text-align: center;

				img{
					margin-left: auto;
					margin-right: auto;
					max-width: 80%;
				}
			}

			#image0, #image1{
				position: absolute;
			}

			#image0 {
				top: 0;
				left: 0;
				z-index: 1001;
			}

			#image1{
				right: 50%;
				bottom: 0;
				z-index: 1000;
			}

			section{
				float: right;
				width: 100%;
			}
		}
	}

	section#counters{
		background: #efefef!important;

		.teaser{
			img{
				margin-bottom: 20px;
			}
		}
	}

	section#certificat img{
		border: 1px solid #000;
		max-height: 400px;
	}

	section#form{
		 background-repeat: no-repeat;
		 background-position: bottom right;
		 background-color: #efefef;
	}
}
.partners-carousel .owl-stage{
	margin-left: auto;
	margin-right: auto;
}

.form-message{
    position: fixed;
    z-index: 99999999;
    text-align: center;
    width: 100%;
    -moz-animation: cssAnimation 0s ease-in 6s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 6s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 6s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 6s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}

.form-message.message-success .inner{
	background: #B1D082;
}

.form-message.message-errors .inner{
	background: #e23a3a;
}

.form-message .inner{
    margin: 20px;
    color: white;
    border-radius: 14px;
    display: inline-block;
    padding: 10px 94px 17px;
    margin-top: 55px;
    font-size: 20px;
    max-width: 700px;
    opacity: 0.95;

    h2{		
		font-size: 26px;
	    margin-bottom: 0;
	    line-height: 124%;
    }
}

@media screen and (min-width: 998px) {
	.column-count-desktop-2 {
		column-count: 2;
	}
}

.mb-1 {
	margin-bottom: 10px;
}

.border-primary {
	border: 1px solid #009d83;
}

span.help-block.form-error {
	color: #fff;
}

.banner-top {
	width: 100%;
	padding: 0.5rem 0;
	background-color: #009d83;
	z-index: 1;

	p {
		color: #fff;
		font-weight: bold;
	}
}

#whatsapp-widget {
	background-color: #128C7E;
	right: 60px;
	bottom: 20px;
	display: flex;
	position: fixed;
	align-items: center;
	padding: 5px 10px;
	cursor: pointer;
	transition: all 0.05s;
	
	i {
		font-size: 1.5rem;
		margin-right: 10px;
	}

	a:hover {
		color: #fff;
	}

	&:hover {
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
	}
}

@media (max-width: 991px) {
	#whatsapp-widget {
		right: 30px;
	}
}

@media (max-width: 768px) {
	#whatsapp-widget {
		right: 80px;
		bottom: 10px;
	}
}

@media (max-width: 667px) {
	#whatsapp-widget {
		right: 10px;
		bottom: 70px;
	}
}

#gmap {
	padding: 15rem 0; /* 208px */
	border-width: 4px;

	& h5 {
		font-size: 18px;
		margin-bottom: 5px;
	}

	& a {
		background-color: #BEE27B;
		color: #fff;
		border-radius: 5px;
		padding: 5px 8px;

		&:hover {
			color: #000;
		}
	}
}


#ct-list-buttons {
	display: flex;
	align-items: center;
	justify-content: end;
}

.cursor-pointer {
	cursor: pointer;
}
#overlay-car-popup{
	position: fixed;
	height: 110%;
	width: 100%;
	margin-top: -50px;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.5);
	--tw-bg-opacity: 0.65;
	z-index: 2000;
	margin-bottom: 70px;
}
#car-popup{
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	position: absolute;
	width: 61%;
	left: 15%;
	top: 25%;
	text-align: center;
}
#close-car-popup{
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	font-size: 40px;
}
.car-popup-text{
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}
#car-poup-close-button{
	margin-bottom: 20px;
	background-color: #009d83;
	color: white;
	border-radius: 0;
	padding: 15px 70px;
}
#car-poup-close-button:hover{
	color: black;
	transition: all 0.4s linear 0s;
}
#car-poup-close-button p{
	font-family: Arial, Helvetica, sans-serif;
	text-transform: none;
	letter-spacing: normal;
	font-size: 19px;
	line-height: normal;
}
#car-popup-title{
	font-weight: bold;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	width: 90%;
}

#car-popup-light-text{
	color: #AAAAAA;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	width: 90%;
	margin-bottom: 20px;
	line-height: 20px;
}
@media (max-width: 992px) {
	#car-popup-image{
		display: none;
	}
}
@media (max-width: 992px) {
	#car-popup-image{
		display: none;
	}
}
@media (max-width: 450px) {
	#car-popup{
		width: 90%;
		left: 5%;
		top:10%;
	}
	#car-popup-title{
		margin-bottom: 10px;
		margin-top: 20px;
	}
	#overlay-car-popup{
		margin-top: -100px;
	}
	#car-poup-close-button{
		width: 80%;
		border-radius: 20px;
		padding: 2px 2px;
		}
	#car-poup-close-button p{
		font-size: 16px;
	}
	.car-popup-text{
		margin-bottom: 5px;
	}
}