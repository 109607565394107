/*
**shop
*/
input[disabled] {
  opacity: .4;
  cursor: not-allowed;
}

/* sort panel */
.shop-sorting {
  line-height: 1;
  margin-top: -10px;
}

.shop-sorting label {
  padding-right: 10px;
}

.shop-sorting select {
  min-width: 270px;
}

.woocommerce-ordering {
  display: inline-block;
}

.woocommerce-result-count {
  line-height: 40px;
  margin-bottom: 0;
}

#toggle_shop_view:before,
#toggle_shop_view:after {
  font-family: 'FontAwesome';
  display: inline-block;
  color: #656565;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 28px;
  border-radius: 1px;
  transition: all 0.2s ease-in-out 0s;
}

#toggle_shop_view:before {
  content: "\f009";
  margin-right: 5px;
  color: #4ebbe3;
}

#toggle_shop_view:after {
  content: "\f0c9";
}

#toggle_shop_view.grid-view:before {
  color: #656565;
}

#toggle_shop_view.grid-view:after {
  color: #4ebbe3;
}

#sort_view {
  color: #323232;
  display: inline-block;
  padding: 12px 16px 13px;
  font-size: 14px;
  background-color: #ffffff;
}

.columns-1 .product {
  width: 100%;
  margin-right: 0;
  float: none;
}

@media (min-width: 992px) {
  .columns-2 .product {
    width: 50%;
    float: left;
  }
  .columns-2 .product:nth-child(2n + 1) {
    clear: both;
  }
  .columns-3 .product {
    width: 33.33333333%;
    float: left;
  }
  .columns-4 .product {
    width: 25%;
    float: left;
  }
}

.product .images,
.product .thumbnails a,
.product .item-media {
  overflow: hidden;
  display: block;
}

.product .images img,
.product .thumbnails a img,
.product .item-media img {
  transition: all 0.2s ease-in-out 0s;
}

.product .images:hover img,
.product .thumbnails a:hover img,
.product .item-media:hover img {
  opacity: 0.8;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}

.products {
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
}

.products:before, .products:after {
  content: '';
  clear: both;
  display: block;
}

.upsells .products,
.related .products {
  margin-left: 0;
  margin-right: 0;
}

li.product {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

.owl-carousel li.product {
  margin-bottom: 0;
}

li.product {
  text-align: center;
}

.owl-item .product {
  padding: 0;
}

.product .row {
  position: relative;
}

.product [class*="col-"] {
  position: static;
  margin-top: 0;
  margin-bottom: 0;
}

.product [class*="col-"].images a {
  display: block;
  position: relative;
}

.product h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.product .rating-title {
  font-size: 1.2em;
}

.product .item-meta + .entry-title {
  margin-top: 5px;
}

.product .product-media {
  position: relative;
}

.product .images {
  position: relative;
}

.product .newproduct,
.product .onsale {
  position: absolute;
  background-color: #bee27b;
  border-radius: 3px;
  color: #ffffff;
  padding: 8px 18px;
  text-transform: uppercase;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.product .onsale {
  background-color: #bee27b;
  left: 20px;
  bottom: 20px;
  right: auto;
  top: auto;
}

.related,
.upsells {
  margin-top: 80px;
  padding-right: 15px;
  padding-left: 15px;
}

.related > h2,
.upsells > h2 {
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0 30px;
}

.upsells + .related {
  margin-top: 30px;
}

.price {
  font-size: 16px;
  color: #4ebbe3;
}

.price del {
  color: #656565;
  margin-right: 10px;
}

.price ins {
  text-decoration: none;
  color: #4ebbe3;
}

.entry-title + .price {
  margin-top: -10px;
}

.woocommerce-loop-product__link + .price {
  display: block;
  margin-top: -15px;
  margin-bottom: 20px;
}

.woocommerce-loop-product__link:hover, .woocommerce-loop-product__link:active, .woocommerce-loop-product__link:focus {
  color: #bee27b;
}

.product-buttons {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.product-buttons a {
  float: left;
  width: 40px;
  height: 40px;
  font-size: 0;
  color: transparent;
  text-align: center;
  background-color: #4ebbe3;
}

.product-buttons a.loading [class*='rt-icon'], .product-buttons a.loading:before {
  display: inline-block;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

.product-buttons a.loading [class*='rt-icon']:before, .product-buttons a.loading:before:before {
  content: "\e61b";
}

.product-buttons a.loading:before {
  content: "\e61b";
}

.product-buttons a i, .product-buttons a:before {
  line-height: 40px;
  font-size: 16px;
  color: #ffffff;
}

.product-buttons a:before {
  font-family: 'rt-icons-2';
  content: "\e626";
}

.product-buttons a.favorite_button {
  background-color: #bee27b;
  color: #323232;
}

.product-buttons a.favorite_button:before {
  font-family: 'rt-icons-2';
  content: "\f08a";
}

.product-buttons a:hover {
  background-color: #323232;
  color: #ffffff;
}

.product-buttons a.added_to_cart {
  background-color: #ffffff;
  font-size: 0;
  color: transparent;
}

.product-buttons a.added_to_cart:hover {
  background-color: #4ebbe3;
}

.product-buttons a.added_to_cart:before {
  content: "\e6c0";
  line-height: 40px;
  color: #323232;
  font-family: 'rt-icons-2';
  font-size: 26px;
}

.product-buttons a.product_type_variable:before {
  font-size: 20px;
  content: "\e63f";
}

/* single-product */
.thumbnails-wrap {
  margin-top: 9px;
}

.summary h1 {
  margin-top: 0;
  font-size: 24px;
}

@media (min-width: 1200px) {
  .summary h1 {
    font-size: 30px;
  }
}

.summary .price {
  position: static;
  display: block;
  background-color: transparent;
  line-height: 1;
  word-wrap: break-word;
}

.summary .price del {
  font-size: 16px;
}

.summary .woocommerce-variation-price .price {
  padding-top: 0;
}

.summary .star-rating {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 20px;
  font-size: 16px;
}

.summary .star-rating:before,
.summary .star-rating span:before {
  font-size: 16px;
}

.summary .stock {
  margin-bottom: 0;
}

.summary .email-to {
  margin-bottom: 10px;
}

.summary .product-option-name {
  display: inline-block;
  margin: 10px 0;
}

.summary .theme_button {
  margin-top: 0;
}

.summary .theme_button.wc-variation-selection-needed {
  opacity: .4;
  cursor: not-allowed;
}

.summary .posted_in {
  margin: 10px 0;
}

.summary .posted_in .small_button {
  margin-left: 5px;
  padding-left: 6px;
  padding-right: 6px;
}

.summary .product_meta {
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  font-size: 0;
}

.summary .product_meta > * {
  font-size: 13px;
}

.summary .product_meta > span {
  display: inline-block;
  min-width: 50%;
}

.summary .product_meta > span.sku_wrapper {
  display: block;
}

.summary .product_meta a {
  margin: 0 0 0 6px;
}

.summary [itemprop="description"] {
  margin: 30px 0;
}

.summary ul.list1 li {
  padding-top: 15px;
  padding-bottom: 20px;
}

.summary .reset_variations {
  color: transparent;
  font-size: 0;
  padding: 0 5px;
}

.summary .reset_variations:before {
  font-family: 'rt-icons-2';
  content: "\e117";
  font-size: 18px;
  color: #323232;
}

.summary label {
  margin: 0;
}

.summary select {
  padding-left: 20px;
  padding-right: 20px;
}

.summary [class*='add-to-cart'] {
  margin: 10px 0;
}

.summary [class*='add-to-cart'] .theme_button {
  float: right;
}

.summary .share_buttons {
  background-color: transparent;
  padding: 0 0 30px;
  margin: 0 0 40px;
  border-bottom: 1px solid #f2f2f2;
}

.summary .variations + .share_buttons {
  border-top: 1px solid #f2f2f2;
  padding-top: 30px;
  margin-top: 10px;
}

.quantity {
  position: relative;
  display: inline-block;
}

.quantity [type="button"] {
  position: absolute;
  right: 20px;
  top: 12px;
  line-height: 1;
  color: transparent;
  border: none;
  width: 22px;
  height: 10px;
  background-color: transparent;
  z-index: 1;
}

.quantity [type="button"] + i {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 16px;
  width: 22px;
  text-align: center;
  line-height: 10px;
  color: #79d7d8;
  display: inline-block;
}

.quantity [type="button"]:hover + i {
  color: #323232;
}

.quantity [type="button"].minus {
  top: auto;
  bottom: 12px;
}

.quantity [type="button"].minus + i {
  top: auto;
  bottom: 12px;
}

.quantity [type="number"] {
  padding-right: 40px;
  padding-left: 20px;
  max-width: 95px;
  min-width: 70px;
}

.variations td {
  display: block;
  border: 0;
  padding: 3px 0;
}

.variations td.value {
  margin-bottom: 10px;
}

.variations label {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 900;
}

.product_meta_bottom {
  margin: 0 0 20px;
}

.owl-carousel.products {
  margin-bottom: 0;
}

.owl-carousel.thumbnails-wrap {
  margin: 10px 0 0;
}

.owl-carousel.thumbnails-wrap .owl-dots {
  margin: 10px 0 5px;
}

.owl-carousel.thumbnails-wrap .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border-width: 1px;
}

.product-icons .teaser [class*="_bg_color"] {
  padding: 10px;
}

.product + .nav-tabs,
.woocommerce-tabs {
  margin-top: 80px;
}

/*shop comments*/
.commentlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.commentlist li {
  margin-bottom: 20px;
}

.commentlist li img {
  display: block;
  float: left;
  overflow: hidden;
  border-radius: 50%;
}

.commentlist .comment-text {
  overflow: hidden;
  position: relative;
  padding: 5px 0 0 20px;
}

.commentlist p.meta {
  font-size: 0;
  margin: 0 0 30px;
}

.commentlist p.meta strong {
  color: #323232;
  font-size: 18px;
  line-height: 1;
  display: block;
}

@media (min-width: 992px) {
  .commentlist p.meta strong {
    font-size: 24px;
  }
}

.commentlist p.meta .woocommerce-review__dash {
  display: none;
}

.commentlist p.meta time {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #4ebbe3;
  font-weight: 700;
}

.commentlist .star-rating {
  position: absolute;
  top: 60px;
}

#reply-title {
  font-size: 24px;
  font-weight: 600;
  color: #323232;
}

.woocommerce-tabs .tab-content {
  padding-top: 40px;
}

.woocommerce-tabs .tab-content .comment-respond {
  margin: 30px 0 0;
}

.woocommerce-tabs .tab-content .comment-respond h3 {
  font-size: 28px;
}

.woocommerce-tabs .tab-content .comment-respond h4 {
  margin-bottom: 0;
  font-size: 28px;
}

/*comment respond form*/
#review_form textarea,
#review_form [type*='text'],
#review_form [type*='email'] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  #review_form .comment-form-author {
    float: left;
    width: 50%;
    padding-right: 5px;
  }
  #review_form .comment-form-email {
    float: left;
    width: 50%;
    padding-left: 5px;
  }
  #review_form .form-submit {
    clear: both;
  }
}

.comment-form-rating label[for] {
  display: block;
}

/* cart */
.shop_table ul {
  list-style: none;
  padding: 0;
}

.shop-adds {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
}

.shop-adds .fa {
  padding: 0 10px;
}

.shop-adds.teaser {
  margin-bottom: 0;
}

.shop-adds.teaser [class*="_bg_color"] {
  padding: 12px;
}

table.cart {
  margin-top: 0;
  margin-bottom: 30px;
}

table.cart .media img {
  max-width: 60px;
}

table.cart h4 {
  font-size: 16px;
  margin-bottom: 4px;
}

table.cart td, table.cart th {
  vertical-align: middle;
  padding: 18px 20px;
}

table.cart .coupon {
  display: inline-block;
}

table.cart a {
  color: #323232;
}

table.cart td.product-name {
  font-size: 18px;
}

table.cart .product-remove {
  text-align: center;
}

table.cart .attachment-shop_thumbnail {
  min-width: 100px;
}

table.cart .product-info {
  min-width: 200px;
}

.coupon .button {
  background-color: #bee27b;
  border-color: #bee27b;
}

.coupon .button:hover {
  background-color: #4ebbe3;
  transition: all 0.05s linear 0s;
}

form.cart {
  margin-top: 30px;
}

form.cart .theme_button {
  margin: 0;
}

form.cart .quantity + .theme_button {
  padding: 19px 25px;
  border-radius: 25px;
}

.cart_totals h4:first-child {
  margin: 60px 0 30px;
}

.cart_totals table {
  margin: 0 0 50px;
}

.cart_totals table th,
.cart_totals table td {
  padding: 20px 20px;
}

.cart_totals table th {
  width: 140px;
}

/* chekcout */
.shop-info {
  margin-bottom: 15px;
}

#createaccount {
  margin-top: 0;
}

#ship-to-different-address label {
  display: inline-block;
}

#ship-to-different-address label + input {
  margin: 0 12px;
}

.checkout [class*='col-'], .shop-register [class*='col-'] {
  margin-top: 0;
  margin-bottom: 0;
}

.woocommerce form input[type="radio"] {
  margin-right: 6px;
}

.woocommerce form input[type="checkbox"] {
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.woocommerce form p:last-child {
  margin-bottom: 10px;
}

.form-row {
  margin: 0 0 15px;
}

.form-row:before, .form-row:after {
  content: '';
  display: block;
  clear: both;
}

.form-row .checkbox {
  margin: -5px 0 0;
}

.form-row .lost_password {
  margin: 15px 0 -15px;
}

.form-row label {
  display: block;
  margin-top: 10px;
}

.form-row [type="email"],
.form-row [type="number"],
.form-row [type="password"],
.form-row [type="text"],
.form-row select,
.form-row textarea {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .form-row label {
    padding-right: 25px;
    width: 30%;
    float: left;
    text-align: right;
  }
  .form-row label + *,
  .form-row.address-field input {
    float: right;
    width: 70% !important;
  }
}

.woocommerce form.login label,
.woocommerce form.login input {
  float: none;
  text-align: left;
  width: auto !important;
}

.woocommerce form.login [type="submit"] {
  margin-top: 10px;
  text-align: center;
}

@media (min-width: 992px) {
  .col2-set {
    width: 70%;
    float: left;
    padding-right: 50px;
  }
  .col2-set.addresses {
    width: 100%;
    float: none;
    padding: 0;
    margin: 0 -15px;
  }
  .col2-set.addresses:before, .col2-set.addresses:after {
    display: block;
    content: '';
    clear: both;
  }
  .col2-set.addresses .col-1,
  .col2-set.addresses .col-2 {
    float: left;
    width: 50%;
    padding: 0 15px;
  }
}

/* shop single*/
.cart-button {
  margin: 50px 0 55px;
}

.share-title {
  font-size: 20px;
  margin: 56px 0 18px;
}

/* shop */
.shop-item .item-content {
  padding: 30px 40px;
}

.shop-item .item-price {
  margin: 9px 0 16px;
  font-size: 30px;
  line-height: 1;
  font-weight: 800;
}

.shop-item .item-price span {
  font-size: 40px;
}

.shop-item .cart-button {
  margin: 50px 0 28px;
}

.related-item .item-content {
  padding: 14px 25px;
}

.related-item h4 {
  font-size: 20px;
  line-height: 24px;
}

.related-item .cart-button {
  margin: 14px 0 14px;
}

.select2-container .select2-choice {
  border: none;
  padding: 10px 20px;
  border-radius: 0;
}

.select2-drop-active {
  border-color: #f2f2f2;
}

.woocommerce-checkout-payment {
  margin-top: 40px;
}

.woocommerce-checkout-payment .radio {
  margin: 2px 0;
}

.woocommerce-checkout-payment ul {
  list-style: none;
  padding: 0;
}

.woocommerce-checkout-payment li {
  padding: 11px 0 6px;
  border-bottom: 1px solid #f2f2f2;
}

.woocommerce-checkout-payment li:first-child {
  border-top: 1px solid #f2f2f2;
}

.woocommerce-checkout-payment p {
  margin: 0;
}

.woocommerce-checkout-payment img {
  display: none;
}

.woocommerce-checkout-payment [type="submit"] {
  margin-top: 20px;
}

/*messages*/
.wc-forward {
  float: right;
}

.row > .alert {
  margin-left: 25px;
  margin-right: 25px;
}

/*my account*/
.woocommerce-MyAccount-navigation {
  margin-bottom: 40px;
}

.woocommerce-MyAccount-navigation ul {
  padding: 0;
}

.woocommerce-MyAccount-navigation li {
  display: inline-block;
}

.woocommerce-MyAccount-navigation .is-active a {
  background-color: #4ebbe3;
  border-color: #4ebbe3;
}

.woocommerce-MyAccount-content h2 {
  margin: 40px 0;
}

.woocommerce-Message {
  background: #ffffff;
  padding: 30px;
}

.woocommerce-Message .woocommerce-Button {
  float: right;
}

.order-actions {
  text-align: center;
}

.customer_details {
  margin-bottom: 60px;
}

.addresses address {
  margin-bottom: 0;
}

/* shop widgets */
/* cart widget */
.widget_shopping_cart img {
  max-width: 80px;
}

.widget_shopping_cart h4 {
  margin-top: 6px;
  margin-right: 20px;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
}

.widget_shopping_cart .cart_list {
  padding-bottom: 4px;
}

.widget_shopping_cart .product-quantity {
  display: block;
  font-size: 12px;
  line-height: 1;
}

.widget_shopping_cart .product-quantity .price {
  font-size: 14px;
}

.widget_shopping_cart .remove {
  float: right;
  font-size: 0;
}

.widget_shopping_cart .remove:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "\f00d";
}

.widget_shopping_cart li a + a {
  color: #323232;
  font-size: 16px;
}

.widget_shopping_cart .wc-forward {
  float: none;
  margin-right: 5px;
}

.widget_shopping_cart a.button {
  background-color: #4ebbe3;
}

.widget_shopping_cart a.button:hover, .widget_shopping_cart a.button:active, .widget_shopping_cart a.button:focus {
  background-color: #bee27b;
}

.widget_shopping_cart a.button.checkout {
  background-color: #bee27b;
}

.widget_shopping_cart a.button.checkout:hover, .widget_shopping_cart a.button.checkout:active, .widget_shopping_cart a.button.checkout:focus {
  background-color: #4ebbe3;
}

.woocommerce-mini-cart__total strong {
  font-weight: 400;
}

/* price filter */
.widget_price_filter input[readonly] {
  padding-right: 3px;
  padding-left: 3px;
  max-width: 80px;
  margin-bottom: 4px;
  display: inline-block;
}

.widget_price_filter .ui-slider-range {
  background: #79d7d8;
}

.widget_price_filter .ui-slider-horizontal {
  margin-bottom: 15px;
}

.widget_price_filter label[for] {
  display: inline-block;
  padding-right: 4px;
}

.widget_price_filter .form-group {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.widget_price_filter .price_slider_amount {
  position: relative;
  padding-bottom: 70px;
}

/*! jQuery UI - v1.11.1 - 2014-10-03
* http://jqueryui.com
* Includes: core.css, slider.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-slider {
  position: relative;
  text-align: left;
  background-color: #f2f2f2;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 12px;
  height: 12px;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
  background-color: #79d7d8;
  border-radius: 50%;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  -webkit-filter: inherit;
  filter: inherit;
}

.ui-slider-horizontal {
  height: 4px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -4px;
  margin-left: -6px;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

/* color layered nav */
.widget_layered_nav .color-filters li {
  display: inline-block;
  margin: 0 29px 14px 0;
}

.widget_layered_nav .color-filters a {
  display: inline-block;
  width: 23px;
  height: 23px;
  font-size: 0;
  background-color: #ccc;
  color: transparent;
  position: relative;
}

.widget_layered_nav .color-filters a:before {
  content: '';
  display: block;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.05);
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
}

.widget_layered_nav .color-filters span {
  position: relative;
  top: 2px;
  left: 5px;
}

/* widget_products */
.widget_products img {
  max-width: 80px;
}

.widget_products .media h3 {
  font-size: 16px;
  line-height: 1.4;
  font-weight: normal;
  margin: 3px 0 10px;
}

.widget_products .star-rating {
  margin-bottom: 10px;
}

.widget_products .price {
  position: static;
  background-color: transparent;
  padding: 0;
  line-height: 1;
}

/*widget_top_rated_products*/
.widget .product-title {
  display: block;
}

.widget .woocommerce-Price-amount {
  color: #4ebbe3;
}

.widget del .woocommerce-Price-amount {
  color: inherit;
}

.widget .star-rating {
  margin: 5px 0 7px;
}

/*widget_recent_reviews*/
.widget_recent_reviews li:before, .widget_recent_reviews li:after,
.product_list_widget li:before,
.product_list_widget li:after {
  content: '';
  display: block;
  clear: both;
}

.widget_recent_reviews img,
.product_list_widget img {
  display: block;
  float: left;
  max-width: 70px;
}

.widget_recent_reviews .remove,
.product_list_widget .remove {
  font-weight: 400;
}

/*widget_rating_filter*/
.widget_rating_filter {
  text-align: right;
}

.widget_rating_filter li {
  clear: both;
  margin-bottom: 8px;
}

.widget_rating_filter .star-rating {
  float: left;
}

@media (max-width: 991px) {
  table.cart {
    display: block;
    overflow-x: auto;
  }
}

@media (min-width: 992px) {
  #order_review_heading,
  #order_review {
    float: left;
    margin-top: 0;
  }
  #order_review_heading > table,
  #order_review > table {
    margin-top: 0;
  }
}

.cart-button i {
  position: relative;
  font-size: 1.2em;
}

.cart-button i:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: -3px;
  right: -2px;
  border: 2px solid #fff;
  background-color: #bee27b;
  border-radius: 50%;
}

.cart-button .total-price {
  font-size: 18px;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  color: #bee27b;
  padding-left: 5px;
}
